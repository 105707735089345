import React from 'react';

type Props = {
  className?: string;
};

const AnchorIcon = (props: Props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.9084 8.292L14.0969 7.1335C13.9384 6.9075 13.6279 6.8525 13.4004 7.0105L12.2419 7.822C12.0159 7.9805 11.9609 8.292 12.1189 8.5185C12.2774 8.745 12.5889 8.8005 12.8154 8.6415L12.9259 8.564C12.5619 10.7725 10.7504 12.5 8.49836 12.7245V6.5H9.99836C10.2744 6.5 10.4984 6.276 10.4984 6C10.4984 5.724 10.2744 5.5 9.99836 5.5H8.49836V4.4185C9.21885 4.2015 9.74836 3.54 9.74836 2.75C9.74836 1.785 8.96336 1 7.99835 1C7.03335 1 6.24835 1.785 6.24835 2.75C6.24835 3.54 6.77785 4.2015 7.49835 4.4185V5.5H5.99835C5.72235 5.5 5.49835 5.724 5.49835 6C5.49835 6.276 5.72235 6.5 5.99835 6.5H7.49835V12.7245C5.24585 12.5 3.43486 10.7725 3.07086 8.564L3.18135 8.6415C3.40786 8.7995 3.71886 8.745 3.87786 8.5185C4.03586 8.2925 3.98136 7.9805 3.75486 7.822L2.59636 7.0105C2.36986 6.8525 2.05836 6.907 1.89986 7.1335L1.08836 8.292C0.930355 8.5185 0.984855 8.83 1.21136 8.9885C1.29886 9.0495 1.39886 9.079 1.49786 9.079C1.65536 9.079 1.81036 9.005 1.90786 8.8655L2.07086 8.633C2.47636 11.361 4.71835 13.4935 7.49835 13.7245V14.5C7.49835 14.7765 7.72235 15 7.99835 15C8.27435 15 8.49836 14.7765 8.49836 14.5V13.7245C11.2784 13.4935 13.5204 11.361 13.9259 8.633L14.0889 8.8655C14.1864 9.0045 14.3414 9.079 14.4989 9.079C14.5979 9.079 14.6979 9.0495 14.7854 8.9885C15.0114 8.83 15.0664 8.5185 14.9084 8.292ZM7.24886 2.75C7.24886 2.3365 7.58536 2 7.99886 2C8.41235 2 8.74886 2.3365 8.74886 2.75C8.74886 3.1635 8.41235 3.5 7.99886 3.5C7.58536 3.5 7.24886 3.1635 7.24886 2.75Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default AnchorIcon;
