import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isExpanded: true,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(state: any, action: PayloadAction<{ isExpanded: boolean }>) {
      state.isExpanded = action.payload.isExpanded;
    },
  },
});

export const { toggleSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
