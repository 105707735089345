import { EnvelopeIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'shared/components/button';
import { Divider } from 'shared/components/divider';
import ArrowUpRightDownLeftIcon from 'shared/icons/ArrowUpRightDownLeftIcon';
import FileCheckWithBackgroundIcon from 'shared/icons/FileCheckWithBackgroundIcon';
import { ViewEmailModal } from './ViewEmailModal';

interface DocumentViewProps {
  maxHeight: number;
}

const DocumentView = ({ maxHeight }: DocumentViewProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { isLoading, docSrc } = useSelector((state: any) => state.documentDetails);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [scrollableSectionHeight, setScrollableSectionHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setScrollableSectionHeight(maxHeight - headerRef.current.clientHeight - 4);
    }
  }, [maxHeight, headerRef]);

  if (isLoading) {
    return null;
  }

  const onDocumentExpand = () => {
    if (docSrc) {
      window.open(docSrc, '_blank');
    }
  };

  return (
    <div className="rounded-[10px] border border-gray-200">
      <div className="flex items-center justify-between px-6 py-4" ref={headerRef}>
        <div className="flex items-center gap-3">
          <FileCheckWithBackgroundIcon />
          <span className="text-[18px] font-semibold">Document</span>
        </div>
        <div className="flex items-center gap-3">
          <Button color="white" className="flex gap-2 px-3 py-2" onClick={() => setIsEmailModalOpen(true)}>
            <EnvelopeIcon />
            <span className="text-[12px] font-medium">View Email</span>
          </Button>
          <Button color="white" className="size-[34px] p-[9px]" onClick={onDocumentExpand}>
            <ArrowUpRightDownLeftIcon />
          </Button>
        </div>
      </div>
      <Divider />
      <div className="overflow-auto" style={{ maxHeight: `${scrollableSectionHeight}px` }}>
        <div style={{ height: `${scrollableSectionHeight}px` }}>
          {docSrc?.includes('png') ? (
            <img alt="document" src={docSrc} />
          ) : (
            <embed src={docSrc} className="h-full w-full" />
          )}
        </div>
      </div>
      <ViewEmailModal isOpen={isEmailModalOpen} setIsOpen={setIsEmailModalOpen} />
    </div>
  );
};

export default DocumentView;
