import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { InfoIcon } from 'shared/icons/Info';

type Shipment = {
  ocean_containers?: {
    container_number?: string;
    seal_number?: string;
    container_type?: string;
    items?: {
      item_description?: string;
    }[];
    weight?: number;
    volume?: number;
    package_count?: number;
    package_uom?: string;
    pallet_count?: number;
  }[];
  total_packages: {
    value?: number;
    unit?: string;
  };
  total_weight: {
    value?: number;
    unit?: string;
  };
  total_volume: {
    value?: number;
    unit?: string;
  };
};

const ROW_TEMPLATE = [
  {
    label: 'Container Number: ',
    textRenderer: (container: any) => container?.container_number ?? '--',
  },
  {
    label: 'Seal Number: ',
    textRenderer: (container: any) => container?.seal_number ?? '--',
  },
  {
    label: 'Type: ',
    textRenderer: (container: any) => container?.container_type ?? '--',
  },
  {
    label: 'Packages: ',
    textRenderer: (container: any) =>
      container?.package_count ? `${container?.package_count} ${container?.package_uom || '--'}` : '--',
  },
  {
    label: 'Pallets: ',
    textRenderer: (container: any) => container?.pallet_count || '--',
  },
  {
    label: 'Weight: ',
    textRenderer: (container: any, shipment: any) => `${container?.weight ?? ''} ${shipment?.total_weight?.unit ?? ''}`,
  },
  {
    label: 'Volume: ',
    textRenderer: (container: any, shipment: any) => `${container?.volume ?? ''} ${shipment?.total_volume?.unit ?? ''}`,
  },
  {
    label: 'Item Description: ',
    textRenderer: (container: any) =>
      (container?.items?.map((item: any) => item.item_description).filter((str: any) => !!str) ?? []) as string[],
  },
];

export default function ContainerCell({ shipment }: { shipment: Shipment }) {
  const [isShowMore, setIsShowMore] = useState(false);
  const buttonRef = useRef(null);
  const panelRef = useRef(null);
  const [position, setPosition] = useState('left');

  const rawOceanContainers = useMemo(() => shipment?.ocean_containers ?? [], [shipment?.ocean_containers]);

  const checkPopoverPanelPosition = () => {
    if (buttonRef.current && panelRef.current) {
      const buttonRect = (buttonRef.current as HTMLElement).getBoundingClientRect();
      const panelWidth = (panelRef.current as HTMLElement).offsetWidth;
      const viewportWidth = window.innerWidth;
      const isSpaceOnRight = viewportWidth - buttonRect.right > panelWidth;
      setPosition(isSpaceOnRight ? 'right' : 'left');
    }
  };

  const triggerDetectPosition = () => {
    setTimeout(() => {
      checkPopoverPanelPosition();
    }, 0);
  };

  useEffect(() => {
    checkPopoverPanelPosition();
    window.addEventListener('scroll', checkPopoverPanelPosition, true);
    return () => {
      window.removeEventListener('scroll', checkPopoverPanelPosition, true);
    };
  }, []);

  const { containers, moreCount } = useMemo(() => {
    let containers: any[],
      moreCount = 0;
    if (!rawOceanContainers.length) {
      containers = [];
    } else if (rawOceanContainers.length > 2 && !isShowMore) {
      containers = [rawOceanContainers[0]];
      moreCount = rawOceanContainers.length - 1;
    } else {
      containers = rawOceanContainers;
    }
    return { containers, moreCount };
  }, [rawOceanContainers, isShowMore]);

  if (!rawOceanContainers.length) {
    return <></>;
  }

  return (
    <div>
      {containers.map((container, index) => {
        return (
          <div className="flex" key={index}>
            <span className="truncate">{container.container_number ?? '--'}</span>
            <Popover as="div" className="relative flex">
              <PopoverButton
                ref={buttonRef}
                type="button"
                className="!px-1 !outline-none"
                onClick={triggerDetectPosition}
              >
                <InfoIcon className="text-[#9CA3AF]" />
              </PopoverButton>
              <PopoverPanel
                ref={panelRef}
                anchor={{ gap: 4, to: 'right start', offset: -18 }}
                className="!max-w-64 data-[closed]:-translate-x-1 data-[closed]:opacity-0"
              >
                <div className="flex">
                  {position === 'right' && (
                    <div className="mt-4 h-0 w-0 border-[10px] border-l-0 border-transparent border-r-[#21283C]"></div>
                  )}
                  <div className="rounded bg-[#21283C] px-3 py-2 text-white">
                    {ROW_TEMPLATE.map(({ label, textRenderer }) => (
                      <p key={label} className="text-xs">
                        <span className="font-bold">{label}</span>
                        <span className="text-gray-300">{textRenderer(container, shipment)}</span>
                      </p>
                    ))}
                  </div>
                  {position === 'left' && (
                    <div className="mt-4 h-0 w-0 border-[10px] border-r-0 border-transparent border-l-[#21283C]"></div>
                  )}
                </div>
              </PopoverPanel>
            </Popover>
          </div>
        );
      })}
      {isShowMore && (
        <button
          className="flex items-center gap-1 py-0.5 text-[10px] font-medium leading-normal text-gray-500"
          type="button"
          onClick={() => setIsShowMore(false)}
        >
          <span>Show less</span>
          <img src="/static/images/angle-up.svg" alt="" />
        </button>
      )}
      {moreCount > 0 && (
        <button
          className="flex items-center gap-1 py-0.5 text-[10px] font-medium leading-normal text-gray-500"
          type="button"
          onClick={() => setIsShowMore(true)}
        >
          <span>{moreCount} more</span>
          <img src="/static/images/angle-down.svg" alt="" />
        </button>
      )}
    </div>
  );
}
