import React from 'react';

type Props = {
  className?: string;
};

const CustomsIcon = (props: Props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_55487_1949)">
        <path
          d="M1.42019 4.72888H0.68734C0.30769 4.72888 0 5.05158 0 5.44974V8.91004C0 9.30809 0.3077 9.63074 0.68734 9.63074H1.42019V4.72888Z"
          fill="#4B5563"
        />
        <path
          d="M1.41992 9.63074V10.3637C1.41992 10.7433 1.74262 11.051 2.14062 11.051H5.60102C5.99907 11.051 6.32172 10.7433 6.32172 10.3637V9.63074H1.41992Z"
          fill="#4B5563"
        />
        <path
          d="M10.0769 2.20862H7.70789L7.07812 2.82721H7.70789V2.96487C7.70789 3.68573 8.29266 4.27027 9.01359 4.27027C9.7348 4.27027 10.3193 3.68562 10.3193 2.96487V2.20862H10.0769Z"
          fill="#4B5563"
        />
        <path d="M10.3193 1.79652H7.65086L7.07812 0.949219H10.3193L10.3193 1.79652Z" fill="#4B5563" />
        <path
          d="M11.3132 4.72888H11.1876L7.90332 8.89798H10.58V9.63071L7.21582 9.63067V11.0511H12.0004V5.44977C12.0004 5.05161 11.6928 4.72891 11.3131 4.72891L11.3132 4.72888Z"
          fill="#4B5563"
        />
        <path
          d="M10.1821 4.72888H6.76068V4.73044C6.61896 4.72779 6.47412 4.78998 6.35209 4.94439C5.44131 6.09569 4.53069 7.24729 3.61999 8.39869C3.13112 9.01705 4.00015 9.89859 4.49479 9.27314C5.35592 8.18424 6.21729 7.09524 7.07849 6.00624V8.66884L10.1821 4.72888Z"
          fill="#4B5563"
        />
      </g>
      <defs>
        <clipPath id="clip0_55487_1949">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomsIcon;
