import React from 'react';

type Props = {
  className?: string;
};

const BuildingIcon = (props: Props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.33398 7.33333H4.13398C3.38725 7.33333 3.01388 7.33333 2.72866 7.47866C2.47778 7.60649 2.27381 7.81046 2.14598 8.06135C2.00065 8.34656 2.00065 8.71993 2.00065 9.46667V14M14.0007 14V4.13333C14.0007 3.3866 14.0007 3.01323 13.8553 2.72801C13.7275 2.47713 13.5235 2.27316 13.2726 2.14532C12.9874 2 12.6141 2 11.8673 2H9.46732C8.72058 2 8.34721 2 8.062 2.14532C7.81111 2.27316 7.60714 2.47713 7.47931 2.72801C7.33398 3.01323 7.33398 3.3866 7.33398 4.13333V14M14.6673 14H1.33398M9.66732 4.66667H11.6673M9.66732 7.33333H11.6673M9.66732 10H11.6673"
        stroke="#9CA3AF"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BuildingIcon;
