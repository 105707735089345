import React from 'react';

type Props = {
  onClick?: () => void;
};

const ManualResolvedIcon = (props: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="7.33333" fill="#0E9F6E" stroke="#0E9F6E" strokeWidth="1.33333" />
      <g clipPath="url(#clip0_55271_7656)">
        <path
          d="M6.85742 12.9524V8.95238C6.85742 8.69979 6.95776 8.45755 7.13637 8.27895C7.31497 8.10034 7.55722 8 7.8098 8V8C8.06239 8 8.30463 8.10034 8.48324 8.27895C8.66184 8.45755 8.76218 8.69979 8.76218 8.95238V11.0476H10.286C10.6901 11.0476 11.0777 11.2082 11.3635 11.4939C11.6493 11.7797 11.8098 12.1673 11.8098 12.5714V12.9524"
          stroke="white"
          strokeWidth="0.761905"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.24997 9.22665C4.7441 8.72041 4.39969 8.07556 4.26026 7.3736C4.12084 6.67165 4.19266 5.94412 4.46666 5.28298C4.74066 4.62184 5.20452 4.05678 5.79961 3.65923C6.3947 3.26169 7.0943 3.0495 7.80997 3.0495C8.52563 3.0495 9.22523 3.26169 9.82032 3.65923C10.4154 4.05678 10.8793 4.62184 11.1533 5.28298C11.4273 5.94412 11.4991 6.67165 11.3597 7.3736C11.2202 8.07556 10.8758 8.72041 10.37 9.22665"
          stroke="white"
          strokeWidth="0.761905"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_55271_7656">
          <rect width="10.6667" height="10.6667" fill="white" transform="translate(2.6665 2.66669)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ManualResolvedIcon;
