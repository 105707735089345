import React from 'react'

export default function FileSaveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_54923_14547"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17"
      >
        <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_54923_14547)">
        <path
          d="M12.0003 14.4997L14.667 11.833L13.7337 10.8997L12.667 11.9663V9.18301H11.3337V11.9663L10.267 10.8997L9.33366 11.833L12.0003 14.4997ZM9.33366 16.4997V15.1663H14.667V16.4997H9.33366ZM4.00033 13.833C3.63366 13.833 3.31977 13.7025 3.05866 13.4413C2.79755 13.1802 2.66699 12.8663 2.66699 12.4997V3.16634C2.66699 2.79967 2.79755 2.48579 3.05866 2.22467C3.31977 1.96356 3.63366 1.83301 4.00033 1.83301H8.66699L12.667 5.83301V7.84968H11.3337V6.49967H8.00033V3.16634H4.00033V12.4997H8.00033V13.833H4.00033Z"
          fill="#1F2A37"
        />
      </g>
    </svg>
  )
}
