import { useQuery } from '@tanstack/react-query';
import get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

import ActiveFiltersV2 from 'shared/components/active-filters-v2';
import { Button } from 'shared/components/button';
import DataTableV2 from 'shared/components/data-table-v2';
import { DateFilterValue, getDateFilterByDateRange } from 'shared/components/date-filter';
import { IFilterItem } from 'shared/components/filters-drawer';
import StatCard from 'shared/components/StatCard';
import Tooltip from 'shared/components/tooltip';
import { TaskType } from 'shared/constants/enums';
import { TaskTypeLabel } from 'shared/constants/labels';
import { TASK_TYPE_FILTER_OPTIONS, TASK_URGENCY_FILTER_OPTIONS } from 'shared/constants/options';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { Clock } from 'shared/icons/Clock';
import { File } from 'shared/icons/File';
import { FileCheck } from 'shared/icons/FileCheck';
import { FileView } from 'shared/icons/FileView';
import { InformationSquare } from 'shared/icons/InformationSquare';
import { Tasks as TasksIcon } from 'shared/icons/Tasks';
import { ITask } from 'shared/reduxStore/slices/tasks/types';
import { getDocumentTypeDisplayName, IFindManyTasksRequest, taskService } from 'shared/services';
import { REACT_QUERY_KEY } from 'shared/services/queryKey';
import {
  getDueDateColor,
  getDueDateText,
  getTaskLinkPrefix,
  getTaskStatus,
  getTaskStatusColor,
} from 'shared/services/tasks/tasks.utils';
import { cn } from 'shared/utils/cn';

// Cache duration in milliseconds
const staleTime = 60 * 60 * 1000;

interface IFindManyTasksRequestExtended extends IFindManyTasksRequest {
  created_at?: DateFilterValue;
  due_date?: DateFilterValue;
  completed_at?: DateFilterValue;
}

const getTaskDetailsLink = (task: ITask) => {
  const prefix = getTaskLinkPrefix(task.type as TaskType);
  return prefix ? `${prefix}/${task.id}` : '';
};

const countFilters = (filter: IFindManyTasksRequestExtended) => {
  let count = 0;
  if (filter.urgency && filter.urgency.length > 0) count++;
  if (filter.status && filter.status.length > 0) count++;
  if (filter.type && filter.type.length > 0) count++;
  if (filter.shipping_doc_type && filter.shipping_doc_type.length > 0) count++;
  if (filter.created_at__gte || filter.created_at__lte) count++;
  if (filter.due_date__gte || filter.due_date__lte) count++;
  if (filter.completed_at__gte || filter.completed_at__lte) count++;
  return count;
};

const normalizeApplyFilters = (_filter: IFindManyTasksRequestExtended) => {
  const appliedFilter: IFindManyTasksRequestExtended = {
    ..._filter,
  };
  const paramsObj: Record<string, string> = {};

  if (appliedFilter.page) {
    paramsObj.page = appliedFilter.page.toString();
  } else {
    paramsObj.page = '1';
  }

  if (appliedFilter.page_size) {
    paramsObj.page_size = appliedFilter.page_size.toString();
  }

  if (appliedFilter.order_by) {
    paramsObj.order_by = appliedFilter.order_by;
  }

  if (appliedFilter.order_direction) {
    paramsObj.order_direction = appliedFilter.order_direction;
  }

  if (appliedFilter.urgency && appliedFilter.urgency.length > 0) {
    paramsObj.urgency = appliedFilter.urgency.join(',');
  }

  if (appliedFilter.status && appliedFilter.status.length > 0) {
    paramsObj.status = appliedFilter.status.join(',');
  }

  if (appliedFilter.type && appliedFilter.type.length > 0) {
    paramsObj.type = appliedFilter.type.join(',');
  }

  if (appliedFilter.shipping_doc_type && appliedFilter.shipping_doc_type.length > 0) {
    paramsObj.shipping_doc_type = appliedFilter.shipping_doc_type.join(',');
  }

  if (appliedFilter.created_at__gte) {
    paramsObj.created_at__gte = appliedFilter.created_at__gte;
  }

  if (appliedFilter.created_at__lte) {
    paramsObj.created_at__lte = appliedFilter.created_at__lte;
  }

  if (appliedFilter.due_date__gte) {
    paramsObj.due_date__gte = appliedFilter.due_date__gte;
  }

  if (appliedFilter.due_date__lte) {
    paramsObj.due_date__lte = appliedFilter.due_date__lte;
  }

  if (appliedFilter.completed_at__gte) {
    paramsObj.completed_at__gte = appliedFilter.completed_at__gte;
  }

  if (appliedFilter.completed_at__lte) {
    paramsObj.completed_at__lte = appliedFilter.completed_at__lte;
  }

  if (appliedFilter.created_at) {
    paramsObj.created_at__gte = moment(appliedFilter.created_at.range.from).format('YYYY-MM-DD');
    paramsObj.created_at__lte = moment(appliedFilter.created_at.range.to).format('YYYY-MM-DD');
  } else {
    delete paramsObj.created_at__gte;
    delete paramsObj.created_at__lte;
  }

  if (appliedFilter.due_date) {
    paramsObj.due_date__gte = moment(appliedFilter.due_date.range.from).format('YYYY-MM-DD');
    paramsObj.due_date__lte = moment(appliedFilter.due_date.range.to).format('YYYY-MM-DD');
  } else {
    delete paramsObj.due_date__gte;
    delete paramsObj.due_date__lte;
  }

  if (appliedFilter.completed_at) {
    paramsObj.completed_at__gte = moment(appliedFilter.completed_at.range.from).format('YYYY-MM-DD');
    paramsObj.completed_at__lte = moment(appliedFilter.completed_at.range.to).format('YYYY-MM-DD');
  } else {
    delete paramsObj.completed_at__gte;
    delete paramsObj.completed_at__lte;
  }

  return paramsObj;
};

export default function Tasks() {
  const orgId = useSelector((state: any) => state.auth.orgId);
  const defaultFilter: IFindManyTasksRequestExtended = {
    org_id: orgId,
    category: ['freight_forwarder_task'],
    page_size: 10,
    page: 1,
    order_by: 'created_at',
    order_direction: 'desc',
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterStatus, setFilterStatus] = useState<string>('');
  const { state, update, reset } = useLocalStorage('active_task_id', {});
  const [applyFilter, setApplyFilter] = useState<IFindManyTasksRequestExtended>(defaultFilter);
  const [tempFilter, setTempFilter] = useState<IFindManyTasksRequestExtended>(defaultFilter);

  const { data, isFetching } = useQuery({
    queryKey: [REACT_QUERY_KEY.GET_TASKS, { ...applyFilter }],
    queryFn: () => taskService.getTasks({ ...applyFilter }),
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime,
  });

  const { data: dataTemp } = useQuery({
    queryKey: [`${REACT_QUERY_KEY.GET_TASKS}_TEMP`, { ...tempFilter }],
    queryFn: () => taskService.getTasks({ ...tempFilter }),
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime,
  });

  const { data: stats, isFetching: isStatsFetching } = useQuery({
    queryKey: [REACT_QUERY_KEY.GET_TASK_STATS, { category: applyFilter.category, org_id: applyFilter.org_id }],
    queryFn: () => taskService.getTaskStats({ category: applyFilter.category, org_id: applyFilter.org_id }),
    refetchOnReconnect: 'always',
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime,
  });

  const [columns, setColumns] = useState([
    {
      accessorFn: (row: ITask) => row?.type || '',
      cell: (info: any) => (
        <Link
          to={getTaskDetailsLink(info.row.original)}
          onClick={() => {
            update('id', info.row.original.id);
          }}
          className={cn([
            'cursor-pointer font-semibold capitalize text-gray-800 hover:underline',
            info.row.original.status === 'completed' && 'text-gray-400',
          ])}
        >
          {TaskTypeLabel[info.getValue() as TaskType] || 'Unknown'}
        </Link>
      ),
      header: 'Task',
      size: 300,
      colName: 'Task',
      id: 'type',
      key: 'type',
    },
    {
      accessorFn: (row: ITask) => row?.status || '--',
      cell: (info: any) => (
        <span
          className={cn([
            'flex items-center gap-1 font-inter text-sm font-normal capitalize',
            getTaskStatusColor(info.getValue()),
          ])}
        >
          {getTaskStatus(info.getValue())}
        </span>
      ),
      header: 'Task status',
      size: 100,
      colName: 'Task status',
      id: 'status',
      key: 'status',
    },
    {
      accessorFn: (row: ITask) => row?.created_at || '',
      cell: (info: any) => {
        const rawValue = info.getValue();
        if (!rawValue) return '--';

        return (
          <span
            className={cn([
              'flex items-center gap-1 font-inter text-sm font-normal',
              info.row.original.status === 'completed' && 'text-gray-400',
            ])}
          >
            {new Date(rawValue).toLocaleString()}
          </span>
        );
      },
      header: 'Creation date',
      size: 200,
      colName: 'Creation date',
      id: 'created_at',
      key: 'created_at',
    },
    {
      accessorFn: (row: ITask) => row?.schedule?.due_at || '',
      cell: (info: any) => {
        if (!info.getValue()) return '--';
        const dueDate = new Date(info.getValue());

        return (
          <span
            className={cn([
              'flex items-center gap-1 font-inter text-sm font-normal',
              getDueDateColor(dueDate),
              info.row.original.status === 'completed' && 'text-gray-400',
            ])}
          >
            <Tooltip
              tooltip={<span className="text-xs capitalize">{getDueDateText(dueDate, info.row.original.status)}</span>}
              className="bg-gray-800 font-medium text-white"
              arrowClassName="fill-gray-800"
              side="left"
              target={
                <span>
                  <Clock />
                </span>
              }
            />
            {dueDate.toLocaleString()}
          </span>
        );
      },
      header: 'Due date',
      size: 100,
      colName: 'Due date',
      id: 'schedule.due_at',
      key: 'schedule.due_at',
    },
    {
      accessorFn: (row: ITask) => row?.schedule?.completed_at || '',
      cell: (info: any) => {
        if (!info.getValue()) return '--';
        const completedAt = new Date(info.getValue());
        return (
          <span
            className={cn([
              'flex items-center gap-1 font-inter text-sm font-normal',
              info.row.original.status === 'completed' ? 'text-gray-400' : '',
            ])}
          >
            {completedAt.toLocaleString()}
          </span>
        );
      },
      header: 'Completion date',
      size: 200,
      colName: 'Completion date',
      id: 'schedule.completed_at',
      key: 'schedule.completed_at',
      initialVisibility: false,
      enableSorting: false,
    },
    {
      accessorFn: (row: ITask) => row?.data?.shipping_doc_type || '',
      cell: (info: any) => {
        const row = info.row.original;
        const shipping_documents = row?.data?.shipping_documents;
        const documentId = row?.data?.shipping_document_id;

        if (shipping_documents && shipping_documents.length > 0) {
          return (
            <div className="flex items-center gap-1">
              {shipping_documents.map((doc: any) => renderDocumentType({ id: doc?.id, type: doc?.type }))}
            </div>
          );
        }

        return renderDocumentType({ id: documentId, type: info.getValue() });
      },
      meta: {
        cellStyle: {
          textOverflow: 'unset',
        },
      },
      header: 'Documents',
      size: 200,
      colName: 'Document',
      id: 'data.shipping_doc_type',
      key: 'data.shipping_doc_type',
      enableSorting: false,
    },
    {
      accessorFn: (row: ITask) => row?.data?.client_shipment_id || '',
      cell: (info: any) => {
        const clientShipmentId = info.getValue() || '--';
        const shipmentId = get(info, 'row.original.data.shipment_ids.[0]');

        return shipmentId ? (
          <Link to={`/shipments/${shipmentId}`} target="_blank" rel="noreferrer noopener">
            <span
              className={cn([
                'flex items-center gap-1 font-inter text-sm font-normal hover:underline',
                info.row.original.status === 'completed' && 'text-gray-400',
              ])}
            >
              {clientShipmentId}
            </span>
          </Link>
        ) : (
          <span
            className={cn([
              'flex items-center gap-1 font-inter text-sm font-normal hover:underline',
              info.row.original.status === 'completed' && 'text-gray-400',
            ])}
          >
            {clientShipmentId}
          </span>
        );
      },
      header: 'Client shipment ID',
      size: 100,
      colName: 'Client shipment ID',
      id: 'data.client_shipment_id',
      key: 'data.client_shipment_id',
      enableSorting: false,
      enableResizing: false,
    },
  ]);

  const resetFilter = () => {
    handleApplyFilter(defaultFilter);
    setFilterStatus('');
  };

  const handleClearFilter = (key: string, value: any) => {
    setFilterStatus('');
    handleApplyFilter({
      ...applyFilter,
      page: 1,
      [key]: value,
    });
  };

  const onToggleCard = (value: string) => {
    if (filterStatus !== value) {
      if (value === 'open') {
        handleApplyFilter({
          ...defaultFilter,
          status: ['assigned'],
        });
        setFilterStatus(value);
      } else if (value === 'due_by_eod') {
        handleApplyFilter({
          ...defaultFilter,
          due_date: {
            range: {
              from: new Date(),
              to: new Date(),
            },
            type: 'today',
            label: 'Due by EOD',
          },
          status: ['assigned'],
        });
        setFilterStatus(value);
      } else if (value === 'overdue') {
        handleApplyFilter({
          ...defaultFilter,
          urgency: ['overdue'],
          status: ['assigned'],
        });
        setFilterStatus(value);
      } else {
        handleApplyFilter({
          ...defaultFilter,
          urgency: value === 'all' ? undefined : [value],
        });
        setFilterStatus(value);
      }
    } else {
      handleApplyFilter({
        ...defaultFilter,
        urgency: undefined,
      });
      setFilterStatus('');
    }
  };

  const handleApplyFilter = (_filter: IFindManyTasksRequestExtended) => {
    const params = new URLSearchParams();
    const appliedFilter = normalizeApplyFilters(_filter);

    Object.keys(appliedFilter).forEach((key) => {
      if (appliedFilter[key]) {
        params.append(key, appliedFilter[key]);
      }
    });
    setSearchParams(params);
  };

  useEffect(() => {
    const page = searchParams.get('page');
    const page_size = searchParams.get('page_size');
    const order_by = searchParams.get('order_by');
    const order_direction = searchParams.get('order_direction');
    const urgency = searchParams.get('urgency');
    const status = searchParams.get('status');
    const type = searchParams.get('type');
    const shipping_doc_type = searchParams.get('shipping_doc_type');
    const created_at__gte = searchParams.get('created_at__gte');
    const created_at__lte = searchParams.get('created_at__lte');
    const due_date__gte = searchParams.get('due_date__gte');
    const due_date__lte = searchParams.get('due_date__lte');
    const completed_at__gte = searchParams.get('completed_at__gte');
    const completed_at__lte = searchParams.get('completed_at__lte');

    let created_at: DateFilterValue | undefined;
    if (created_at__gte && created_at__lte) {
      created_at = getDateFilterByDateRange({
        from: new Date(`${created_at__gte}T00:00:00`),
        to: new Date(`${created_at__lte}T23:59:59`),
      });
    }

    let due_date: DateFilterValue | undefined;
    if (due_date__gte && due_date__lte) {
      due_date = getDateFilterByDateRange({
        from: new Date(`${due_date__gte}T00:00:00`),
        to: new Date(`${due_date__lte}T23:59:59`),
      });
    }

    let completed_at: DateFilterValue | undefined;
    if (completed_at__gte && completed_at__lte) {
      completed_at = getDateFilterByDateRange({
        from: new Date(`${completed_at__gte}T00:00:00`),
        to: new Date(`${completed_at__lte}T23:59:59`),
      });
    }

    const newFilter = {
      page: page ? parseInt(page) : 1,
      page_size: page_size ? parseInt(page_size) : 10,
      order_by: order_by || 'created_at',
      order_direction: order_direction || 'desc',
      urgency: urgency ? urgency.split(',') : undefined,
      status: status ? status.split(',') : undefined,
      type: type ? type.split(',') : undefined,
      shipping_doc_type: shipping_doc_type ? shipping_doc_type.split(',') : undefined,
      created_at__gte: created_at__gte || undefined,
      created_at__lte: created_at__lte || undefined,
      due_date__gte: due_date__gte || undefined,
      due_date__lte: due_date__lte || undefined,
      completed_at__gte: completed_at__gte || undefined,
      completed_at__lte: completed_at__lte || undefined,
      created_at,
      due_date,
      completed_at,
    };
    setApplyFilter((prev) => ({ ...prev, ...newFilter }));
    setTempFilter((prev) => ({ ...prev, ...newFilter }));
  }, [searchParams]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      reset();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [reset]);

  const filterItems: IFilterItem[] = [
    {
      key: 'urgency',
      title: 'Task Urgency',
      options: TASK_URGENCY_FILTER_OPTIONS,
      onChange: (value: string[]) => {},
      onChangeComposer: (setValueFn) => (value) => {
        setFilterStatus('');
        setTempFilter((prev) => ({
          ...prev,
          status: ['assigned'],
          urgency: value,
        }));
        setValueFn((prev: any) => ({
          ...prev,
          page: 1,
          status: ['assigned'],
          urgency: value,
        }));
      },
      value: applyFilter.urgency || [],
      activeValue: applyFilter.urgency || [],
      valueComposer: (filter: any) => filter.urgency || [],
      type: 'checkbox',
      onClear: () => {
        handleClearFilter('urgency', undefined);
      },
      disabled: false,
      disabledChecker: () => countFilters(tempFilter) > 0 && (!tempFilter.urgency || tempFilter.urgency.length === 0),
    },
    {
      key: 'status',
      title: 'Task Status',
      options: [
        { label: 'Open', value: 'assigned' },
        { label: 'Completed', value: 'completed' },
      ],
      onChange: (value: string[]) => {},
      onChangeComposer: (setValueFn) => (value) => {
        setFilterStatus('');
        setTempFilter((prev) => ({
          ...prev,
          status: value,
        }));
        setValueFn((prev: any) => ({
          ...prev,
          page: 1,
          status: value,
        }));
      },
      onClear: () => {
        handleClearFilter('status', undefined);
      },
      value: applyFilter.status,
      activeValue: applyFilter.status || '',
      valueComposer: (filter: any) => filter.status || '',
      type: 'checkbox',
      disabled: false,
      disabledChecker: () =>
        (countFilters(tempFilter) > 0 && (!tempFilter.status || tempFilter.status.length === 0)) ||
        (tempFilter?.urgency && tempFilter?.urgency?.length > 0) ||
        false,
    },
    {
      key: 'type',
      title: 'Task Type',
      type: 'dropdown',
      options: TASK_TYPE_FILTER_OPTIONS,
      onChange: (value: string[]) => {},
      onChangeComposer: (setValueFn) => (value) => {
        setFilterStatus('');
        setTempFilter((prev) => ({
          ...prev,
          type: value,
        }));
        setValueFn((prev: any) => ({
          ...prev,
          page: 1,
          type: value,
        }));
      },
      onClear: () => {
        handleClearFilter('type', []);
      },
      value: applyFilter.type || [],
      activeValue: applyFilter.type || [],
      valueComposer: (filter: any) => filter.type || [],
      placeholder: 'Search Task Type',
      disabled: false,
      disabledChecker: () => countFilters(tempFilter) > 0 && (!tempFilter.type || tempFilter.type.length === 0),
    },
    {
      key: 'created_at',
      title: 'Creation Date',
      type: 'dateDropdown',
      value: undefined,
      activeValue: applyFilter.created_at?.label || '',
      onChange(value) {},
      onClear() {
        handleClearFilter('created_at', undefined);
      },
      onChangeComposer(setValueFn) {
        return (value) => {
          setFilterStatus('');
          setTempFilter((prev) => ({
            ...defaultFilter,
            ...normalizeApplyFilters({ ...prev, created_at: value }),
          }));
          setValueFn((prev: any) => ({
            ...prev,
            page: 1,
            created_at: value,
          }));
        };
      },
      disabled: false,
      disabledChecker: () => {
        return (
          countFilters(tempFilter) > 0 &&
          !tempFilter.created_at &&
          !tempFilter.created_at__gte &&
          !tempFilter.created_at__lte
        );
      },
    },
    {
      key: 'due_date',
      title: 'Due Date',
      type: 'dateDropdown',
      value: undefined,
      activeValue: applyFilter.due_date?.label || '',
      onChange(value) {},
      onClear() {
        handleClearFilter('due_date', undefined);
      },
      onChangeComposer(setValueFn) {
        return (value) => {
          setFilterStatus('');
          setTempFilter((prev) => ({
            ...defaultFilter,
            ...normalizeApplyFilters({ ...prev, due_date: value }),
          }));
          setValueFn((prev: any) => ({
            ...prev,
            page: 1,
            due_date: value,
          }));
        };
      },
      disabled: false,
      disabledChecker: () => {
        return (
          countFilters(tempFilter) > 0 && !tempFilter.due_date && !tempFilter.due_date__gte && !tempFilter.due_date__lte
        );
      },
    },
    {
      key: 'completed_at',
      title: 'Completion Date',
      type: 'dateDropdown',
      value: undefined,
      activeValue: applyFilter.completed_at?.label || '',
      onChange(value) {},
      onClear() {
        handleClearFilter('completed_at', undefined);
      },
      onChangeComposer(setValueFn) {
        return (value) => {
          setFilterStatus('');
          setTempFilter((prev) => ({
            ...defaultFilter,
            ...normalizeApplyFilters({ ...prev, completed_at: value }),
          }));
          setValueFn((prev: any) => ({
            ...prev,
            page: 1,
            completed_at: value,
          }));
        };
      },
      disabled: false,
      disabledChecker: () => {
        return (
          countFilters(tempFilter) > 0 &&
          !tempFilter.completed_at &&
          !tempFilter.completed_at__gte &&
          !tempFilter.completed_at__lte
        );
      },
    },
  ];

  const totalPages = data?.total ? Math.ceil(data.total / (applyFilter.page_size || 10)) : 0;

  const renderDocumentType = (doc: any) => {
    if (!doc || !doc?.type) return null;

    if (!doc?.id) {
      return (
        <span className="inline-flex cursor-not-allowed items-center gap-1 rounded-lg border border-gray-500 px-3 py-1 uppercase text-gray-500">
          <FileCheck />
          {getDocumentTypeDisplayName(doc?.type) || 'UNKNOWN'}
        </span>
      );
    }

    return (
      <Link key={doc.id} to={`/document/${doc?.id}`} target="_blank" rel="noreferrer noopener">
        <span className="inline-flex items-center gap-1 rounded-lg border border-green-600 px-3 py-1 uppercase text-green-600">
          <FileCheck />
          {getDocumentTypeDisplayName(doc?.type) || 'UNKNOWN'}
        </span>
      </Link>
    );
  };

  return (
    <main>
      <div className="grid grid-cols-2 gap-6 md:grid-cols-4">
        <StatCard
          icon={<InformationSquare className="w-5 text-black" />}
          loading={isStatsFetching}
          text="Overdue Tasks"
          count={stats?.overdue || 0}
          active={filterStatus === 'overdue'}
          onClick={onToggleCard.bind(null, 'overdue')}
        />
        <StatCard
          icon={<TasksIcon className="w-5 text-black" />}
          loading={isStatsFetching}
          text="Tasks Due Today"
          count={stats?.dueByEOD || 0}
          active={filterStatus === 'due_by_eod'}
          onClick={onToggleCard.bind(null, 'due_by_eod')}
        />
        <StatCard
          icon={<FileView className="w-5 text-black" />}
          loading={isStatsFetching}
          text="Open Tasks"
          count={stats?.open || 0}
          active={filterStatus === 'open'}
          onClick={onToggleCard.bind(null, 'open')}
        />
        <StatCard
          icon={<File className="w-5 text-black" />}
          loading={isStatsFetching}
          text="All"
          count={stats?.total || 0}
          active={filterStatus === 'all'}
          onClick={onToggleCard.bind(null, 'all')}
        />
      </div>
      <DataTableV2
        tableId="tasks-list"
        title="Tasks"
        className="mt-6"
        columns={columns}
        setColumns={setColumns}
        data={data?.tasks || []}
        loading={isFetching}
        totalPages={totalPages}
        currentPage={applyFilter.page || 1}
        totalCount={data?.total || 0}
        setFilter={setApplyFilter}
        applyFilter={applyFilter}
        handleApply={(filter) => {
          if (!filter.page) {
            setFilterStatus('');
          }
          handleApplyFilter({
            ...applyFilter,
            ...filter,
            ...(!filter.page && { page: 1 }),
          });
        }}
        filterCount={countFilters(applyFilter)}
        actions={[]}
        filterItems={filterItems}
        totalFilterResults={countFilters(tempFilter) && dataTemp?.total ? dataTemp.total : 0}
        resetFilter={resetFilter}
        activeFilters={
          <ActiveFiltersV2
            filterItems={filterItems}
            filters={applyFilter}
            handleApply={(filter) => {
              handleApplyFilter({
                ...applyFilter,
                ...filter,
              });
            }}
            resetFilter={resetFilter}
          />
        }
        emptyContent={{
          title: countFilters(applyFilter) ? 'Result Not Found' : 'No Tasks Available',
          description: countFilters(applyFilter)
            ? 'Your search did not return any results.\nPlease try refining your search criteria.'
            : 'There are currently no tasks assigned. Please check back later or explore other sections.',
          action: countFilters(applyFilter) ? (
            <Button onClick={resetFilter} className="!px-8">
              Clear
            </Button>
          ) : undefined,
        }}
        filterAlert={{
          color: 'info',
          message: 'Only one filter can be used at a time.',
        }}
        filterAlertChecker={(filter) => countFilters(filter) > 0}
        activeRows={[state.id]}
      />
    </main>
  );
}
