import React from 'react';

type Props = {
  className?: string;
};

const FileCheckWithBackgroundIcon = (props: Props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" fill="#F3F4F6" />
      <path
        d="M13.4997 12V8.104C13.098 8.2144 12.7255 8.4112 12.4222 8.7032L10.0655 10.9656C9.76217 11.2568 9.55717 11.6144 9.44134 12H13.4997Z"
        fill="#1F2A37"
      />
      <path
        d="M21.0547 8H15.1663V12C15.1663 12.8824 14.4188 13.6 13.4997 13.6H9.33301V22.4C9.33301 23.2824 10.0555 24 10.9447 24H21.0555C21.9438 24 22.6663 23.2824 22.6663 22.4V9.6C22.6663 8.7176 21.9438 8 21.0547 8ZM19.0888 16.5656L15.7555 19.7656C15.593 19.9216 15.3797 20 15.1663 20C14.953 20 14.7397 19.9216 14.5772 19.7656L12.9105 18.1656C12.5847 17.8528 12.5847 17.3472 12.9105 17.0344C13.2363 16.7216 13.763 16.7216 14.0888 17.0344L15.1663 18.0688L17.9105 15.4344C18.2363 15.1216 18.763 15.1216 19.0888 15.4344C19.4147 15.7472 19.4147 16.2528 19.0888 16.5656Z"
        fill="#1F2A37"
      />
    </svg>
  );
};

export default FileCheckWithBackgroundIcon;
