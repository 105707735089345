import React from 'react'

export default function IssueBadge({ issueCount, solvedCount }: { issueCount: number; solvedCount: number }) {
  const badgeStyle =
    issueCount === 0 && solvedCount === 0
      ? 'bg-green-100 text-green-800'
      : issueCount > 0
        ? 'bg-red-100 text-red-800'
        : 'bg-green-100 text-green-800'

  const badgeText =
    issueCount === 0 && solvedCount === 0
      ? 'No Issues'
      : issueCount > 0
        ? `${issueCount} ${issueCount === 1 ? 'Issue' : 'Issues'}`
        : `${solvedCount} ${solvedCount === 1 ? 'Issue Fixed' : 'Issues Fixed'}`

  return (
    <span className={`absolute right-12 me-2 rounded px-2.5 py-0.5 text-xs font-medium ${badgeStyle}`}>
      {badgeText}
    </span>
  )
}
