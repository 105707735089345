import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { HiSearch } from 'react-icons/hi';

import { Button } from 'shared/components/button';
import { Dialog, DialogBody, DialogTitle } from 'shared/components/dialog';
import CloseIcon from 'shared/icons/CloseIcon';
import ContainerIcon from 'shared/icons/ContainerIcon';
import FilterIcon from 'shared/icons/FilterIcon';
import { InfoIcon } from 'shared/icons/Info';
import { cn } from 'shared/utils';
import TimeBlock from './Nodes/Blocks/TimeBlock';

interface ChangeDayDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  hideLeftPanel?: boolean;
  containers: any[];
  onUpdateContainer: (ids: string[], data: any) => void;
}

const TIME_SLOTS = ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00'];

const ChangeDayDialog = ({
  isOpen,
  setIsOpen,
  hideLeftPanel = false,
  containers = [],
  onUpdateContainer,
}: ChangeDayDialogProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<string>();
  const [isConfirmActual, setIsConfirmActual] = useState<boolean>(false);
  const [filteredContainers, setFilteredContainers] = useState<any[]>(() => containers);
  const [selectedContainers, setSelectedContainers] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const allContainerIds = filteredContainers.map((container) => container.id);
  const isSelectedAll =
    selectedContainers.length === containers.length || selectedContainers.length === filteredContainers.length;

  const disableApply = !selectedContainers.length || !selectedDate || !selectedTime;

  const handleApplyChanges = () => {
    if (!selectedTime) {
      return;
    }

    const newTime = selectedDate
      ? moment(selectedDate)
          .set({
            hour: parseInt(selectedTime?.split(':')[0]),
            minute: parseInt(selectedTime?.split(':')[1]),
          })
          .toDate()
          .getTime()
      : undefined;
    setIsOpen(false);
    onUpdateContainer(selectedContainers, {
      estimatedTime: newTime,
      ...(isConfirmActual && { actualTime: newTime }),
    });
  };

  useEffect(() => {
    if (searchQuery) {
      setFilteredContainers(containers.filter((container) => container.id?.includes(searchQuery)));
    } else {
      setFilteredContainers(containers);
    }
  }, [searchQuery, allContainerIds, containers]);

  // todo: use real containers data

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={cn('z-50 !p-0', hideLeftPanel ? '!max-w-[555px]' : '!max-w-[830px]')}
    >
      <DialogTitle className="border-b px-5 py-3">
        <div className="flex items-center justify-between">
          <span className="text-[14px] font-semibold leading-[1.5] text-gray-900">Change pickup day</span>
          <CloseIcon onClick={() => setIsOpen(false)} className="cursor-pointer" />
        </div>
      </DialogTitle>

      <DialogBody className="!m-0 rounded-lg bg-white">
        <div className={cn(!hideLeftPanel && 'grid grid-cols-[285px,1fr]')}>
          {/* Left side - Container selection */}
          {!hideLeftPanel && (
            <div className="border-r0-black space-y-4 border-r pb-6 pt-3">
              <div className="flex items-center justify-between gap-4 pl-[10px] pr-2">
                <div className="relative">
                  <HiSearch className="absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search containers"
                    className="w-full rounded-lg border border-gray-300 py-1.5 pl-[42px] pr-4 focus:outline-none"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <FilterIcon />
              </div>

              <div className="flex items-center justify-between pr-2 text-sm">
                <div className="flex items-center gap-1 pl-[10px] pr-3">
                  <input
                    id="ChangeDayDialog_selectedAll"
                    type="checkbox"
                    checked={isSelectedAll}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedContainers(allContainerIds);
                      } else {
                        setSelectedContainers([]);
                      }
                    }}
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label htmlFor="ChangeDayDialog_selectedAll" className="text-[14px] font-semibold text-gray-900">
                    Select all
                  </label>
                  <InfoIcon className="size-[13.3px] [&_*]:fill-gray-500" />
                </div>
                <span className="text-[12px] text-gray-500">
                  <span className="font-bold text-black">
                    {selectedContainers.length}/{containers?.length || 0}
                  </span>{' '}
                  Container selected
                </span>
              </div>

              <div className="mt-2">
                {filteredContainers.map((container, index) => {
                  const isSelected = selectedContainers.includes(container.id);
                  const { estimatedTime, actualTime, isCompleted = false } = container || {};

                  return (
                    <div
                      key={index}
                      className={cn(
                        'flex gap-3 border-gray-200 pb-3 pl-[10px] pr-2 pt-[15px]',
                        isSelected && 'bg-[#2463EB1A]'
                      )}
                    >
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedContainers([...selectedContainers, container.id]);
                          } else {
                            setSelectedContainers(selectedContainers.filter((id) => id !== container.id));
                          }
                        }}
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <div className="flex-1">
                        <div className="flex items-center space-x-2">
                          <ContainerIcon variant="active" className="size-[22px] text-gray-400" />
                          <span className="font-medium">{container.id}</span>
                        </div>
                        <TimeBlock
                          variant="pickup"
                          vehicle="truck"
                          estimatedTime={estimatedTime}
                          actualTime={actualTime}
                          isCompleted={isCompleted}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* Right side - Calendar and time slots */}
          <div className="p-4 pb-6">
            {/* <div className="flex flex-col gap-2 pl-3">
              <div className="flex gap-1.5">
                <ContainerIcon variant="active" className="size-[22px] text-gray-400" />
                <span className="font-medium">{containers[0]?.id}</span>
              </div>
              <div className="flex items-center gap-1.5">
                <TruckIcon className="size-[22px] text-gray-900" />
                <span className="text-[14px]">
                  Est Pickup: <span className="font-bold">{moment(new Date()).format('DD-MM-yy, HH:mm')}</span>
                </span>
                <ClockFastForwardIcon className="size-3" />
              </div>
            </div> */}

            <div className="mt-6 grid grid-cols-[auto,1fr] gap-8">
              <DayPicker
                mode="single"
                selected={selectedDate}
                onSelect={setSelectedDate}
                showOutsideDays
                className="p-0"
                classNames={{
                  months: 'flex flex-col space-y-4',
                  month: 'space-y-4',
                  caption: 'flex justify-center relative items-center',
                  caption_label: 'text-[12px] font-medium',
                  nav: 'space-x-1 flex items-center',
                  nav_button: 'size-4 bg-transparent p-0 opacity-100',
                  nav_button_previous: 'absolute left-1 ',
                  nav_button_next: 'absolute right-1',
                  table: 'w-full border-collapse mt-2',
                  head_row: 'flex',
                  head_cell: 'text-gray-500 rounded-none w-9 font-normal text-[0.8rem] border-none',
                  row: 'flex w-full',
                  cell: 'text-center text-[12px] relative p-0 hover:bg-gray-100 text-gray-900  rounded-md',
                  day: 'h-[34px] w-9 p-0 font-bold',
                  day_selected:
                    'bg-primary-700 rounded-md text-white hover:bg-primary-400 hover:text-white focus:bg-primary-400 focus:text-white',
                  day_today: 'rounded-md bg-gray-50',
                  day_outside: 'text-gray-300',
                  day_disabled: 'text-gray-300',
                  day_hidden: 'invisible',
                }}
                components={{
                  IconLeft: () => <ArrowLeftIcon className="h-4 w-4 text-gray-900" />,
                  IconRight: () => <ArrowRightIcon className="h-4 w-4 text-gray-900" />,
                }}
                formatters={{
                  formatWeekdayName: (day) => day?.toLocaleDateString('en-US', { weekday: 'short' }),
                }}
              />

              <div className="grid h-fit grid-cols-3 gap-2 py-[14px]">
                {TIME_SLOTS.map((time, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedTime(time)}
                    className={`h-[34px] rounded-md border p-2 text-center text-[12px] font-bold ${
                      selectedTime === time ? 'border-black bg-black text-white' : 'border-gray-200 hover:border-black'
                    }`}
                  >
                    {time}
                  </button>
                ))}
              </div>
            </div>

            <div className="mt-[52px] flex items-center justify-end gap-5">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  onChange={(e) => setIsConfirmActual(e.target.checked)}
                />
                <span className="text-sm text-gray-600">Confirm as Actual</span>
              </label>

              <Button
                disabled={disableApply}
                onClick={handleApplyChanges}
                className="rounded-md bg-primary-700 px-4 py-2 text-sm font-medium text-white hover:bg-gray-800"
              >
                Apply Changes
              </Button>
            </div>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default ChangeDayDialog;
