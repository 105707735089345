import React from 'react';

type Props = {
  className?: string;
};

const TickIcon = (props: Props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2918 3.76827C13.4199 4.11346 13.2439 4.49713 12.8987 4.62521C12.1928 4.88712 11.4184 5.43227 10.6344 6.15256C9.85783 6.866 9.10949 7.716 8.45303 8.54046C7.79783 9.36326 7.24216 10.1503 6.85016 10.7321C6.6544 11.0226 6.50001 11.2611 6.39503 11.4263C6.34255 11.5088 6.30245 11.5729 6.27575 11.616L6.24586 11.6644L6.23869 11.6761L6.23709 11.6788C6.1112 11.8863 5.88215 12.0095 5.63963 11.9996C5.39707 11.9897 5.17907 11.8488 5.0705 11.6317C4.61895 10.7285 4.10619 10.2274 3.733 9.956C3.54481 9.81913 3.38811 9.7378 3.28665 9.69273C3.23587 9.67013 3.19887 9.65666 3.17867 9.6498C3.17015 9.64686 3.16463 9.6452 3.16234 9.64453C2.81076 9.55126 2.59818 9.19253 2.68669 8.83846C2.77599 8.48126 3.13795 8.26413 3.49514 8.3534C3.51163 8.35773 3.49594 8.3536 3.49594 8.3536L3.49677 8.3538L3.49851 8.35426L3.50239 8.35526L3.51163 8.35773C3.51845 8.3596 3.52657 8.36186 3.53593 8.3646C3.55467 8.37006 3.57841 8.37746 3.60672 8.387C3.66334 8.4062 3.73833 8.4344 3.82817 8.47433C4.00795 8.5542 4.2471 8.6812 4.51723 8.87766C4.87907 9.14086 5.28987 9.5238 5.68591 10.0741C5.70501 10.0456 5.72451 10.0166 5.74443 9.987C6.15021 9.3848 6.72696 8.56766 7.40996 7.70986C8.09169 6.8538 8.88749 5.94685 9.73229 5.17071C10.5696 4.40142 11.4937 3.72435 12.4349 3.37514C12.7801 3.24707 13.1638 3.42308 13.2918 3.76827Z"
        fill="#D1D5DB"
      />
    </svg>
  );
};

export default TickIcon;
