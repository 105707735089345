import React from 'react'

export default function FileCheck02(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.3332 8.33398V4.53398C13.3332 3.41388 13.3332 2.85383 13.1152 2.426C12.9234 2.04968 12.6175 1.74372 12.2412 1.55197C11.8133 1.33398 11.2533 1.33398 10.1332 1.33398H5.8665C4.7464 1.33398 4.18635 1.33398 3.75852 1.55197C3.3822 1.74372 3.07624 2.04968 2.88449 2.426C2.6665 2.85383 2.6665 3.41388 2.6665 4.53398V11.4673C2.6665 12.5874 2.6665 13.1475 2.88449 13.5753C3.07624 13.9516 3.3822 14.2576 3.75852 14.4493C4.18635 14.6673 4.7464 14.6673 5.8665 14.6673H7.99984M9.33317 7.33398H5.33317M6.6665 10.0007H5.33317M10.6665 4.66732H5.33317M9.6665 12.6673L10.9998 14.0007L13.9998 11.0007"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
