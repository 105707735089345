import React from 'react';

type Props = {
  className?: string;
};

const ArrowUpRightDownLeftIcon = (props: Props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.7428 1.59998H14.4M14.4 1.59998V5.25712M14.4 1.59998L9.82855 6.39358M5.25712 14.4H1.59998M1.59998 14.4V10.7428M1.59998 14.4L6.39358 9.82855"
        stroke="#1F2A37"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUpRightDownLeftIcon;
