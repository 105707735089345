import { WhereFilterOp } from 'firebase/firestore';

import { DateFilterValue } from 'shared/components/date-filter';

export type DocumentQueryRequest = {
  page_size: number;
  page: number;
  name?: string;
  client_shipment_id?: string[];
  type?: string;
  status?: string[];
  createdAt?: DateFilterValue;
  order_by?: string;
  order_direction?: string;
  orgId: string;
};

type ShipmentFilterKey = keyof Omit<
  DocumentQueryRequest,
  'page' | 'page_size' | 'orgId' | 'order_by' | 'order_direction'
>;

type FilterConditions = Record<
  ShipmentFilterKey,
  {
    operation: 'in' | 'like' | 'array-contains-any' | 'array-contains';
    field: string;
  }
>;

type TFilter = {
  key: string;
  value: string | string[] | Date;
  operation: WhereFilterOp | 'include';
};

export default function convertDocumentQueryRequestToFilter(filter: DocumentQueryRequest) {
  const filterConditions: FilterConditions = {
    name: {
      operation: 'like',
      field: 'metadata.received_email_doc_filename',
    },
    client_shipment_id: {
      operation: 'in',
      field: 'client_shipment_id',
    },
    type: {
      operation: 'in',
      field: 'type',
    },
    status: {
      operation: 'in',
      field: 'processing_result.status_code',
    },
    createdAt: {
      operation: 'in',
      field: 'created_at',
    },
  };

  const filters: Array<TFilter> = [];

  const filterKeys = Object.keys(filter) as ShipmentFilterKey[];

  for (let key of filterKeys) {
    if (!filter[key] || !filterConditions[key]) {
      continue;
    }

    const condition = filterConditions[key] as Record<string, any>;
    const value = filter[key] as any;

    if (condition.operation === 'in' && Array.isArray(value) && value.length > 0) {
      filters.push({
        key: condition.field,
        value: value,
        operation: 'in',
      });
    }

    if (condition.operation === 'in' && typeof value === 'object' && !Array.isArray(value)) {
      if (value.range.from && value.range.to) {
        filters.push({
          key: condition.field,
          value: value.range.from,
          operation: '>=',
        });
        filters.push({
          key: condition.field,
          value: value.range.to,
          operation: '<=',
        });
      }
    }

    if (condition.operation === 'like' && !!value) {
      filters.push({
        key: condition.field,
        value: value as string,
        operation: 'include' as any,
      });
    }
  }

  return filters;
}
