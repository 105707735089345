import { routesKeys } from 'containers/Shipments/Details/model'

export default function transformRouteData(data: Record<string, string>): Record<string, any> {
  return Object.entries(data).reduce<Record<string, any>>((acc, [key, value]) => {
    const routeKey = routesKeys.find((route) => route.key === key)
    if (!routeKey) return { ...acc, [key]: value }

    const resolvedValue = value

    const accesorValue = routeKey.accesorKey ? { [routeKey.accesorKey]: resolvedValue } : resolvedValue

    acc[key] = routeKey.appendKey
      ? { ...(typeof accesorValue === 'object' ? accesorValue : {}), [routeKey.appendKey]: 'unit' }
      : accesorValue

    return acc
  }, {})
}
