import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from '@firebase/firestore';
import { where, WhereFilterOp } from 'firebase/firestore';
import get from 'lodash/get';

import { db } from '../config';
import { Collection } from '../enums';
import { ExportDataRequest, FetchShipmentsByIdRequest, Filter, PaginatedQueryRequest } from '../model';
import { getTotalCount, normalizeShipmentDocumentData } from '../utils';

export type WhereFilterOpWithAdditionalField = WhereFilterOp | 'include';

const shipmentViewCollection = collection(db, Collection.Shipments);

export const shipmentsListener = async (request: PaginatedQueryRequest, callback: any) => {
  let pageCount = query(
    shipmentViewCollection,
    where('org_id', '==', request.orgId),
    orderBy(request.sort?.key || 'created_at', request.sort?.value || 'desc')
  );

  const page = request.page || 1;
  const pageSize = request.page_size || 10;

  if (request.filter?.[0]?.operation === ('include' as WhereFilterOpWithAdditionalField)) {
    return onSnapshot(
      pageCount,
      async () => {
        try {
          const querySnapshot = await getDocs(pageCount);
          const shipments = querySnapshot.docs
            .filter((doc: any) => {
              const data = doc.data();
              const filter = request.filter[0];
              return get(data, filter.key)?.toLowerCase().includes(filter.value.toString()?.toLowerCase());
            })
            .map((doc: any) => normalizeShipmentDocumentData(doc.data()));

          const startIndex = (page - 1) * pageSize;
          const endIndex = startIndex + pageSize;
          const data = shipments.slice(startIndex, endIndex);
          callback?.({
            shipments: data,
            totalPages: Math.ceil(shipments.length / request.page_size),
            totalData: shipments.length,
          });
        } catch (error: any) {
          console.error('Error in shipmentsListener: ', error.message);
        }
      },
      (error) => {
        console.error('Error in shipmentsListener: ', error.message);
      }
    );
  }

  if (request.filter?.length) {
    request.filter?.forEach((filterItem) => {
      pageCount = query(pageCount, where(filterItem.key, filterItem.operation, filterItem.value));
    });
  }

  let q = pageCount;
  if (page > 1) {
    const offset = (page - 1) * pageSize;
    const offsetQuery = query(q, limit(offset));
    const offsetDocs = await getDocs(offsetQuery);
    if (!offsetDocs.empty) {
      const lastDoc = offsetDocs.docs[offsetDocs.docs.length - 1];
      if (lastDoc) {
        q = query(q, startAfter(lastDoc), limit(pageSize));
      }
    }
  } else {
    q = query(q, limit(pageSize));
  }

  return onSnapshot(
    q,
    async () => {
      try {
        const querySnapshot = await getDocs(q);
        const shipments = querySnapshot.docs.map((doc: any) => normalizeShipmentDocumentData(doc.data()));

        const totalData = await getTotalCount(pageCount);

        callback?.({
          shipments,
          totalPages: Math.ceil(totalData / request.page_size),
          totalData,
        });
      } catch (error: any) {
        console.error('Error in shipmentsListener: ', error.message);
      }
    },
    (error) => {
      console.error('Error in shipmentsListener: ', error.message);
    }
  );
};

export const getTotalShipmentCount = async ({ orgId }: { orgId: string }, callback?: (count: number) => void) => {
  let q = query(shipmentViewCollection, where('org_id', '==', orgId));

  const snapshot = await getCountFromServer(q);
  callback?.(snapshot.data().count);
};

export const getAllClientShipmentIds = async (
  { orgId }: { orgId: string },
  callback?: (clientShipmentIds: string[]) => void
) => {
  let q = query(shipmentViewCollection, where('org_id', '==', orgId));
  const snapshot = await getDocs(q);
  const clientShipmentIds = snapshot.docs.map((doc) => doc.data().client_shipment_id);
  callback?.(clientShipmentIds);
};

export const listenerForShipmentStats = (request: PaginatedQueryRequest, callback?: any) => {
  let q = query(shipmentViewCollection, where('org_id', '==', request.orgId));
  const needsAttention = query(q, where('validation_result.status_code', '==', 'discrepancy'));
  const inProgress_partialok = query(q, where('validation_result.status_code', '==', 'partial_ok'));
  const inProgress_error = query(q, where('validation_result.status_code', '==', 'error'));
  const completed = query(q, where('validation_result.status_code', '==', 'all_ok'));
  return onSnapshot(q, async (snapShot) => {
    const needsAttentionQuerySnapshot = await getDocs(needsAttention);
    const inProgressPartialOKQuerySnapshot = await getDocs(inProgress_partialok);
    const inProgressErrorQuerySnapshot = await getDocs(inProgress_error);
    const completedQuerySnapshot = await getDocs(completed);

    callback?.({
      needsAttention: needsAttentionQuerySnapshot?.docs?.length,
      inProgress:
        inProgressPartialOKQuerySnapshot.docs.length +
        inProgressErrorQuerySnapshot.docs.length +
        needsAttentionQuerySnapshot?.docs?.length,
      completed: completedQuerySnapshot.docs.length,
      missingDocuments: inProgressPartialOKQuerySnapshot.docs.length,
    });
  });
};

const searchKeyMap: {
  [key: string]: {
    field: string;
    operand: WhereFilterOp;
  };
} = {
  containerId: {
    field: 'labels.ocean_container_numbers',
    operand: 'array-contains',
  },
  hblNumber: {
    field: 'data.house_bill_of_lading_number',
    operand: '==',
  },
};

export const filterShipmentsBySearchKey = async (request: ExportDataRequest) => {
  const searchConfig = searchKeyMap[request.exportKeyType];

  if (!searchConfig) {
    throw new Error('Invalid exportKeyType');
  }

  const q = query(
    shipmentViewCollection,
    where('org_id', '==', request.orgId),
    where(searchConfig.field, searchConfig.operand, request.exportKeyValue),
    orderBy('created_at', 'desc')
  );

  const querySnapShot = await getDocs(q);
  return querySnapShot.docs.map((doc: any) => {
    const d = doc.data();
    return {
      ...d,
    };
  });
};

export const listenShipmentDetails = (request: FetchShipmentsByIdRequest, callback: (shipment: any) => void) => {
  let q = query(shipmentViewCollection, where('org_id', '==', request.orgId), where('id', '==', request.shipmentId));

  return onSnapshot(q, async () => {
    const querySnapshot = await getDocs(q);
    const shipment = querySnapshot.docs.map((doc: any) => normalizeShipmentDocumentData(doc.data()));
    callback?.(shipment[0]);
  });
};

export const getAllShipments = async ({
  orgId,
  filters = [],
  sort,
  callback,
}: {
  orgId: string;
  filters?: Filter[];
  sort?: {
    value: 'asc' | 'desc';
    key: string;
  };
  callback?: (...args: any[]) => void;
}) => {
  try {
    let shipmentQuery = query(
      shipmentViewCollection,
      where('org_id', '==', orgId),
      orderBy(sort?.key || 'created_at', sort?.value || 'desc')
    );

    if (filters?.length) {
      filters.forEach((filterItem) => {
        shipmentQuery = query(shipmentQuery, where(filterItem.key, filterItem.operation, filterItem.value));
      });
    }

    if (callback) {
      getDocs(shipmentQuery).then((result) => {
        const data = result.docs.map((doc) => normalizeShipmentDocumentData(doc.data()));
        callback(data);
      });

      return null;
    } else {
      const result = await getDocs(shipmentQuery);

      return result.docs.map((doc) => normalizeShipmentDocumentData(doc.data()));
    }
  } catch (error: any) {
    console.error('Error in getAllShipments: ', error.message);
    return null;
  }
};

export const getShipmentById = async (shipmentId: string) => {
  try {
    const q = query(shipmentViewCollection, where('id', '==', shipmentId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc: any) => normalizeShipmentDocumentData(doc.data()))[0];
  } catch (error: any) {
    console.error('Error in getShipmentById: ', error.message);
    return null;
  }
};
