import { collection, getDocs, onSnapshot, orderBy, query, Timestamp, where } from '@firebase/firestore';

import { convertMillisecondToLocaleString } from 'shared/utils/date';
import { db } from '../config';
import { FetchTasksByIdRequest, PaginatedQueryRequest } from '../model';
import { getTotalCount } from '../utils';

// Function to listen for task updates based on filters and pagination
export const tasksListener = (request: PaginatedQueryRequest, callback: any) => {
  const taskViewCollection = collection(db, 'tasks');

  let pageCount = query(
    taskViewCollection,
    where('org_id', '==', request.orgId),
    where('category', '==', 'freight_forwarder_task'),
    orderBy(request.sort?.key || 'created_at', request.sort?.value || 'desc')
  );

  if (request.filter?.length) {
    request.filter?.forEach((filterItem) => {
      pageCount = query(pageCount, where(filterItem.key, filterItem.operation, filterItem.value));
    });
  }

  if (request.defaultFilter?.length) {
    request.defaultFilter?.forEach((filterItem) => {
      pageCount = query(pageCount, where(filterItem.key, filterItem.operation, filterItem.value));
    });
  }

  let q = pageCount;

  return onSnapshot(q, async (querySnapshot) => {
    const tasks = querySnapshot.docs.map((doc) => {
      const data = doc.data();

      return {
        ...data,
        schedule: {
          ...data.schedule,
          due_at:
            data.schedule.due_at instanceof Timestamp
              ? data.schedule.due_at.toDate().getTime() // Convert to milliseconds
              : data.schedule.due_at,
        },
        created_at: convertMillisecondToLocaleString(data?.created_at?.toMillis()),
        updated_at: convertMillisecondToLocaleString(data?.updated_at?.toMillis()),
      };
    });

    const totalData = await getTotalCount(pageCount);

    callback?.({
      tasks,
      totalPages: Math.ceil(totalData / request.page_size),
      totalData,
    });
  });
};

export const allTasksListener = (request: PaginatedQueryRequest, callback: any) => {
  const taskViewCollection = collection(db, 'tasks');
  let q = query(taskViewCollection, where('org_id', '==', request.orgId), orderBy('created_at', 'desc'));

  return onSnapshot(q, (querySnapshot) => {
    const tasks = querySnapshot.docs.map((doc) => {
      const data = doc.data();

      return {
        ...data,
        created_at: convertMillisecondToLocaleString(data?.created_at?.toMillis()),
        updated_at: convertMillisecondToLocaleString(data?.updated_at?.toMillis()),
      };
    });

    callback?.({
      tasks,
    });
  });
};

// Function to listen for task stats updates
export const taskStatsListener = (request: PaginatedQueryRequest, callback?: any) => {
  const taskViewCollection = collection(db, 'tasks');

  // Get the current time
  const currentTime = new Date();

  // Get the start of tomorrow (i.e., midnight of the next day)
  const startOfTomorrow = new Date();
  startOfTomorrow.setHours(24, 0, 0, 0); // Set time to midnight of the next day

  // Query to only get tasks with category 'freight_forwarder_task'
  let freightForwarderQuery = query(
    taskViewCollection,
    where('org_id', '==', request.orgId),
    where('category', '==', 'freight_forwarder_task')
  );

  if (request.defaultFilter?.length) {
    request.defaultFilter?.forEach((filterItem) => {
      freightForwarderQuery = query(
        freightForwarderQuery,
        where(filterItem.key, filterItem.operation, filterItem.value)
      );
    });
  }

  // Use a single onSnapshot listener for the freight_forwarder tasks
  return onSnapshot(freightForwarderQuery, (querySnapshot) => {
    let overdueCount = 0;
    let dueByEODCount = 0;
    let completedCount = 0;
    let openCount = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();

      // Count open tasks
      if (data.status !== 'completed') {
        openCount++;
      }

      // Count completed tasks
      if (data.status === 'completed') {
        completedCount++;
      }

      // Check if the task is overdue
      if (data.status !== 'completed' && data.schedule?.due_at?.toDate() < currentTime) {
        overdueCount++;
      }

      // Check if the task is due by the end of today
      if (
        data.status !== 'completed' &&
        data.schedule?.due_at?.toDate() >= currentTime &&
        data.schedule?.due_at?.toDate() < startOfTomorrow
      ) {
        dueByEODCount++;
      }
    });

    // Callback with the calculated counts
    callback?.({
      total: querySnapshot.size,
      overdue: overdueCount,
      dueByEOD: dueByEODCount,
      completed: completedCount,
      open: openCount,
    });
  });
};

export const taskDetailsListener = (request: FetchTasksByIdRequest, callback: (task: any) => void) => {
  const taskViewCollection = collection(db, 'tasks');
  let q = query(taskViewCollection, where('org_id', '==', request.orgId), where('id', '==', request.taskId));

  return onSnapshot(q, async () => {
    const querySnapshot = await getDocs(q);
    const task = querySnapshot.docs.map((doc: any) => {
      const data = doc.data();
      return {
        ...data,
        created_at: data?.created_at?.toMillis(),
        updated_at: data?.updated_at?.toMillis(),
      };
    });
    callback?.(task[0]);
  });
};

export async function findTaskDiscrepancy(shipmentId: string) {
  const ref = collection(db, 'tasks');

  const q = query(
    ref,
    where('data.client_shipment_id', '==', shipmentId),
    where('type', '==', 'shipment_data_discrepancy'),
    where('status', '==', 'assigned')
  );

  try {
    const doc = await getDocs(q);
    const result = doc.docs.map((doc) => {
      return doc.data();
    });
    return result[result.length - 1];
  } catch (error) {
    console.error(error);
    return null;
  }
}
