import { Background, Controls, Edge, MarkerType, Node, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

import { TAddress, TOceanContainer } from 'shared/constants/types';
import formatAddress from 'shared/utils/formatAddress';
import { EShipmentRouteHandleId, EShipmentRouteNode, EShipmentRouteVariant, NodeKey } from '../enums';
import { getCoordinatesGenerator } from '../helpers';
import ChangeDayDialog from './ChangeDayDialog';
import ContainerListDialog from './ContainerListDialog';
import CustomEdge from './Edges/CustomEdge';
import EdgeLabel from './Edges/EdgeLabel';
import ConsigneeNode from './Nodes/ConsigneeNode';
import PlaceOfDeliveryNode from './Nodes/PlaceOfDeliveryNode';
import PlaceOfReceiptNode from './Nodes/PlaceOfReceiptNode';
import PortOfDischargeNode from './Nodes/PortOfDischargeNode';
import PortOfLoadingNode from './Nodes/PortOfLoadingNode';
import ShipperNode from './Nodes/ShipperNode';

type ShipmentRouteProps = {
  shipment: any;
  maxHeight: number;
};

type TExtraParams = {
  setIsContainerListDialogOpen: (value: boolean) => void;
};

const generateNodes = (shipment: any, { setIsContainerListDialogOpen }: TExtraParams) => {
  const shipper: TAddress = get(shipment, 'data.shipper', {});
  const placeOfReceipt = get(shipment, 'data.place_of_receipt', '--');
  const portOfLoading = get(shipment, 'data.port_of_loading.unloc', '--');
  const portOfDischarge = get(shipment, 'data.port_of_discharge.unloc', '--');
  const placeOfDelivery = get(shipment, 'data.place_of_delivery');
  const consignee: TAddress = get(shipment, 'data.consignee', {});
  const containers = get(shipment, 'data.ocean_containers', []);

  const newNodes: Node[] = [];
  const generateCoordinates = getCoordinatesGenerator();

  const handleOpenContainerListDialog = () => {
    setIsContainerListDialogOpen(true);
  };

  newNodes.push({
    id: NodeKey.shipper,
    type: EShipmentRouteNode.shipper,
    position: generateCoordinates(),
    data: {
      label: 'Shipper',
      name: shipper.name || '--',
      address: formatAddress(shipper),
      variant: EShipmentRouteVariant.default,
      containers,
      onOpenContainerList: handleOpenContainerListDialog,
    },
    draggable: false,
  });

  const hasPlaceOfReceiptNode = placeOfReceipt !== portOfLoading;
  if (hasPlaceOfReceiptNode) {
    newNodes.push({
      id: NodeKey.placeOfReceipt,
      type: EShipmentRouteNode.placeOfReceipt,
      position: generateCoordinates(true),
      data: {
        label: 'Place of Receipt',
        location: placeOfReceipt,
        variant: EShipmentRouteVariant.inactive,
        containers,
        onOpenContainerList: handleOpenContainerListDialog,
      },
      draggable: false,
    });
  }

  newNodes.push(
    {
      id: NodeKey.portOfLoading,
      type: EShipmentRouteNode.portOfLoading,
      position: generateCoordinates(!hasPlaceOfReceiptNode),
      data: {
        label: 'Port of Loading',
        location: portOfLoading,
        variant: EShipmentRouteVariant.active,
        containers,
        onOpenContainerList: handleOpenContainerListDialog,
      },
      draggable: false,
    },
    {
      id: NodeKey.portOfDischarge,
      type: EShipmentRouteNode.portOfDischarge,
      position: generateCoordinates(),
      data: {
        label: 'Port of Discharge',
        location: portOfDischarge,
        variant: EShipmentRouteVariant.inactive,
        containers,
        onOpenContainerList: handleOpenContainerListDialog,
      },
      draggable: false,
    }
  );

  if (placeOfDelivery !== portOfDischarge) {
    newNodes.push({
      id: NodeKey.placeOfDelivery,
      type: EShipmentRouteNode.placeOfDelivery,
      position: generateCoordinates(),
      data: {
        label: 'Place of Delivery',
        location: placeOfDelivery,
        variant: EShipmentRouteVariant.default,
        containers,
        onOpenContainerList: handleOpenContainerListDialog,
      },
      draggable: false,
    });
  }

  newNodes.push({
    id: NodeKey.consignee,
    type: EShipmentRouteNode.consignee,
    position: generateCoordinates(),
    data: {
      label: 'Consignee',
      name: consignee.name || '--',
      address: formatAddress(consignee),
      variant: EShipmentRouteVariant.default,
      containers,
      onOpenContainerList: handleOpenContainerListDialog,
    },
    draggable: false,
  });

  return newNodes;
};

const DEFAULT_EDGE_OPTIONS = {
  sourceHandle: EShipmentRouteHandleId.bottom,
  targetHandle: EShipmentRouteHandleId.top,
  type: 'custom',
  label: <EdgeLabel type="truck" />,
  style: { stroke: '#5850EC' },
  markerEnd: { type: MarkerType.ArrowClosed, orient: '90' },
};

const generateEdges = (nodes: Node[], shipment: any) => {
  if (!nodes.length || !shipment) {
    return [];
  }

  const placeOfReceiptNode = nodes.find((node) => node.id === NodeKey.placeOfReceipt);
  const placeOfDeliveryNode = nodes.find((node) => node.id === NodeKey.placeOfDelivery);

  const newEdges: Edge[] = [];

  if (placeOfReceiptNode) {
    newEdges.push(
      {
        ...DEFAULT_EDGE_OPTIONS,
        id: `edge_${NodeKey.shipper}_${NodeKey.placeOfReceipt}`,
        source: NodeKey.shipper,
        target: NodeKey.placeOfReceipt,
        label: <EdgeLabel type="truckWithContainer" />,
      },
      {
        ...DEFAULT_EDGE_OPTIONS,
        id: `edge_${NodeKey.placeOfReceipt}_${NodeKey.portOfLoading}`,
        source: NodeKey.placeOfReceipt,
        target: NodeKey.portOfLoading,
      }
    );
  } else {
    newEdges.push({
      ...DEFAULT_EDGE_OPTIONS,
      id: `edge_${NodeKey.shipper}_${NodeKey.portOfDischarge}`,
      source: NodeKey.shipper,
      target: NodeKey.portOfDischarge,
    });
  }

  newEdges.push({
    ...DEFAULT_EDGE_OPTIONS,
    id: `edge_${NodeKey.portOfLoading}_${NodeKey.portOfDischarge}`,
    source: NodeKey.portOfLoading,
    target: NodeKey.portOfDischarge,
    style: { strokeDasharray: '5,5' },
  });

  if (placeOfDeliveryNode) {
    newEdges.push(
      {
        ...DEFAULT_EDGE_OPTIONS,
        id: `edge_${NodeKey.portOfDischarge}_${NodeKey.placeOfDelivery}`,
        source: NodeKey.portOfDischarge,
        target: NodeKey.placeOfDelivery,
      },
      {
        ...DEFAULT_EDGE_OPTIONS,
        id: `edge_${NodeKey.placeOfDelivery}_${NodeKey.consignee}`,
        source: NodeKey.placeOfDelivery,
        target: NodeKey.consignee,
      }
    );
  } else {
    newEdges.push({
      ...DEFAULT_EDGE_OPTIONS,
      id: `edge_${NodeKey.portOfDischarge}_${NodeKey.consignee}`,
      source: NodeKey.portOfDischarge,
      target: NodeKey.consignee,
      label: <EdgeLabel type="truckWithContainer" />,
    });
  }

  return newEdges;
};

export default function ShipmentRoute({ shipment, maxHeight }: ShipmentRouteProps) {
  const [isContainerListDialogOpen, setIsContainerListDialogOpen] = useState(false);
  const [isChangeDayDialogOpen, setIsChangeDayDialogOpen] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);

  const rawContainers: TOceanContainer[] = get(shipment, 'data.ocean_containers', []);

  // todo: need to use time data from BE
  const [containers, setContainers] = useState(() =>
    rawContainers.map((data) => ({
      ...data,
      id: data.container_number,
      estimatedTime: new Date(2025, 0, 1).getTime(),
    }))
  );

  const handleOpenEditDayDialog = () => {
    setIsChangeDayDialogOpen(true);
    setIsContainerListDialogOpen(false);
  };

  const handleChangeContainers = (ids: string[], data: any) => {
    // todo need to call api to BE and update new container data
    const newContainers = containers.map((container) => {
      if (container.id && ids.includes(container.id)) {
        return {
          ...container,
          ...data,
        };
      }
      return container;
    });
    setContainers(newContainers);
  };

  useEffect(() => {
    // todo: create nodes: shipper --> place of receipt --> port of loading --> port of discharge --> place of delivery --> consignee
    if (!shipment) return;

    const newNodes = generateNodes(shipment, {
      setIsContainerListDialogOpen,
    });
    const newEdges = generateEdges(newNodes, shipment);
    setEdges(newEdges);
    setNodes(newNodes);
  }, [shipment, isContainerListDialogOpen, isChangeDayDialogOpen, setNodes, setEdges]);

  return (
    <div className="h-[800px] w-full rounded-lg bg-white" style={{ height: maxHeight }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={{
          [EShipmentRouteNode.placeOfReceipt]: PlaceOfReceiptNode,
          [EShipmentRouteNode.portOfLoading]: PortOfLoadingNode,
          [EShipmentRouteNode.portOfDischarge]: PortOfDischargeNode,
          [EShipmentRouteNode.placeOfDelivery]: PlaceOfDeliveryNode,
          [EShipmentRouteNode.consignee]: ConsigneeNode,
          [EShipmentRouteNode.shipper]: ShipperNode,
        }}
        edgeTypes={{
          custom: CustomEdge,
        }}
        nodeClickDistance={5}
        attributionPosition="top-right"
        fitView
      >
        <Background color="#f1f5f9" />
        <Controls />
      </ReactFlow>

      <ContainerListDialog
        isOpen={isContainerListDialogOpen}
        setIsOpen={setIsContainerListDialogOpen}
        onOpenEditDayDialog={handleOpenEditDayDialog}
        containers={containers}
        shipmentData={shipment?.data || {}}
      />

      <ChangeDayDialog
        isOpen={isChangeDayDialogOpen}
        setIsOpen={setIsChangeDayDialogOpen}
        containers={containers}
        onUpdateContainer={handleChangeContainers}
      />
    </div>
  );
}
