import { EShipmentRouteVariant } from 'models/ShipmentDetails/enums';
import React from 'react';

import ContainerIcon from 'shared/icons/ContainerIcon';
import ShipIcon from 'shared/icons/ShipIcon';
import TruckIcon from 'shared/icons/TruckIcon';

type EdgeLabelProps = {
  variant?: EShipmentRouteVariant;
  type?: 'ship' | 'truck' | 'truckWithContainer';
};

const EdgeLabel = ({ variant = EShipmentRouteVariant.default, type = 'truck' }: EdgeLabelProps) => {
  switch (type) {
    case 'ship':
    case 'truck':
      return (
        <div className="flex size-8 items-center justify-center">{type === 'ship' ? <ShipIcon /> : <TruckIcon />}</div>
      );

    case 'truckWithContainer':
      return (
        <div className="flex items-center justify-center gap-1 rounded-[100px] border border-indigo-100 bg-white px-[7px] py-[3px]">
          <TruckIcon className="size-5 [&_path]:fill-indigo-600" />
          <div className="flex items-center leading-[1] text-indigo-600">
            <ContainerIcon variant="active" className="size-4" />
            <span className="text-[12px] font-bold">C123456</span>
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default EdgeLabel;
