import React from 'react';

type Props = {
  onClick?: () => void;
  className?: string;
};

const CheckCircleIcon = (props: Props) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_55271_7638)">
        <path
          d="M8 0.5C6.41775 0.5 4.87103 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346627 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C15.9977 6.37898 15.1541 4.3455 13.6543 2.84572C12.1545 1.34593 10.121 0.502329 8 0.5ZM10.9656 7.4656L7.7656 10.6656C7.61558 10.8156 7.41213 10.8998 7.2 10.8998C6.98787 10.8998 6.78442 10.8156 6.6344 10.6656L5.0344 9.0656C4.88868 8.91472 4.80804 8.71263 4.80986 8.50288C4.81169 8.29312 4.89582 8.09247 5.04415 7.94414C5.19247 7.79582 5.39312 7.71168 5.60288 7.70986C5.81264 7.70804 6.01472 7.78867 6.1656 7.9344L7.2 8.9688L9.8344 6.3344C9.98528 6.18867 10.1874 6.10804 10.3971 6.10986C10.6069 6.11168 10.8075 6.19582 10.9559 6.34414C11.1042 6.49247 11.1883 6.69312 11.1901 6.90288C11.192 7.11263 11.1113 7.31472 10.9656 7.4656Z"
          fill="#0E9F6E"
        />
      </g>
      <defs>
        <clipPath id="clip0_55271_7638">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckCircleIcon;
