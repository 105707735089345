import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { TaskType } from 'shared/constants/enums';
import { TaskTypeLabel } from 'shared/constants/labels';
import { Task } from 'shared/hooks/useTaskDetails';
import { Clock } from 'shared/icons/Clock';
import { getDocumentTypeDisplayName, getDueDateColor, getTaskStatus } from 'shared/services';
import { updateShipmentAndDocumentDetails } from 'shared/utils/updateShipmentAndDocumentDetails';
import { twMerge } from 'tailwind-merge';

interface Props {
  task: Task;
}

const LoadingSkeleton = () => {
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(false);
    }, 2000); // Hide the skeleton after 2 seconds

    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, []);

  return showSkeleton ? <Skeleton height="20px" direction="ltr" /> : null;
};

const TaskDetailsHeader: React.FC<Props> = ({ task }) => {
  const navigate = useNavigate();
  const [shipment, setShipment] = useState<any | null>(null);
  const [documents, setDocuments] = useState<any[]>([]);

  useEffect(() => {
    const unsubscribeList: Array<() => void> = [];

    updateShipmentAndDocumentDetails({
      task,
      orgID: task.org_id,
      shipmentCacheRef: { current: {} },
      setShipmentDetails: (updateFn) => {
        setShipment((prevShipmentDetails: any) => {
          const updatedDetails = typeof updateFn === 'function' ? updateFn(prevShipmentDetails) : updateFn;

          return updatedDetails[task.id] || null;
        });
      },
      setDocumentTypes: (updateFn) => {
        setDocuments((prevDocument: any) => {
          const updatedDocument = typeof updateFn === 'function' ? updateFn(prevDocument) : updateFn;

          return updatedDocument[task.id] || null;
        });
      },
      unsubscribeList,
    });

    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };
  }, [task, task.org_id]);

  return (
    <div className="border-b border-gray-200 bg-white py-4">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <div className="flex w-full justify-between gap-10">
          <div className="flex items-center gap-6">
            <button onClick={() => navigate(-1)}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
              >
                <path
                  d="M0.5 17C0.5 7.8873 7.8873 0.5 17 0.5C26.1127 0.5 33.5 7.8873 33.5 17C33.5 26.1127 26.1127 33.5 17 33.5C7.8873 33.5 0.5 26.1127 0.5 17Z"
                  fill="white"
                />
                <path
                  d="M0.5 17C0.5 7.8873 7.8873 0.5 17 0.5C26.1127 0.5 33.5 7.8873 33.5 17C33.5 26.1127 26.1127 33.5 17 33.5C7.8873 33.5 0.5 26.1127 0.5 17Z"
                  stroke="#E5E7EB"
                />
                <path
                  d="M15.2867 17.0002L20.2225 12.1704C20.5752 11.8248 20.5944 11.2446 20.2645 10.8743C19.9356 10.504 19.3799 10.4847 19.0282 10.8303L14.0399 15.7123C13.6916 16.0533 13.5 16.5098 13.5 17.0002C13.5 17.4906 13.6916 17.9471 14.039 18.2881L19.0273 23.1701C19.1962 23.3351 19.4106 23.4166 19.6249 23.4166C19.8594 23.4166 20.0922 23.3195 20.2637 23.1261C20.5935 22.7558 20.5743 22.1764 20.2217 21.8299L15.2867 17.0002Z"
                  fill="#1F2A37"
                />
              </svg>
            </button>

            <div>
              <h2 className="text-lg font-bold">{TaskTypeLabel[task.type as TaskType] || 'Unknown'}</h2>
              {shipment && shipment.client_shipment_id ? (
                <p className="text-sm text-gray-700">{shipment.client_shipment_id || 'Unassigned'}</p>
              ) : (
                <LoadingSkeleton />
              )}
            </div>
          </div>
          <div className="flex grow flex-wrap justify-start gap-6 text-sm text-gray-600">
            <div className="flex flex-col gap-1">
              <span className="text-xs">Status</span>
              <span className="rounded-full bg-gray-100 px-3 py-1 text-xs capitalize text-gray-900">
                {getTaskStatus(task.status)}
              </span>
            </div>
            {task.status === 'completed' ? (
              <div className="flex flex-col gap-1">
                <span className="text-xs">Completion date</span>
                {task.schedule?.completed_at && (
                  <span className={twMerge(['flex items-center gap-1'])}>
                    <Clock />
                    <span className="font-semibold">
                      {new Date(task.schedule?.completed_at?.seconds * 1000).toLocaleString()}
                    </span>
                  </span>
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                <span className="text-xs">Due Date</span>
                <span
                  className={twMerge([
                    'flex items-center gap-1',
                    getDueDateColor(new Date(task.schedule?.due_at.seconds * 1000)),
                    task.status === 'completed' && 'text-gray-400',
                  ])}
                >
                  <Clock />
                  <span className="font-semibold">
                    {new Date(task.schedule?.due_at.seconds * 1000).toLocaleString()}
                  </span>
                </span>
              </div>
            )}
            <div className="flex flex-col gap-1">
              <span className="text-xs">Mode</span>
              {shipment && shipment.data.load_type ? (
                <span className="font-semibold text-gray-900">{shipment && shipment.data.load_type}</span>
              ) : (
                <LoadingSkeleton />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Consignee</span>
              {shipment && shipment.data.consignee ? (
                <span className="font-semibold text-gray-900">{shipment && shipment.data.consignee.name}</span>
              ) : (
                <LoadingSkeleton />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Assigned user</span>
              <span className="font-semibold text-black">{task.schedule?.assigned_to || 'Unassigned'}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Dest. Port</span>
              {shipment && shipment.data.port_of_discharge?.unloc ? (
                <span className="font-semibold text-gray-900">
                  {shipment && shipment.data.port_of_discharge?.unloc}
                </span>
              ) : (
                <LoadingSkeleton />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Type</span>
              <div className="flex items-center gap-1">
                {documents.length > 0 &&
                  documents.map((doc) => (
                    <a
                      key={doc.id}
                      href={`/#/document/${doc.id}`}
                      target="_blank"
                      className="inline-flex items-center gap-1 rounded-lg border border-[#057A55] px-2 py-0 text-xs"
                      rel="noopener noreferrer"
                    >
                      <span className="uppercase text-[#057A55]">{getDocumentTypeDisplayName(doc.type)}</span>
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailsHeader;
