import moment from 'moment';

export const getStartOfDay = (date: string) => {
  if (!date) return date;
  // validate format date YYYY-MM-DD
  if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) return date;
  const localDate = new Date(`${date}T00:00:00`);
  return localDate.toISOString().replace(/\.\d{3}Z$/, 'Z');
};

export const getEndOfDay = (date: string) => {
  if (!date) return date;
  // validate format date YYYY-MM-DD
  if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) return date;
  const localDate = new Date(`${date}T23:59:59`);
  return localDate.toISOString().replace(/\.\d{3}Z$/, 'Z');
};

export const convertMillisecondToIOSString = (milliseconds?: number) => {
  if (!milliseconds || typeof milliseconds !== 'number') return '';
  const date = new Date(milliseconds);
  return date.toISOString();
};

export const convertMillisecondToLocaleString = (milliseconds?: number) => {
  if (!milliseconds || typeof milliseconds !== 'number') return '';
  const date = new Date(milliseconds);
  return date.toLocaleString();
};

export const formatDate = (date: number | Date, format = 'DD-MM-YY; HH:mm') => {
  return moment(date).format(format);
};
