import React from 'react';
import { IFilterItem } from '.';

import DateFilter from '../date-filter';

export default function FilterItemDateDropDown({ data }: { data: IFilterItem }) {
  return (
    <DateFilter
      onChange={(date) => {
        data.onChange(date);
      }}
      value={data.value}
      disabled={data.disabled}
    />
  );
}
