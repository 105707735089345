import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import axiosInstance from 'shared/services/axios';
import { auth } from '../firebase/config';
import { getOrgId, persistLogin } from '../reduxStore/slices/auth';

export const useAuthenticate = (afterPath: string = '/shipments') => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      const accessToken = await auth?.currentUser?.getIdToken();
      if (accessToken) {
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      }

      if (user && user?.providerData?.[0]) {
        const orgId = await getOrgId(user);
        dispatch(
          persistLogin({
            user: user.providerData?.[0],
            orgId,
          })
        );
        if (pathname.includes('login')) {
          navigate(afterPath);
        }
        setIsAuthenticated(true);

        return;
      } else {
        setIsAuthenticated(true);
        return navigate('/login');
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, afterPath]);

  return isAuthenticated;
};
