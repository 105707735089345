import flatten from 'lodash/flatten';
import React from 'react';

import { Button } from 'shared/components/button';
import DocumentChip from 'shared/components/DocumentChip';
import Drawer from 'shared/components/drawer';
import { EDocumentType } from 'shared/constants/enums';
import { TDocumentType } from 'shared/constants/types';
import { sortDocumentByTypeCB } from 'shared/utils/documents';
import DocumentDrawBodyItem from './DocumentDrawBodyItem';

type DocumentDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  shipment?: any;
  selectedDocumentTypes?: string[];
};

const renderDocumentChip = (shipmentId: string) => (doc: any) => (
  <DocumentChip
    key={doc.id}
    name={doc.type}
    hasDocument={doc.status !== 'missing'}
    color={doc.status === 'missing' ? 'missing' : 'success'}
    link={doc.id ? `/document/${doc.id}?client-shipment-id=${shipmentId}` : undefined}
  />
);

const DocumentDrawer = ({ open, setOpen, shipment, selectedDocumentTypes = [] }: DocumentDrawerProps) => {
  let shippingDocuments: any[] = [];
  const shipmentId = shipment?.client_shipment_id;

  if (shipment?.shipping_documents && Array.isArray(shipment.shipping_documents)) {
    shippingDocuments = shipment.shipping_documents
      .map((doc: any) => ({
        id: doc.id,
        type: doc.type,
        name: EDocumentType[doc.type as TDocumentType] || '',
        status: doc.processing_result_status_code,
      }))
      .concat(
        shipment?.unreceived_shipping_document_types?.map((doc: string) => ({
          type: doc,
          name: EDocumentType[doc as TDocumentType] || '',
          status: 'missing',
        })) || []
      );
  }

  const otherDocuments = shippingDocuments.reduce((acc: any, doc: any) => {
    if (!selectedDocumentTypes.includes(doc.type)) {
      acc.push(doc);
    }
    return acc;
  }, []);
  const preferredDocuments = flatten(
    selectedDocumentTypes.map((type: string) => {
      const docs = shippingDocuments.filter((doc: any) => doc.type === type);

      if (docs.length > 0) {
        return docs;
      }

      return {
        type,
        id: type,
        name: EDocumentType[type as TDocumentType] || '',
        status: 'missing',
      };
    })
  );
  otherDocuments.sort(sortDocumentByTypeCB);

  const totalDocuments = preferredDocuments.length + otherDocuments.length;

  const Body = (
    <div>
      <DocumentDrawBodyItem title="All Documents" subTitle={totalDocuments > 0 ? `Total: ${totalDocuments}` : '--'} />
      <DocumentDrawBodyItem
        title="Preferred Document"
        subTitle={preferredDocuments.length > 0 ? `Total: ${preferredDocuments.length}` : '--'}
      >
        <div className="mt-2 flex flex-wrap gap-x-3 gap-y-3">
          {preferredDocuments.map(renderDocumentChip(shipmentId))}
        </div>
      </DocumentDrawBodyItem>
      <DocumentDrawBodyItem
        title="Others Documents"
        subTitle={otherDocuments.length > 0 ? `Total: ${otherDocuments.length}` : '--'}
      >
        <div className="mt-2 flex flex-wrap gap-x-3 gap-y-3">{otherDocuments.map(renderDocumentChip(shipmentId))}</div>
      </DocumentDrawBodyItem>
    </div>
  );

  const Footer = (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-row justify-start gap-4">
        <Button onClick={() => setOpen(false)}>Close</Button>
      </div>
    </div>
  );

  return (
    <Drawer
      className="z-50"
      bodyClassName="px-0 sm:px-0 w-full"
      bodyWrapperClassName="py-0"
      open={open}
      setOpen={setOpen}
      title="All Documents"
      subTitle={shipment?.client_shipment_id}
      body={Body}
      footer={Footer}
    />
  );
};

export default DocumentDrawer;
