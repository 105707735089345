import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { useRef, useState } from 'react';

import { DateRange } from 'react-day-picker';
import { Calendar } from 'shared/components/Calendar';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from 'shared/components/dropdown';
import CalendarIcon from 'shared/icons/Calendar';
import { cn } from 'shared/utils';

export type DateFilterType =
  | 'today'
  | 'yesterday'
  | 'this_week'
  | 'this_month'
  | 'last_30_days'
  | 'last_60_days'
  | 'custom';
export type DateFilterValue = {
  type: DateFilterType;
  label: string;
  range: DateRange;
};

const options: {
  label: string;
  value: DateFilterType;
}[] = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'This Week', value: 'this_week' },
  { label: 'This Month', value: 'this_month' },
  { label: 'Last 30 days', value: 'last_30_days' },
  { label: 'Last 60 days', value: 'last_60_days' },
  { label: 'Custom', value: 'custom' },
];

const formatDate = (date: Date) => moment(date).format('MMM DD, YYYY');

type DateFilterProps = {
  value?: DateFilterValue;
  onChange?: (value?: DateFilterValue) => void;
  disabled?: boolean;
};

export const getRangeByType = (type: DateFilterType): DateRange => {
  const range = [];
  switch (type) {
    case 'today':
      range.push(moment().startOf('day').toDate());
      range.push(moment().endOf('day').toDate());
      break;
    case 'yesterday':
      range.push(moment().subtract(1, 'day').startOf('day').toDate());
      range.push(moment().subtract(1, 'day').endOf('day').toDate());
      break;
    case 'this_week':
      range.push(moment().startOf('week').toDate());
      range.push(moment().endOf('week').toDate());
      break;
    case 'this_month':
      range.push(moment().startOf('month').toDate());
      range.push(moment().endOf('month').toDate());
      break;
    case 'last_30_days':
      range.push(moment().subtract(30, 'days').startOf('day').toDate());
      range.push(moment().endOf('day').toDate());
      break;
    case 'last_60_days':
      range.push(moment().subtract(60, 'days').startOf('day').toDate());
      range.push(moment().endOf('day').toDate());
      break;
    default:
      break;
  }

  return {
    from: range[0],
    to: range[1],
  };
};

export const getDateFilterByDateRange = (range: DateRange): DateFilterValue => {
  if (!range.from || !range.to) {
    return {
      type: 'custom',
      label: '',
      range: range,
    };
  }

  const diff = moment(range.to).diff(moment(range.from), 'days');

  if (moment(range.from).isSame(moment(), 'day') && moment(range.to).isSame(moment(), 'day')) {
    return {
      type: 'today',
      label: 'Today',
      range: range,
    };
  }

  if (
    moment(range.from).isSame(moment().subtract(1, 'day'), 'day') &&
    moment(range.to).isSame(moment().subtract(1, 'day'), 'day')
  ) {
    return {
      type: 'yesterday',
      label: 'Yesterday',
      range: range,
    };
  }

  if (
    moment(range.from).isSame(moment().startOf('week'), 'day') &&
    moment(range.to).isSame(moment().endOf('week'), 'day')
  ) {
    return {
      type: 'this_week',
      label: 'This Week',
      range: range,
    };
  }

  if (
    moment(range.from).isSame(moment().startOf('month'), 'day') &&
    moment(range.to).isSame(moment().endOf('month'), 'day')
  ) {
    return {
      type: 'this_month',
      label: 'This Month',
      range: range,
    };
  }

  if (diff === 30 && moment(range.from).isSame(moment().subtract(30, 'days'), 'day')) {
    return {
      type: 'last_30_days',
      label: 'Last 30 days',
      range: range,
    };
  }

  if (diff === 60 && moment(range.from).isSame(moment().subtract(60, 'days'), 'day')) {
    return {
      type: 'last_60_days',
      label: 'Last 60 days',
      range: range,
    };
  }

  return {
    type: 'custom',
    label: `${formatDate(range.from)} - ${formatDate(range.to)}`,
    range: range,
  };
};

export default function DateFilter({ value, onChange, disabled }: DateFilterProps) {
  const calendarButtonRef = useRef<HTMLButtonElement | null>(null);
  const [date, setDate] = useState<DateRange | undefined>(undefined);

  const handleMenuItemClick = (value: DateFilterType) => {
    if (value === 'custom') {
      setTimeout(() => {
        calendarButtonRef.current?.click();
      }, 200);

      return;
    }

    const range = getRangeByType(value);

    setDate(range);

    onChange?.({
      type: value,
      label: options.find((option) => option.value === value)?.label || '',
      range: range,
    });
  };

  const handleClearField = (e: any) => {
    e.stopPropagation();
    setDate(undefined);
    onChange?.(undefined);
  };

  const label = value
    ? value.type === 'custom'
      ? `${moment(value.range.from).format('MMM DD, YYYY')} - ${moment(value.range.to).format('MMM DD, YYYY')}`
      : options.find((option) => option.value === value.type)?.label
    : 'Select date range';

  return (
    <div>
      <Dropdown>
        <DropdownButton
          disabled={disabled}
          outline
          className="flex w-full justify-between gap-1 rounded-lg border border-gray-300 bg-gray-50 !py-2 pl-10 text-left text-sm font-normal text-gray-500 focus:border-cyan-500 focus:ring-cyan-500 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500"
        >
          <div
            className={cn('flex items-center gap-2', {
              'text-gray-900': label !== 'Select date range',
            })}
          >
            <CalendarIcon />
            {label}
          </div>
          <XMarkIcon onClick={handleClearField} />
        </DropdownButton>
        <DropdownMenu className="!w-[var(--button-width)]">
          {options.map((option) => (
            <DropdownItem
              onClick={() => {
                handleMenuItemClick(option.value);
              }}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>

      {/* <Datepicker inline multiple /> */}
      <Popover className="h-0">
        <PopoverButton className="h-0 w-full overflow-hidden" ref={calendarButtonRef}>
          Open Calendar
        </PopoverButton>
        <PopoverPanel
          transition
          anchor="bottom"
          className="min-w-48 !overflow-visible rounded-xl border-gray-200 bg-white text-sm shadow-md transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
        >
          <Calendar
            mode="range"
            selected={date}
            onSelect={(range, selectedDay) => {
              setDate(range);
              if (!range) {
                return;
              }

              const label = `${range.from ? formatDate(range.from) : ''} - ${range.to ? formatDate(range.to) : ''}`;

              onChange?.({
                type: 'custom',
                label: label,
                range: range,
              });
            }}
          />
        </PopoverPanel>
      </Popover>
    </div>
  );
}
