import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import React, { useMemo, useState } from 'react';

import { Dialog, DialogBody, DialogTitle } from 'shared/components/dialog';
import { Divider } from 'shared/components/divider';
import { TOceanContainer } from 'shared/constants/types';
import CloseIcon from 'shared/icons/CloseIcon';
import ContainerIcon from 'shared/icons/ContainerIcon';
import { cn } from 'shared/utils';
import TimeBlock from './Nodes/Blocks/TimeBlock';

interface Shipment {
  ocean_containers?: TOceanContainer[];
  total_packages: {
    value?: number;
    unit?: string;
  };
  total_weight: {
    value?: number;
    unit?: string;
  };
  total_volume: {
    value?: number;
    unit?: string;
  };
}

type TabContent = {
  key: string;
  label: string;
  value: string | number;
  className?: string;
}[];

type ContainerListDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  shipmentData: Shipment;
  containers: any[];
  onOpenEditDayDialog: () => void;
};

const ContainerListDialog = ({
  isOpen,
  setIsOpen,
  containers,
  onOpenEditDayDialog,
  shipmentData,
}: ContainerListDialogProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabs = useMemo(
    () =>
      containers.reduce<{ tabId: string; content: TabContent; extraData: any }[]>((results, container) => {
        const tabContent = [
          { key: 'no', value: container.container_number || '--', label: 'No' },
          {
            key: 'volume',
            label: 'Volume',
            value: `${container.volume ?? ''} ${shipmentData?.total_volume?.unit ?? ''}`,
          },
          {
            key: 'packages',
            label: 'Packages',
            value: container.package_count ? `${container.package_count} ${container.package_uom || '--'}` : '--',
          },
          {
            key: 'type',
            label: 'Type',
            value: container.container_type ?? '--',
          },
          {
            key: 'weight',
            label: 'Weight',
            value: `${container.weight ?? ''} ${shipmentData?.total_weight?.unit ?? ''}`,
          },
          {
            key: 'pallets',
            label: 'Pallets',
            value: container.pallet_count || '--',
          },

          {
            key: 'item',
            label: 'Item',
            value: (container?.items?.map((item: any) => item.item_description).filter((str: any) => !!str) ?? []).join(
              ', '
            ),
            className: 'col-span-2',
          },
        ];

        return results.concat({
          content: tabContent,
          tabId: container.container_number || '--',
          extraData: {
            estimatedTime: container.estimatedTime,
            actualTime: container.actualTime,
          },
        });
      }, []) || [],
    [containers, shipmentData]
  );

  // todo: update condition to disable/enable edit time ability
  let editable = true;
  const handleOpenEditDayDialog = () => {
    if (!editable) return;

    onOpenEditDayDialog();
  };

  return (
    <Dialog open={isOpen} onClose={setIsOpen} className="z-50 !p-0">
      <DialogTitle className="border-b px-5 py-3">
        <div className="flex items-center justify-between">
          <span className="text-[14px] leading-[1.5] text-gray-900">All Containers </span>
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>
      </DialogTitle>
      <DialogBody className="!mt-0 text-sm/6 text-zinc-900 dark:text-white">
        <TabGroup
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
          vertical
          className="grid grid-cols-[180px_350px]"
        >
          <TabList className="flex flex-col border-r border-r-gray-100">
            {tabs.map(({ tabId }, index) => (
              <Tab
                key={tabId}
                className={cn(
                  'flex items-center gap-1.5 py-4 pl-5',
                  selectedIndex === index && 'bg-[#2463EB1A] ring-transparent focus:ring-transparent'
                )}
              >
                <ContainerIcon variant="active" />
                <span className="text-[14px] font-semibold leading-[1.5]">{tabId}</span>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map(({ content, extraData }, index) => (
              <TabPanel key={index} className="p-6 text-[12px]">
                <div className={'mb-4 grid grid-cols-2'}>
                  {content.map((content) => {
                    return (
                      <div key={content.key} className={cn(content.className)}>
                        <span>{content.label}: </span>
                        <span className="font-bold">{content.value}</span>
                      </div>
                    );
                  })}
                </div>
                <Divider className="mb-4" />
                <TimeBlock
                  variant="pickup"
                  vehicle="truck"
                  estimatedTime={extraData.estimatedTime}
                  actualTime={extraData.actualTime}
                  onEdit={handleOpenEditDayDialog}
                  editable
                />
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </DialogBody>
    </Dialog>
  );
};

export default ContainerListDialog;
