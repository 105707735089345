import { Dropdown } from 'flowbite-react';
import React from 'react';

import { EDocumentType } from 'shared/constants/enums';
import { TDocumentType } from 'shared/constants/types';
import TaskDiscrepancyIcon from './TaskDiscrepancyIcon';

type OptionType = { value: string; label: string; custom?: boolean };

function formatString(value: string) {
  return value
    .split('_') // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join the words with a space
}

const DropdownComponent = ({ documents }: { documents: any[] }) => (
  <div className="absolute right-4 top-4">
    <Dropdown
      color="gray"
      label={<span className="flex items-center gap-1 text-xs">Ref Doc ({documents.length})</span>}
    >
      {documents.map((doc) => (
        <Dropdown.Item key={doc.id} onClick={() => window.open(`#/document/${doc.id}`, '_blank')}>
          <span className="flex items-center gap-1">
            <TaskDiscrepancyIcon type="document" />
            <span className="whitespace-nowrap uppercase">{EDocumentType[doc.type as TDocumentType] || doc.type}</span>
          </span>
        </Dropdown.Item>
      ))}
    </Dropdown>
  </div>
);

const ValueOptions = ({
  options,
  currentValue,
  onValueChange,
  onModalOpen,
  record,
  selectedValues,
  task,
}: {
  options: { value: string; label: string; custom?: boolean }[];
  currentValue: string;
  onValueChange: (value: string) => void;
  onModalOpen: () => void;
  record: any;
  selectedValues: any;
  task: any;
}) => (
  <div className="values my-4 flex flex-col items-center justify-center gap-4">
    {options.map((option) => {
      // Separate the label (text before `: `) and value (text after `: `)
      const [label, value] = option.label.split(':').map((str) => str.trim());
      // Separate the array label
      const labels = label.split(', ');

      const shortenedLabel = labels.map((label) => EDocumentType[label as TDocumentType] || label).join(', ');

      return (
        <label
          key={option.value}
          className={`rounded-lg border py-1 text-sm ${
            (record.field_name in selectedValues && currentValue === option.value) ||
            (record.status === 'overridden' && currentValue === option.value)
              ? 'bg-black text-white'
              : 'bg-gray-50'
          } ${task.status === 'completed' ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-black hover:text-white'}`}
        >
          <input
            type="radio"
            name="value"
            value={option.value}
            className="peer sr-only"
            checked={record.status === 'overridden' && currentValue === option.value}
            disabled={task.status === 'completed'}
            onChange={() => {
              onValueChange(option.value); // Update the selected value
              if (option.custom) {
                onModalOpen(); // Open the modal if the option is custom
              }
            }}
          />
          <span
            className="flex items-center gap-2 rounded-lg px-2 py-1"
            onClick={() => {
              if (option.custom) {
                onModalOpen(); // Ensure the modal opens when the custom button is clicked
              }
            }}
          >
            {option.custom ? (
              <>
                <span>{shortenedLabel}</span>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-black"
                >
                  <g clipPath="url(#clip0_184_14968)">
                    <path
                      d="M9.38251 3.09034C9.8793 2.5521 10.1277 2.28297 10.3916 2.12599C11.0285 1.74721 11.8128 1.73543 12.4603 2.09492C12.7286 2.24391 12.9847 2.50545 13.4967 3.02855C14.0088 3.55164 14.2648 3.81319 14.4107 4.08732C14.7626 4.74879 14.7511 5.5499 14.3803 6.20051C14.2266 6.47015 13.9631 6.72389 13.4362 7.23138L7.16713 13.2696C6.16862 14.2313 5.66937 14.7122 5.04541 14.9559C4.42145 15.1996 3.73551 15.1816 2.36362 15.1458L2.17696 15.1409C1.75931 15.13 1.55049 15.1245 1.4291 14.9867C1.30771 14.849 1.32428 14.6363 1.35743 14.2108L1.37542 13.9798C1.46871 12.7824 1.51536 12.1837 1.74918 11.6455C1.983 11.1073 2.38633 10.6704 3.19299 9.79637L9.38251 3.09034Z"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.66669 3.16669L13.3334 7.83335"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.33337 15.1667L14.6667 15.1667"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_184_14968">
                      <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
              </>
            ) : (
              <>
                <span className="uppercase">{shortenedLabel}:</span>
                {value}
              </>
            )}
          </span>
        </label>
      );
    })}
  </div>
);

const DiscrepancySection = ({
  label,
  currentValue,
  onValueChange,
  onModalOpen,
  documents,
  fieldValues,
  customValue,
  record,
  selectedValues,
  task,
}: {
  label: string;
  options: { value: string; label: string; custom?: boolean }[];
  currentValue: string;
  onValueChange: (value: string) => void;
  onModalOpen: () => void;
  documents: any[];
  fieldValues: any[];
  customValue: string | null;
  record: any;
  selectedValues: any;
  task: any;
}) => {
  const documentMap = Object.fromEntries(documents.map((doc) => [doc.id, doc.type || 'Unknown']));
  const options: OptionType[] = fieldValues.map((fieldValue) => {
    const docType = fieldValue.shipping_document_ids
      .map((docId: string) => documentMap[docId])
      .filter(Boolean)
      .join(', ');
    return {
      value: fieldValue.value,
      label: `${docType || 'Unknown Source'}: ${fieldValue.value}`,
    };
  });

  const isOverridden = fieldValues.some((fieldValue: any) => record.status === 'overridden');

  // Add the custom value as a third option if it exists
  if (customValue || (isOverridden && currentValue)) {
    const existsInOptions = options.some((option) => option.value.toString() === currentValue.toString());

    if (!existsInOptions) {
      options.push({
        value: currentValue,
        label: `${currentValue}`,
        custom: true,
      });
    }
  }

  return (
    <div className="relative flex-1 justify-center rounded-lg border p-4 text-center">
      <DropdownComponent documents={documents} />
      <span
        className={`mb-6 me-2 mt-4 inline-flex items-center gap-1 rounded px-2.5 py-0.5 text-xs font-medium ${
          isOverridden ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}
      >
        <TaskDiscrepancyIcon type="warning" green={isOverridden} />
        {formatString(label)}
      </span>
      <p className="text-base font-bold">Select corrected value</p>
      <ValueOptions
        options={options}
        currentValue={currentValue.toString()}
        onValueChange={onValueChange}
        onModalOpen={onModalOpen} // Pass the onModalOpen prop
        record={record}
        selectedValues={selectedValues}
        task={task}
      />
      <small className="text-gray-500">Current Value: {currentValue || ''}</small>
      {task.status !== 'completed' && (
        <div className="text-right text-xs text-gray-700 underline">
          <span className="cursor-pointer underline" onClick={onModalOpen}>
            None of the above?
          </span>
        </div>
      )}
    </div>
  );
};

export default DiscrepancySection;
