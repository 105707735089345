import React from 'react'

export function InfoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_54823_12166"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_54823_12166)">
        <path
          d="M7.33301 11.333H8.66634V7.33301H7.33301V11.333ZM7.99967 5.99967C8.18856 5.99967 8.3469 5.93579 8.47467 5.80801C8.60245 5.68023 8.66634 5.5219 8.66634 5.33301C8.66634 5.14412 8.60245 4.98579 8.47467 4.85801C8.3469 4.73023 8.18856 4.66634 7.99967 4.66634C7.81079 4.66634 7.65245 4.73023 7.52467 4.85801C7.3969 4.98579 7.33301 5.14412 7.33301 5.33301C7.33301 5.5219 7.3969 5.68023 7.52467 5.80801C7.65245 5.93579 7.81079 5.99967 7.99967 5.99967ZM7.99967 14.6663C7.07745 14.6663 6.21079 14.4913 5.39967 14.1413C4.58856 13.7913 3.88301 13.3163 3.28301 12.7163C2.68301 12.1163 2.20801 11.4108 1.85801 10.5997C1.50801 9.78856 1.33301 8.9219 1.33301 7.99967C1.33301 7.07745 1.50801 6.21079 1.85801 5.39967C2.20801 4.58856 2.68301 3.88301 3.28301 3.28301C3.88301 2.68301 4.58856 2.20801 5.39967 1.85801C6.21079 1.50801 7.07745 1.33301 7.99967 1.33301C8.9219 1.33301 9.78856 1.50801 10.5997 1.85801C11.4108 2.20801 12.1163 2.68301 12.7163 3.28301C13.3163 3.88301 13.7913 4.58856 14.1413 5.39967C14.4913 6.21079 14.6663 7.07745 14.6663 7.99967C14.6663 8.9219 14.4913 9.78856 14.1413 10.5997C13.7913 11.4108 13.3163 12.1163 12.7163 12.7163C12.1163 13.3163 11.4108 13.7913 10.5997 14.1413C9.78856 14.4913 8.9219 14.6663 7.99967 14.6663ZM7.99967 13.333C9.48856 13.333 10.7497 12.8163 11.783 11.783C12.8163 10.7497 13.333 9.48856 13.333 7.99967C13.333 6.51079 12.8163 5.24967 11.783 4.21634C10.7497 3.18301 9.48856 2.66634 7.99967 2.66634C6.51079 2.66634 5.24967 3.18301 4.21634 4.21634C3.18301 5.24967 2.66634 6.51079 2.66634 7.99967C2.66634 9.48856 3.18301 10.7497 4.21634 11.783C5.24967 12.8163 6.51079 13.333 7.99967 13.333Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
