import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchShipmentsByIdRequest } from '../firebase/model';
import { listenShipmentDetails } from '../firebase/query/shipments';
import { updateShipmentDetailLoader, updateShipmentDetails } from '../reduxStore/slices/shipmentDetails';
import { AppDispatch } from '../reduxStore/store';

const useShipmentDetails = (request: FetchShipmentsByIdRequest) => {
  const orgId = useSelector((state: any) => state.auth.orgId);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(updateShipmentDetailLoader());
    const shipmentDetailsListener = listenShipmentDetails(
      {
        ...request,
        orgId,
      },
      (payload: any) => dispatch(updateShipmentDetails(payload))
    );
    return () => shipmentDetailsListener();
  }, [dispatch, orgId, request]);
};

export default useShipmentDetails;
