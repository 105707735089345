import { WhereFilterOpWithAdditionalField } from 'shared/firebase/query/shipments';
import { DateFilterValue } from "shared/components/date-filter";

export type ShipmentQueryRequest = {
  page_size: number;
  page: number;
  mode: string[];
  client_shipment_id: string[];
  validation_status: string[];
  place_of_receipt?: string;
  created_at?: DateFilterValue;
  master_bill_of_lading_number?: string;
  house_bill_of_lading_number?: string;
  automated_manifest_system_number?: string;
  pol?: string;
  estimated_time_of_departure?: DateFilterValue;
  actual_time_of_departure?: DateFilterValue;
  pod?: string;
  estimated_time_of_arrival?: DateFilterValue;
  actual_time_of_arrival?: DateFilterValue;
  place_of_delivery?: string;
  vessel_name?: string;
  vessel_voyage_number?: string;
  shipper_name?: string;
  consignee_name?: string;
  notify_party?: string;
  destination_agent?: string;
  container_number?: string;
  // status?: string
  order_by?: string;
  order_direction?: string;
  orgId: string;
};

type ShipmentFilterKey = keyof Omit<
  ShipmentQueryRequest,
  'page' | 'page_size' | 'orgId' | 'order_by' | 'order_direction'
>;

type FilterConditions = Record<
  ShipmentFilterKey,
  {
    operation: 'in' | 'like';
    field: string;
  }
>;

export default function convertShipmentQueryRequestToFilter(filter: ShipmentQueryRequest) {
  const filterConditions: FilterConditions = {
    client_shipment_id: {
      operation: 'in',
      field: 'client_shipment_id',
    },
    mode: {
      operation: 'in',
      field: 'data.load_type',
    },
    validation_status: {
      operation: 'in',
      field: 'validation_result.status_code',
    },
    created_at: {
      operation: 'in',
      field: 'created_at',
    },
    master_bill_of_lading_number: {
      operation: 'like',
      field: 'data.master_bill_of_lading_number',
    },
    house_bill_of_lading_number: {
      operation: 'like',
      field: 'data.house_bill_of_lading_number',
    },
    automated_manifest_system_number: {
      operation: 'like',
      field: 'data.automated_manifest_system_number',
    },
    pol: {
      operation: 'like',
      field: 'data.port_of_loading.unloc',
    },
    pod: {
      operation: 'like',
      field: 'data.port_of_discharge.unloc',
    },
    estimated_time_of_departure: {
      operation: 'in',
      field: 'data.estimated_time_of_departure',
    },
    actual_time_of_departure: {
      operation: 'in',
      field: 'data.actual_time_of_departure',
    },
    estimated_time_of_arrival: {
      operation: 'in',
      field: 'data.estimated_time_of_arrival',
    },
    actual_time_of_arrival: {
      operation: 'in',
      field: 'data.actual_time_of_arrival',
    },
    place_of_receipt: {
      operation: 'like',
      field: 'data.place_of_receipt',
    },
    place_of_delivery: {
      operation: 'like',
      field: 'data.place_of_delivery',
    },
    vessel_name: {
      operation: 'like',
      field: 'data.vessel_name',
    },
    vessel_voyage_number: {
      operation: 'like',
      field: 'data.vessel_voyage_number',
    },
    shipper_name: {
      operation: 'like',
      field: 'data.shipper.name',
    },
    consignee_name: {
      operation: 'like',
      field: 'data.consignee.name',
    },
    notify_party: {
      operation: 'like',
      field: 'data.notify_party.name',
    },
    destination_agent: {
      operation: 'like',
      field: 'data.destination_agent.name',
    },
    container_number: {
      operation: 'like',
      field: 'data.containers.number',
    },
    // status: {
    //   operation: 'in',
    //   field: 'status',
    // },
  };

  const filters: Array<{
    key: string;
    value: string | string[] | Date;
    operation: WhereFilterOpWithAdditionalField;
  }> = [];

  const filterKeys = Object.keys(filter) as ShipmentFilterKey[];

  for (let key of filterKeys) {
    if (!filter[key] || !filterConditions[key]) {
      continue;
    }

    const condition = filterConditions[key];
    const value = filter[key];

    if (condition.operation === 'in' && Array.isArray(value) && value.length > 0) {
      filters.push({
        key: condition.field,
        value: value,
        operation: 'in',
      });
    }

    if (condition.operation === 'in' && typeof value === 'object' && !Array.isArray(value)) {
      if (value.range.from && value.range.to) {
        filters.push({
          key: condition.field,
          value: value.range.from,
          operation: '>=',
        });
        filters.push({
          key: condition.field,
          value: value.range.to,
          operation: '<=',
        });
      }
    }

    if (condition.operation === 'like' && !!value) {
      filters.push({
        key: condition.field,
        value: value as string,
        operation: 'include' as WhereFilterOpWithAdditionalField,
      });
    }
  }

  return filters;
}
