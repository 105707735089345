import { Spinner } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DigitizedView from 'models/DocumentDetails/components/DigitizedView';
import DocumentDetailsHeader from 'models/DocumentDetails/components/DocumentDetailsHeader';
import DocumentView from 'models/DocumentDetails/components/DocumentView';
import { getGSReference } from 'shared/firebase/utils';
import useDocumentDetails from 'shared/hooks/useDocumentDetails';
import { getDocumentData, updateDocSrc } from 'shared/reduxStore/slices/documentDetails';
import { AppDispatch } from 'shared/reduxStore/store';
import { cn } from 'shared/utils';

const DocumentDetailsPage = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [maxHeight, setMaxHeight] = useState(0);
  const { isLoading, data } = useSelector((state: any) => state.documentDetails);

  const { docId } = useParams();

  useDocumentDetails(docId || '');

  useEffect(() => {
    if (data) {
      getGSReference(data.structured_payload_gs_link, (url: string) => dispatch(getDocumentData(url)));
      const doc = data.original_payload_gs_link.includes('xlsx')
        ? data.transformed_payload_gs_link
        : data.original_payload_gs_link;
      getGSReference(doc, (url: string) => dispatch(updateDocSrc(url)));
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (containerRef.current) {
      setMaxHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef]);

  return (
    <div className={cn('flex h-screen flex-col overflow-hidden', isLoading && 'justify-center')}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <DocumentDetailsHeader />
          <div className="container mx-auto flex max-h-[calc(100vh-85px)] flex-col items-center justify-center overflow-hidden p-6">
            <div className="grid h-[calc(100vh-85px-24px-24px)] w-full grid-cols-2 gap-4" ref={containerRef}>
              <DocumentView maxHeight={maxHeight} />
              <DigitizedView maxHeight={maxHeight} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentDetailsPage;
