const shipper = {
  name: 'Shipper',
  json_keys: [
    'shipper_name',
    'shipper_address_line_1',
    'shipper_address_line_2',
    'shipper_city',
    'shipper_state',
    'shipper_zip_code',
    'shipper_country',
  ],
};

const exporter = {
  name: 'Exporter',
  json_keys: [
    'exporter_name',
    'exporter_address_line_1',
    'exporter_address_line_2',
    'exporter_city',
    'exporter_state',
    'exporter_zip_code',
    'exporter_country',
  ],
};

const importer = {
  name: 'Importer',
  json_keys: [
    'importer_name',
    'importer_address_line_1',
    'importer_address_line_2',
    'importer_city',
    'importer_state',
    'importer_zip_code',
    'importer_country',
  ],
};

const consignee = {
  name: 'Consignee',
  json_keys: [
    'consignee_name',
    'consignee_address_line_1',
    'consignee_address_line_2',
    'consignee_city',
    'consignee_state',
    'consignee_zip_code',
    'consignee_country',
  ],
};

const notifyParty = {
  name: 'Notify party',
  json_keys: [
    'notify_party_name',
    'notify_party_address_line_1',
    'notify_party_address_line_2',
    'notify_party_city',
    'notify_party_state',
    'notify_party_zip_code',
    'notify_party_country',
    'notify_party_emails',
  ],
};

const designation = {
  name: 'Destination agent',
  json_keys: [
    'destination_agent_name',
    'destination_agent_address_line_1',
    'destination_agent_address_line_2',
    'destination_agent_city',
    'destination_agent_state',
    'destination_agent_zip_code',
    'destination_agent_country',
  ],
};

const containers = {
  name: 'Containers',
  array_contains: true,
  key: 'containers',
  text_area_fields: ['item_description'],
};

const shipmentDetails = {
  name: 'Shipment details',
  json_keys: [
    'freight_mode',
    'freight_terms',
    'freight_service',
    'incoterm',
    'house_bill_of_lading_release_type',
    'number_of_origin_bill_of_lading',
    'master_bill_of_lading_number',
    'house_bill_of_lading_number',
    'automated_manifest_system_number',
    'vessel',
    'voyage',
    'port_of_loading',
    'port_of_discharge',
    'place_of_receipt',
    'place_of_delivery',
  ],
};

export const FieldListBasedOnDocumentType = {
  house_bill_of_lading: [
    { ...shipper },
    { ...consignee },
    { ...notifyParty },
    { ...designation },
    { ...shipmentDetails },
    { ...containers },
  ],
  pre_alert: [
    {
      name: 'MBL Info',
      json_keys: [
        'master_bill_of_lading_consignee_name',
        'master_bill_of_lading_number',
        'master_bill_of_lading_payment_term',
        'master_bill_of_lading_shipper_name',
        'master_bill_of_lading_status',
      ],
    },
    {
      name: 'HBL Info',
      json_keys: [
        'house_bill_of_lading_consignee_name',
        'house_bill_of_lading_number',
        'house_bill_of_lading_payment_term',
        'house_bill_of_lading_shipper_name',
        'house_bill_of_lading_status',
      ],
    },
    {
      name: 'Shipment details',
      json_keys: [
        'total_packages',
        'package_uom',
        'total_volume',
        'volume_uom',
        'automated_manifest_system_number',
        'carrier',
        'vessel',
        'voyage',
        'place_of_receipt',
        'estimated_time_of_departure_place_of_receipt',
        'port_of_loading',
        'estimated_time_of_departure_port_of_loading',
        'port_of_discharge',
        'estimated_time_of_arrival_port_of_discharge',
        'place_of_delivery',
        'estimated_time_of_arrival_place_of_delivery',
      ],
    },
  ],
  automated_manifest_system: [
    { ...shipper },
    { ...consignee },
    {
      name: 'Shipment details',
      json_keys: [
        'booking_reference',
        'ocean_carrier',
        'master_bill_of_lading_number',
        'vessel',
        'voyage',
        'international_maritime_organization_number',
        'vessel_flag',
        'place_of_receipt',
        'port_of_loading',
        'last_foreign_port',
        'first_usa_port',
        'port_of_discharge',
        'freight_mode',
        'estimated_time_of_departure',
        'estimated_time_of_arrival',
        'automated_manifest_system_number',
        'total_containers',
        'total_weight',
        'total_volume',
        'total_packages',
        'total_pallets',
        'weight_uom',
        'volume_uom',
        'shipment_reference_id',
      ],
    },
    { ...containers },
  ],
  master_bill_of_lading: [
    { ...shipper },
    { ...consignee },
    { ...notifyParty },
    {
      name: 'Shipment details',
      json_keys: [
        'freight_mode',
        'freight_terms',
        'freight_service',
        'master_bill_of_lading_number',
        'vessel',
        'voyage',
        'port_of_loading',
        'port_of_discharge',
        'place_of_receipt',
        'place_of_delivery',
        'shipped_on_board_date',
        'estimated_time_of_departure',
        'estimated_time_of_arrival',
        'place_of_issue',
        'date_of_issue',
        'total_volume',
        'total_containers',
        'total_packages',
        'total_weight',
        'weight_uom',
        'volume_uom',
        'shipment_reference_id',
      ],
    },
    { ...containers },
  ],
  carrier_arrival_notice: [
    { ...shipper },
    { ...consignee },
    { ...notifyParty },
    {
      name: 'Shipment details',
      json_keys: [
        'freight_mode',
        'freight_terms',
        'freight_service',
        'master_bill_of_lading_number',
        'house_bill_of_lading_number',
        'vessel',
        'voyage',
        'port_of_loading',
        'port_of_discharge',
        'place_of_receipt',
        'place_of_delivery',
        'estimated_time_of_departure',
        'estimated_time_of_arrival',
        'last_free_date',
        'total_containers',
        'total_packages',
        'total_weight',
        'total_volume',
        'weight_uom',
        'volume_uom',
        'total_charges',
        'currency',
        'shipment_reference_id',
      ],
    },
    { ...containers },
  ],
  commercial_invoice: [
    { ...exporter },
    { ...importer },
    {
      name: 'Invoice details',
      json_keys: ['invoice_number', 'invoice_date', 'total_units', 'total_cost', 'currency', 'shipment_reference_id'],
    },
    {
      name: 'Products',
      array_contains: true,
      key: 'products',
      text_area_fields: ['product_description'],
    },
  ],
  packing_list: [
    { ...exporter },
    { ...importer },
    {
      name: 'Packing details',
      json_keys: [
        'total_gross_weight',
        'total_net_weight',
        'total_containers',
        'total_weight',
        'total_volume',
        'total_packages',
        'total_pallets',
        'weight_uom',
        'volume_uom',
        'shipment_reference_id',
        'total_units',
      ],
    },
    {
      name: 'Products',
      array_contains: true,
      key: 'products',
      text_area_fields: ['product_description'],
    },
  ],
  cbp_form_7512: [
    {
      name: 'Inbond details',
      json_keys: [
        'entry_number',
        'class_of_entry',
        'inbond_date',
        'master_bill_of_lading_number',
        'house_bill_of_lading_number',
        'automated_manifest_system_number',
        'bonded_carrier',
        'consignee',
        'importer',
      ],
    },
  ],

  ap_invoice: [
    {
      name: 'Issue from',
      json_keys: [
        'issue_from_name',
        'issue_from_address_line_1',
        'issue_from_address_line_2',
        'issue_from_city',
        'issue_from_state',
        'issue_from_zip_code',
        'issue_from_country',
      ],
      text_area_fields: ['issue_from_name', 'issue_from_address_line_1', 'issue_from_address_line_2'],
    },
    {
      name: 'Issue to',
      json_keys: [
        'issue_to_name',
        'issue_to_address_line_1',
        'issue_to_address_line_2',
        'issue_to_city',
        'issue_to_state',
        'issue_to_zip_code',
        'issue_to_country',
      ],
      text_area_fields: ['issue_to_name', 'issue_to_address_line_1', 'issue_to_address_line_2'],
    },
    {
      name: 'Invoice details',
      json_keys: ['invoice_reference_id', 'invoice_number', 'invoice_date', 'currency', 'total_amount'],
    },
    {
      name: 'Shipment details',
      json_keys: ['shipment_reference_id', 'master_bill_of_lading_number', 'house_bill_of_lading_number'],
    },
    {
      name: 'Charge',
      array_contains: true,
      key: 'charges',
    },
  ],

  profit_loss_sharing_report: [
    {
      name: 'General',
      json_keys: [
        'issue_to',
        'issue_from',
        'print_date',
        'doc_number',
        'job_number',
        'balence_due_to_responsible_party',
        'balence_due_amount',
        'total_profit_sharing_item_sub_total',
        'total_non_profit_sharing_item_sub_total',
      ],
    },
    {
      name: 'Shipment details',
      json_keys: [
        'shipment_reference_id',
        'master_bill_of_lading_number',
        'vessel_or_flight_number',
        'voyage',
        'place_of_delivery',
        'port_of_loading',
        'estimated_time_of_departure',
        'actual_time_of_departure',
        'port_of_discharge',
        'estimated_time_of_arrival',
        'actual_time_of_arrival',
        'total_packages',
        'total_weight',
        'total_volume',
        'package_uom',
        'weight_uom',
        'volume_uom',
        'currency',
      ],
    },
    {
      name: 'Profit sharing item',
      array_contains: true,
      key: 'total_profit_sharing_item',
    },
    {
      name: 'Non profit sharing item',
      array_contains: true,
      key: 'total_non_profit_sharing_item',
    },
    {
      name: 'Charge',
      array_contains: true,
      key: 'charges',
    },
  ],
};
