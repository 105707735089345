import { createSlice } from '@reduxjs/toolkit';
import compact from 'lodash/compact';

type ShipmentsState = {
  currentPageNumber: number;
  totalPages: number;
  shipments: any[];
  allClientShipments: Record<string, any>[];
  allClientShipmentsMapById: Record<string, any>;
  allClientShipmentIds: string[];
  firstToken: string;
  lastToken: string;
  isLoading: boolean;
  allShipmentsCount: number;
  totalData: number;
  error: any;
  stats: {
    needsAttention: number;
    missingDocuments: number;
    inProgress: number;
    completed: number;
    loading: boolean;
  };
};

const initialState: ShipmentsState = {
  currentPageNumber: 0,
  totalPages: 0,
  shipments: [],
  allClientShipmentIds: [],
  allClientShipments: [],
  allClientShipmentsMapById: {},
  firstToken: '',
  lastToken: '',
  isLoading: false,
  allShipmentsCount: 0,
  totalData: 0,
  error: null,
  stats: {
    needsAttention: 0,
    missingDocuments: 0,
    inProgress: 0,
    completed: 0,
    loading: false,
  },
};

const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    updateLoader(state) {
      state.isLoading = true;
    },
    updateShipmentList(state, action) {
      state.shipments = action.payload.shipments;
      state.totalPages = action.payload.totalPages;
      state.firstToken = action.payload.firstToken;
      state.lastToken = action.payload.lastToken;
      state.totalData = action.payload.totalData;
      state.error = null;
      state.isLoading = false;
    },
    updateStatsLoading(state) {
      state.stats.loading = true;
    },
    updateShipmentStats(state, action) {
      state.stats = {
        ...action.payload,
        loading: false,
      };
    },
    updateAllShipmentsCount(state, action) {
      state.allShipmentsCount = action.payload;
    },
    updateAllClientShipmentIds(state, action) {
      state.allClientShipmentIds = action.payload;
    },
    updateAllClientShipments(state, action) {
      state.allClientShipments = action.payload;
      state.allClientShipmentsMapById = action.payload.reduce(
        (acc: Record<string, any>, shipment: any) => ({
          ...acc,
          [shipment.id]: shipment,
        }),
        {}
      );
      state.allClientShipmentIds = compact(action.payload.map((shipment: any) => shipment.client_shipment_id));
    },
  },
});

export const {
  updateShipmentList,
  updateLoader,
  updateStatsLoading,
  updateShipmentStats,
  updateAllShipmentsCount,
  updateAllClientShipmentIds,
  updateAllClientShipments,
} = shipmentsSlice.actions;
export default shipmentsSlice.reducer;
