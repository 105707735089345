import React from 'react';

type PencilIconProps = {
  className?: string;
  onClick?: () => void;
};

const PencilIcon = (props: PencilIconProps) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_55484_12586)">
        <path
          d="M9 1.50195L11 3.50195M1 11.502L1.6382 9.16188C1.67984 9.00921 1.70066 8.93288 1.73262 8.8617C1.76099 8.79849 1.79586 8.73841 1.83666 8.68241C1.88261 8.61935 1.93856 8.5634 2.05045 8.4515L7.21716 3.2848C7.31616 3.18579 7.36566 3.13629 7.42275 3.11774C7.47296 3.10143 7.52704 3.10143 7.57725 3.11774C7.63434 3.13629 7.68384 3.18579 7.78284 3.2848L9.21716 4.71911C9.31616 4.81811 9.36566 4.86762 9.38421 4.9247C9.40053 4.97491 9.40053 5.029 9.38421 5.07921C9.36566 5.13629 9.31616 5.18579 9.21716 5.2848L4.05045 10.4515C3.93856 10.5634 3.88261 10.6193 3.81954 10.6653C3.76355 10.7061 3.70346 10.741 3.64026 10.7693C3.56907 10.8013 3.49274 10.8221 3.34007 10.8638L1 11.502Z"
          stroke="#111928"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_55484_12586">
          <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PencilIcon;
