import React from 'react';

type Props = {
  className?: string;
};

function ClipboardWithBackgroundIcon(props: Props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" fill="#F3F4F6" />
      <path
        d="M21.0981 9.6H18.7451C18.7451 9.17565 18.5799 8.76869 18.2857 8.46863C17.9915 8.16857 17.5925 8 17.1765 8H14.8236C14.4075 8 14.0086 8.16857 13.7144 8.46863C13.4202 8.76869 13.2549 9.17565 13.2549 9.6H10.902C10.486 9.6 10.087 9.76857 9.79281 10.0686C9.49864 10.3687 9.33337 10.7757 9.33337 11.2V22.4C9.33337 22.8243 9.49864 23.2313 9.79281 23.5314C10.087 23.8314 10.486 24 10.902 24H21.0981C21.5141 24 21.9131 23.8314 22.2073 23.5314C22.5014 23.2313 22.6667 22.8243 22.6667 22.4V11.2C22.6667 10.7757 22.5014 10.3687 22.2073 10.0686C21.9131 9.76857 21.5141 9.6 21.0981 9.6ZM17.1765 9.6V12H14.8236V9.6H17.1765ZM18.7451 20H13.2549C13.0469 20 12.8474 19.9157 12.7003 19.7657C12.5533 19.6157 12.4706 19.4122 12.4706 19.2C12.4706 18.9878 12.5533 18.7843 12.7003 18.6343C12.8474 18.4843 13.0469 18.4 13.2549 18.4H18.7451C18.9532 18.4 19.1526 18.4843 19.2997 18.6343C19.4468 18.7843 19.5295 18.9878 19.5295 19.2C19.5295 19.4122 19.4468 19.6157 19.2997 19.7657C19.1526 19.9157 18.9532 20 18.7451 20ZM18.7451 16.8H13.2549C13.0469 16.8 12.8474 16.7157 12.7003 16.5657C12.5533 16.4157 12.4706 16.2122 12.4706 16C12.4706 15.7878 12.5533 15.5843 12.7003 15.4343C12.8474 15.2843 13.0469 15.2 13.2549 15.2H18.7451C18.9532 15.2 19.1526 15.2843 19.2997 15.4343C19.4468 15.5843 19.5295 15.7878 19.5295 16C19.5295 16.2122 19.4468 16.4157 19.2997 16.5657C19.1526 16.7157 18.9532 16.8 18.7451 16.8Z"
        fill="#1F2A37"
      />
    </svg>
  );
}

export default ClipboardWithBackgroundIcon;
