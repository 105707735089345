import React from 'react'

export function Tasks(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19 2H5C3.89543 2 3 2.89543 3 4V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V4C21 2.89543 20.1046 2 19 2Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M3 7H21" stroke="currentColor" strokeWidth="1.5" />
      <path d="M8 2V4" stroke="currentColor" strokeWidth="1.5" />
      <path d="M16 2V4" stroke="currentColor" strokeWidth="1.5" />
      <path d="M7 11H17" stroke="currentColor" strokeWidth="1.5" />
      <path d="M7 15H17" stroke="currentColor" strokeWidth="1.5" />
      <path d="M7 19H13" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21Z"
        fill="currentColor"
      />
    </svg>
  )
}
