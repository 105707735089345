import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import authReducer from './slices/auth';
import documentDetailReducer from './slices/documentDetails';
import documentsReducer from './slices/documents';
import shipmentDetailsReducer from './slices/shipmentDetails';
import shipmentsReducer from './slices/shipments';
import sidebarReducer from './slices/sidebar';
import tasksReducer from './slices/tasks';

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    auth: authReducer,
    shipments: shipmentsReducer,
    tasks: tasksReducer,
    shipmentDetails: shipmentDetailsReducer,
    documentDetails: documentDetailReducer,
    documents: documentsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
