import React from 'react';

type Props = {
  className?: string;
};

const ExclamationOutLineIcon = (props: Props) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 0C5.9889 0 6.9556 0.293245 7.77785 0.842652C8.6001 1.39206 9.24096 2.17295 9.6194 3.08658C9.99783 4.00021 10.0969 5.00555 9.90392 5.97545C9.711 6.94535 9.23479 7.83627 8.53553 8.53553C7.83627 9.23479 6.94535 9.711 5.97545 9.90392C5.00555 10.0969 4.00021 9.99783 3.08658 9.6194C2.17295 9.24096 1.39206 8.6001 0.842652 7.77785C0.293245 6.9556 0 5.9889 0 5C0.00145626 3.67436 0.528708 2.40344 1.46607 1.46607C2.40344 0.528708 3.67436 0.00145626 5 0ZM5 9C5.79112 9 6.56448 8.7654 7.22228 8.32588C7.88008 7.88635 8.39277 7.26164 8.69552 6.53073C8.99827 5.79983 9.07748 4.99556 8.92314 4.21964C8.7688 3.44371 8.38784 2.73098 7.82843 2.17157C7.26902 1.61216 6.55628 1.2312 5.78036 1.07686C5.00444 0.922518 4.20017 1.00173 3.46926 1.30448C2.73836 1.60723 2.11365 2.11992 1.67412 2.77772C1.2346 3.43551 1 4.20887 1 5C1.00119 6.0605 1.423 7.07722 2.17289 7.82711C2.92278 8.577 3.9395 8.99881 5 9Z"
        fill="#9CA3AF"
      />
      <path
        d="M5 4C5.13261 4 5.25978 4.05268 5.35355 4.14645C5.44732 4.24021 5.5 4.36739 5.5 4.5L5.5 7C5.5 7.13261 5.44732 7.25978 5.35355 7.35355C5.25978 7.44732 5.13261 7.5 5 7.5C4.86739 7.5 4.74021 7.44732 4.64645 7.35355C4.55268 7.25978 4.5 7.13261 4.5 7L4.5 4.5C4.5 4.36739 4.55268 4.24021 4.64645 4.14645C4.74021 4.05268 4.86739 4 5 4Z"
        fill="#9CA3AF"
      />
      <path
        d="M5 2.5C4.72386 2.5 4.5 2.72386 4.5 3C4.5 3.27614 4.72386 3.5 5 3.5C5.27614 3.5 5.5 3.27614 5.5 3C5.5 2.72386 5.27614 2.5 5 2.5Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default ExclamationOutLineIcon;
