import React from 'react';

type Props = { onClick?: () => void; className?: string };

const ExclamationIcon = (props: Props) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_55271_7617)">
        <path
          d="M8 0.5C6.41775 0.5 4.87103 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346627 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C15.9977 6.37898 15.1541 4.3455 13.6543 2.84572C12.1545 1.34593 10.121 0.502329 8 0.5ZM8 12.5C7.84178 12.5 7.68711 12.4531 7.55555 12.3652C7.42399 12.2773 7.32145 12.1523 7.2609 12.0061C7.20035 11.86 7.18451 11.6991 7.21537 11.5439C7.24624 11.3887 7.32243 11.2462 7.43432 11.1343C7.5462 11.0224 7.68875 10.9462 7.84393 10.9154C7.99911 10.8845 8.15997 10.9003 8.30615 10.9609C8.45233 11.0214 8.57727 11.124 8.66518 11.2555C8.75308 11.3871 8.8 11.5418 8.8 11.7C8.8 11.9122 8.71572 12.1157 8.56569 12.2657C8.41566 12.4157 8.21218 12.5 8 12.5ZM8.8 9.3C8.8 9.51217 8.71572 9.71565 8.56569 9.86568C8.41566 10.0157 8.21218 10.1 8 10.1C7.78783 10.1 7.58435 10.0157 7.43432 9.86568C7.28429 9.71565 7.2 9.51217 7.2 9.3V5.3C7.2 5.08783 7.28429 4.88434 7.43432 4.73431C7.58435 4.58428 7.78783 4.5 8 4.5C8.21218 4.5 8.41566 4.58428 8.56569 4.73431C8.71572 4.88434 8.8 5.08783 8.8 5.3V9.3Z"
          fill="#E02424"
        />
      </g>
      <defs>
        <clipPath id="clip0_55271_7617">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExclamationIcon;
