import { Arrow, Content, Portal, Provider, Root, Trigger } from '@radix-ui/react-tooltip';
import React from 'react';

import { cn } from 'shared/utils/cn';

const Tooltip = ({ target, tooltip, side = 'right', className = '', arrowClassName = '' }: any) => {
  return tooltip ? (
    <Provider>
      <Root>
        <Trigger asChild>{target}</Trigger>
        <Portal>
          <Content
            className={cn(
              'data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-violet11 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]',
              className
            )}
            sideOffset={5}
            side={side}
          >
            {tooltip}
            <Arrow className={cn('fill-white', arrowClassName)} />
          </Content>
        </Portal>
      </Root>
    </Provider>
  ) : (
    target
  );
};

export default Tooltip;
