import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  currentPageNumber: 0,
  totalPages: 0,
  tasks: [],
  firstToken: '',
  lastToken: '',
  isLoading: false,
  totalData: 0,
  error: null,
  stats: {
    pending: 0,
    inProgress: 0,
    completed: 0,
    overdue: 0,
    loading: true,
  },
};

const tasks = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    updateLoader(state) {
      state.isLoading = true;
    },
    updateTasks(state, action) {
      state.tasks = action.payload.tasks;
      state.totalPages = action.payload.totalPages;
      state.firstToken = action.payload.firstToken;
      state.lastToken = action.payload.lastToken;
      state.totalData = action.payload.totalData;
      state.error = null;
      state.isLoading = false;
    },
    updateStatsLoading(state) {
      state.stats.loading = true;
    },
    updateTaskStats(state, action) {
      state.stats = {
        ...action.payload,
        loading: false,
      };
    },
  },
});

export const { updateTasks, updateLoader, updateStatsLoading, updateTaskStats } = tasks.actions;
export default tasks.reducer;
