import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
import { TAddress } from 'shared/constants/types';

import { InfoIcon } from 'shared/icons/Info';
import formatAddress from 'shared/utils/formatAddress';

type TAddressCellProps = {
  data: TAddress;
};

export default function AddressCell({ data }: TAddressCellProps) {
  const buttonRef = useRef(null);
  const panelRef = useRef(null);
  const [position, setPosition] = useState('left');

  let name = data?.name ?? '--';

  const checkPopoverPanelPosition = () => {
    if (buttonRef.current && panelRef.current) {
      const buttonRect = (buttonRef.current as HTMLElement).getBoundingClientRect();
      const panelWidth = (panelRef.current as HTMLElement).offsetWidth;
      const viewportWidth = window.innerWidth;
      const isSpaceOnRight = viewportWidth - buttonRect.right > panelWidth;
      setPosition(isSpaceOnRight ? 'right' : 'left');
    }
  };

  const triggerDetectPosition = () => {
    setTimeout(() => {
      checkPopoverPanelPosition();
    }, 0);
  };

  useEffect(() => {
    checkPopoverPanelPosition();
    window.addEventListener('scroll', checkPopoverPanelPosition, true);
    return () => {
      window.removeEventListener('scroll', checkPopoverPanelPosition, true);
    };
  }, []);

  if (!data) {
    return <></>;
  }

  return (
    <div className="flex">
      <span className="truncate">{name}</span>
      <Popover as="div" className="relative flex">
        <PopoverButton ref={buttonRef} type="button" className="!px-1 !outline-none" onClick={triggerDetectPosition}>
          <InfoIcon className="text-[#9CA3AF]" />
        </PopoverButton>
        <PopoverPanel
          ref={panelRef}
          anchor={{ gap: 4, to: 'right start', offset: -18 }}
          className="!max-w-64 data-[closed]:-translate-x-1 data-[closed]:opacity-0"
        >
          <div className="flex">
            {position === 'right' && (
              <div className="mt-4 h-0 w-0 border-[10px] border-l-0 border-transparent border-r-[#21283C]"></div>
            )}
            <div className="rounded bg-[#21283C] px-3 py-2 text-white">
              <p className="text-xs">
                <span className="font-bold">Address: </span>
                {/* Cannot normalize this text, we have to save address in normal format on backend */}
                <span className="text-gray-300">{formatAddress(data)}</span>
              </p>

              {data.emails && data.emails.length > 0 && (
                <p className="text-xs">
                  <span className="font-bold">Email: </span>
                  <span className="text-gray-300">{data.emails.join(', ')}</span>
                </p>
              )}
            </div>
            {position === 'left' && (
              <div className="mt-4 h-0 w-0 border-[10px] border-r-0 border-transparent border-l-[#21283C]"></div>
            )}
          </div>
        </PopoverPanel>
      </Popover>
    </div>
  );
}
