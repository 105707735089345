import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import React, { useState } from 'react';

import { DEFAULT_HANDLE_CLASSES } from 'models/ShipmentDetails/constants';
import { EShipmentRouteHandleId, EShipmentRouteVariant } from 'models/ShipmentDetails/enums';
import { TOceanContainer } from 'shared/constants/types';
import { cn } from 'shared/utils';
import ContainerBlock, { DEFAULT_CONTAINER_STATUS_LIST, TContainerStatusList } from './Blocks/ContainerBlock';
import HeadBlock from './Blocks/HeadBlock';
import TimeBlock from './Blocks/TimeBlock';

export type TPlaceOfDeliveryNode = Node<{
  label: string;
  location?: string;
  containerStatusList?: TContainerStatusList;
  variant: EShipmentRouteVariant;
  timestamp: number;
  isDelivered?: boolean;
  containers: TOceanContainer[];
  onOpenContainerList: () => void;
}>;

const PlaceOfDeliveryNode = (props: NodeProps<TPlaceOfDeliveryNode>) => {
  const {
    location,
    label,
    containers,
    onOpenContainerList,
    containerStatusList = DEFAULT_CONTAINER_STATUS_LIST.slice(0, 1),
    timestamp,
    variant,
    isDelivered = true,
  } = props.data || {};
  const [isExpanded, setIsExpanded] = useState(true);

  const isInactive = variant === EShipmentRouteVariant.inactive;
  const isActive = variant === EShipmentRouteVariant.active;

  return (
    <>
      <div
        className={cn(
          'leading-1 flex !w-[230px] flex-col gap-2 rounded-md border border-gray-300 bg-white px-3 py-4 text-[10px]',
          isActive && 'border-indigo-300 bg-indigo-100',
          isInactive && 'border-gray-200'
        )}
      >
        {isDelivered && (
          <div className="flex h-[17px] w-fit items-center rounded-[100px] border border-green-600 bg-[#DEF7EC] px-[3px] text-[10px] text-green-800">
            <span>Delivered</span>
          </div>
        )}
        <HeadBlock
          subLabel={location}
          label={label}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          variant={variant}
        />

        {isExpanded && (
          <>
            <ContainerBlock
              variant={variant}
              containers={containers}
              onOpenContainerList={onOpenContainerList}
              containerStatusList={containerStatusList}
              isWareHouse
            />
            {timestamp && <TimeBlock variant="delivery" vehicle="ship" isCompleted actualTime={timestamp} />}
          </>
        )}
      </div>
      <Handle
        id={EShipmentRouteHandleId.top}
        type="target"
        position={Position.Top}
        className={DEFAULT_HANDLE_CLASSES}
      />
      <Handle
        id={EShipmentRouteHandleId.bottom}
        type="source"
        position={Position.Bottom}
        className={DEFAULT_HANDLE_CLASSES}
      />
    </>
  );
};

export default PlaceOfDeliveryNode;
