import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { UserIcon } from '@heroicons/react/24/outline';
import { EShipmentRouteVariant } from 'models/ShipmentDetails/enums';
import React from 'react';

import AnchorIcon from 'shared/icons/AnchorIcon';
import BuildingIcon from 'shared/icons/BuildingIcon';
import CheckCircleIcon from 'shared/icons/CheckCircleIcon';
import { cn } from 'shared/utils/cn';

type HeadBlockProps = {
  label: string;
  subLabel?: string;
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  variant: EShipmentRouteVariant;
  type?: 'port' | 'building' | 'user';
};

const HeadBlock = ({ type = 'building', label, variant, subLabel, isExpanded, setIsExpanded }: HeadBlockProps) => {
  const isInactive = variant === EShipmentRouteVariant.inactive;
  const startIconClasses = cn('h-4 w-4 flex-shrink-0 basis-4');

  let startIcon;
  switch (type) {
    case 'port':
      startIcon = <AnchorIcon className={startIconClasses} />;
      break;
    case 'building':
      startIcon = <BuildingIcon className={startIconClasses} />;
      break;
    case 'user':
      startIcon = <UserIcon className={startIconClasses} />;
      break;
    default:
      break;
  }

  return (
    <div className="flex items-baseline gap-1" onClick={() => setIsExpanded(!isExpanded)}>
      <ChevronDownIcon className={cn('h-4 w-4 flex-shrink-0 basis-4', isExpanded && 'rotate-180 transform')} />
      <div className="flex items-center gap-2">
        <div className="flex gap-1">
          {startIcon}
          <div className="flex flex-col gap-[2px]">
            <div
              className={cn('flex flex-1 items-center gap-[5px] text-[12px] font-semibold leading-[1.2]', {
                'text-gray-400': isInactive,
              })}
            >
              <div>{label}</div>
              {isInactive && <CheckCircleIcon className="size-3" />}
            </div>
            {subLabel && isExpanded && (
              <span
                className={cn('text-gray-600', {
                  'text-gray-400': isInactive,
                })}
              >
                {subLabel}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadBlock;
