import React from 'react';

type Props = {
  className?: string;
  onClick?: () => void;
};

const CopyIcon = (props: Props) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.5 10C6.5 8.1144 6.5 7.1716 7.08579 6.58579C7.6716 6 8.6144 6 10.5 6H11.1667C13.0523 6 13.9951 6 14.5809 6.58579C15.1667 7.1716 15.1667 8.1144 15.1667 10V10.6667C15.1667 12.5523 15.1667 13.4951 14.5809 14.0809C13.9951 14.6667 13.0523 14.6667 11.1667 14.6667H10.5C8.6144 14.6667 7.6716 14.6667 7.08579 14.0809C6.5 13.4951 6.5 12.5523 6.5 10.6667V10Z"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8332 6C11.8316 4.02861 11.8018 3.00748 11.228 2.30829C11.1172 2.17326 10.9934 2.04946 10.8584 1.93864C10.1208 1.33334 9.02498 1.33334 6.83331 1.33334C4.64166 1.33334 3.54583 1.33334 2.80827 1.93864C2.67324 2.04945 2.54943 2.17326 2.43862 2.30829C1.83331 3.04586 1.83331 4.14168 1.83331 6.33334C1.83331 8.525 1.83331 9.6208 2.43862 10.3584C2.54943 10.4934 2.67324 10.6172 2.80827 10.728C3.50745 11.3019 4.52859 11.3317 6.49998 11.3333"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
