import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import compact from 'lodash/compact';
import React, { useEffect, useState } from 'react';

import Tooltip from 'shared/components/tooltip';
import CheckCircleIcon from 'shared/icons/CheckCircleIcon';
import { Clock } from 'shared/icons/Clock';
import ExclamationIcon from 'shared/icons/ExclamationIcon';
import ManualResolvedIcon from 'shared/icons/ManualResolvedIcon';
import { getDocumentTypeDisplayName } from 'shared/services';
import { cn } from 'shared/utils';
import { EValidationStatus, Validation } from './model';

const TOOLTIP_TEXT = {
  unvalidated: 'Pending validation',
  success: 'Passed validation',
  discrepancy: 'Failed validation',
  overridden: 'Validation manually resolved',
};

type ValidationStatusIconProps = {
  validation: Validation;
  value: string;
  valueToCompareWIthValidation: string;
  documents?: any[];
  onClick?: () => void;
};

const ValidationStatusIcon = ({
  validation,
  value,
  valueToCompareWIthValidation,
  documents = [],
  onClick,
}: ValidationStatusIconProps) => {
  const [documentList, setDocumentList] = useState<any[]>([]);

  const status = validation?.status;
  const fieldValues = validation?.field_values || [];
  const isNoDocumentOverridden = documentList.every((d) => !d?.isOverridden);

  const iconProps = {
    height: '20px',
    width: '20px',
    className: 'cursor-pointer',
    onClick: () => {
      if (onClick) {
        onClick();
      }
    },
  };

  let icon = null;
  let content = null;

  switch (status) {
    case EValidationStatus.UNVALIDATED:
      icon = <Clock {...iconProps} className="text-yellow-400" />;
      break;
    case EValidationStatus.DISCREPANCY:
      icon = <ExclamationIcon {...iconProps} />;
      break;
    case EValidationStatus.SUCCESS:
      icon = <CheckCircleIcon {...iconProps} />;
      break;
    case EValidationStatus.OVERRIDDEN:
      icon = <ManualResolvedIcon {...iconProps} />;
      break;
    default:
      icon = null;
      break;
  }

  const noDiscrepancyContent = (
    <div className="!leading-[15px]">
      <div className="!text-[10px] !font-bold">No discrepancy found!</div>
      <br className="!text-[12px]" />
      {documentList.map((d) => (
        <div key={d.id} className={cn('!text-[10px]', '!text-gray-400')}>
          <span>{d.label}: </span>
          <span>{d.value}</span>
        </div>
      ))}
    </div>
  );

  switch (status) {
    case EValidationStatus.SUCCESS:
      content = noDiscrepancyContent;
      break;
    case EValidationStatus.OVERRIDDEN:
      content = (
        <div className="!leading-[15px]">
          <div className="!text-[10px] !font-bold">Manually resolved!</div>
          <br className="!text-[12px]" />
          {documentList.map((d) => (
            <div key={d.id} className={cn('!text-[10px]', d.isOverridden ? 'font-bold' : '!text-gray-400')}>
              <span>{d.label}: </span>
              <span>{d.value}</span>
            </div>
          ))}
          {isNoDocumentOverridden && <div className="!text-[10px] !font-bold">Manually Entered: {value}</div>}
        </div>
      );
      break;
    case EValidationStatus.DISCREPANCY:
      content = (
        <div className="!leading-[15px]">
          <div className="!text-[10px] !font-bold">Data discrepancy detected!</div>
          <br className="!text-[12px]" />
          {documentList.map((d) => (
            <div key={d.id} className={cn('!text-[10px]', '!text-gray-400')}>
              <span>{d.label}: </span>
              <span>{d.value}</span>
            </div>
          ))}
        </div>
      );
      break;
    case EValidationStatus.UNVALIDATED:
      content = (
        <div className="!leading-[15px]">
          <div className="!text-[10px] !font-bold">Pending validation!</div>
          <br className="!text-[12px]" />
          {documentList.map((d) => (
            <div key={d.id} className={cn('!text-[10px]', '!text-gray-400')}>
              <span>{d.label}: </span>
              <span>{d.value}</span>
            </div>
          ))}
        </div>
      );
      break;
    default:
      break;
  }

  useEffect(() => {
    if (!!documents && documents.length > 0) {
      let list: any[] = [];

      fieldValues.forEach((field) => {
        const docIds = field?.shipping_document_ids || [];

        if (!docIds || docIds.length === 0) return;

        const extraDocs = compact(
          docIds.map((docId) => {
            const docMaybe = documents?.find((d) => d.id === docId);
            if (!docMaybe) return null;
            const labelMaybe = getDocumentTypeDisplayName(docMaybe?.type);
            if (!labelMaybe) return null;

            return {
              id: docId,
              label: labelMaybe,
              value: field?.value,
              isOverridden: field?.value?.toString() === valueToCompareWIthValidation?.toString(),
            };
          })
        );

        list = list.concat(extraDocs);
      });

      setDocumentList(list);
    }
  }, [fieldValues, documents]);

  if (content) {
    return (
      <Popover as="div" className="relative flex">
        <PopoverButton type="button" className="!px-1 !outline-none">
          <span>{icon}</span>
        </PopoverButton>
        <PopoverPanel anchor={{ gap: 4, to: 'right start', offset: -18 }}>
          <div className="flex">
            <div className="mt-4 h-0 w-0 border-[10px] border-l-0 border-transparent border-r-[#21283C]" />
            <div className="rounded bg-[#21283C] px-3 py-2 text-white">{content}</div>
          </div>
        </PopoverPanel>
      </Popover>
    );
  }

  return <Tooltip tooltip={TOOLTIP_TEXT[validation.status as keyof typeof TOOLTIP_TEXT]} target={icon} />;
};

export default ValidationStatusIcon;
