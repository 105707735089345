import { MapPinIcon } from '@heroicons/react/24/outline';
import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import React, { useState } from 'react';

import { DEFAULT_HANDLE_CLASSES } from 'models/ShipmentDetails/constants';
import { EShipmentRouteHandleId, EShipmentRouteVariant } from 'models/ShipmentDetails/enums';
import { TOceanContainer } from 'shared/constants/types';
import { cn } from 'shared/utils';
import ContainerBlock from './Blocks/ContainerBlock';
import HeadBlock from './Blocks/HeadBlock';

export type TShipperNode = Node<{
  label: string;
  name?: string;
  variant: EShipmentRouteVariant;
  containers: TOceanContainer[];
  onOpenContainerList: () => void;
  address: string;
}>;

const ShipperNode = (props: NodeProps<TShipperNode>) => {
  const { name, label, variant, containers, onOpenContainerList, address } = props.data || {};
  const [isExpanded, setIsExpanded] = useState(true);

  const isInactive = variant === EShipmentRouteVariant.inactive;
  const isActive = variant === EShipmentRouteVariant.active;

  return (
    <>
      <div
        className={cn(
          'leading-1 relative flex !w-[230px] flex-col gap-2 rounded-md border border-gray-300 bg-white px-3 py-4 text-[10px]',
          isActive && 'border-indigo-300 bg-indigo-100',
          isInactive && 'border-gray-200'
        )}
      >
        <HeadBlock
          subLabel={name}
          label={label}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          variant={variant}
          type="user"
        />

        {isExpanded && (
          <>
            <ContainerBlock containers={containers} onOpenContainerList={onOpenContainerList} variant={variant} />
            <div className="flex gap-2">
              <MapPinIcon className="size-3 flex-shrink-0" />
              <span className="text-[10px] text-gray-600">{address}</span>
            </div>
          </>
        )}
      </div>
      <Handle
        id={EShipmentRouteHandleId.bottom}
        type="source"
        position={Position.Bottom}
        className={DEFAULT_HANDLE_CLASSES}
      />
    </>
  );
};

export default ShipperNode;
