import ContainerStatusBadge, { EContainerStatus } from 'models/ShipmentDetails/components/ContainerStatusBadge';
import { EShipmentRouteVariant } from 'models/ShipmentDetails/enums';
import React from 'react';

import { TOceanContainer } from 'shared/constants/types';
import ContainerIcon from 'shared/icons/ContainerIcon';
import { cn } from 'shared/utils/cn';

export type TContainerStatusList = {
  id: string;
  count: number;
  status: EContainerStatus;
}[];

export const DEFAULT_CONTAINER_STATUS_LIST: TContainerStatusList = [
  {
    id: 'C123456',
    count: 1,
    status: EContainerStatus.notArrived,
  },
  {
    id: 'C1234562',
    count: 2,
    status: EContainerStatus.arrived,
  },
  {
    id: 'C1234563',
    count: 1,
    status: EContainerStatus.departed,
  },
];

type ContainerBlockProps = {
  variant: EShipmentRouteVariant;
  containers: TOceanContainer[];
  onOpenContainerList: () => void;
  containerStatusList?: TContainerStatusList;
  showStatus?: boolean;
  isWareHouse?: boolean;
};

const ContainerBlock = ({
  variant,
  containers,
  onOpenContainerList,
  containerStatusList = [],
  showStatus = false,
  isWareHouse = false,
}: ContainerBlockProps) => {
  const isInactive = variant === EShipmentRouteVariant.inactive;
  const isActive = variant === EShipmentRouteVariant.active;

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        <ContainerIcon variant={variant} className="h-4 w-4" />
        <div className="flex items-center font-bold" onClick={onOpenContainerList}>
          <span
            className={cn('text-gray-600', {
              'text-indigo-600': isActive,
              'text-gray-400': isInactive,
            })}
          >
            {containers.length > 1 ? `${containers.length} Containers` : `${containers.length} Container`}
          </span>
        </div>
      </div>

      {showStatus && (
        <div className="flex items-center gap-1">
          {containerStatusList.map((container, index) => (
            <ContainerStatusBadge key={index} count={container.count} status={container.status} variant={variant} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ContainerBlock;
