import { Accordion } from '@szhsin/react-accordion';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { AccordionItem } from 'shared/components/accordion-items';
import { Divider } from 'shared/components/divider';
import { Input } from 'shared/components/input';
import { Textarea } from 'shared/components/textarea';
import { EDocumentFieldLabel } from 'shared/constants/enums';
import ClipboardWithBackgroundIcon from 'shared/icons/ClipboardWithBackgroundIcon';
import { FieldListBasedOnDocumentType } from '../configs';

interface DigitizedViewProps {
  maxHeight: number;
}

const DigitizedView = ({ maxHeight }: DigitizedViewProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { isLoading, json_data, data: document } = useSelector((state: any) => state.documentDetails);

  const [scrollableSectionHeight, setScrollableSectionHeight] = useState(0);

  const jsonData = get(json_data, 'data', {});
  const documentType = get(document, 'type');

  const sections = useMemo(() => {
    const documentSections =
      FieldListBasedOnDocumentType[documentType as keyof typeof FieldListBasedOnDocumentType] || [];

    return flatten(
      documentSections.map((section: any) => {
        const textAreaFields = section?.text_area_fields || [];

        if (section?.array_contains) {
          const sectionList: any[] = [];

          jsonData?.[section.key]?.forEach((item: any, index: string) => {
            sectionList.push({
              title: `${section.name} ${index + 1}`,
              fields: Object.keys(item)?.map((itemKey) => ({
                label: EDocumentFieldLabel?.[itemKey as keyof typeof EDocumentFieldLabel],
                value: item[itemKey],
                element: textAreaFields.includes(itemKey) ? 'textarea' : 'text',
              })),
            });
          });

          return sectionList;
        }

        return {
          title: section.name,
          fields: section.json_keys?.map((key: any) => {
            return {
              label: EDocumentFieldLabel[key as keyof typeof EDocumentFieldLabel],
              value: jsonData?.[key],
            };
          }),
        };
      })
    );
  }, [jsonData, documentType]);

  useEffect(() => {
    if (headerRef.current) {
      setScrollableSectionHeight(maxHeight - headerRef.current.clientHeight - 4);
    }
  }, [maxHeight, headerRef]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="rounded-[10px] border border-gray-200">
      <div className="flex items-center gap-3 px-6 py-4" ref={headerRef}>
        <ClipboardWithBackgroundIcon />
        <span className="text-[18px] font-semibold">Digitized View</span>
      </div>
      <Divider />
      <div className="overflow-auto p-4" style={{ maxHeight: `${scrollableSectionHeight}px` }}>
        <Accordion transition transitionTimeout={200} allowMultiple className="flex flex-col gap-4">
          {sections.map((section: any, index: number) => {
            return (
              <AccordionItem
                key={index}
                className="rounded-lg border border-gray-200"
                buttonClassName="p-3"
                header={<span className="font-medium">{section.title}</span>}
                panelClassName="p-3"
                enterButtonClassName="bg-gray-100"
                initialEntered
              >
                <div className="grid grid-cols-3 gap-[10px]">
                  {section.fields?.map((field: any) => {
                    return (
                      <div key={`${section.title}_${field.label}`}>
                        <label className="text-sm font-medium">{field.label}</label>
                        {field.element === 'textarea' ? (
                          <Textarea rows={2} value={field.value} disabled />
                        ) : (
                          <Input type="text" value={field.value} disabled />
                        )}
                      </div>
                    );
                  })}
                </div>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default DigitizedView;
