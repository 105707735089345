import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaginatedQueryRequest } from '../firebase/model';
import {
  getAllClientShipmentIds,
  getTotalShipmentCount,
  listenerForShipmentStats,
  shipmentsListener,
} from '../firebase/query/shipments';
import {
  updateAllClientShipmentIds,
  updateAllShipmentsCount,
  updateLoader,
  updateShipmentList,
  updateShipmentStats,
  updateStatsLoading,
} from '../reduxStore/slices/shipments';
import { AppDispatch } from '../reduxStore/store';

const useShipments = (request: PaginatedQueryRequest) => {
  const orgId = useSelector((state: any) => state.auth.orgId);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(updateLoader());
    let unsubscribe: (() => void) | undefined;
    const initializeListener = async () => {
      try {
        unsubscribe = await shipmentsListener(
          {
            ...request,
            orgId,
          },
          (payload: any) => dispatch(updateShipmentList(payload))
        );
      } catch (error) {
        console.error('Error initializing shipment listener:', error);
      }
    };
    initializeListener();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, request, orgId]);

  useEffect(() => {
    dispatch(updateLoader());
    getTotalShipmentCount({ orgId }, (count: number) => {
      dispatch(updateAllShipmentsCount(count));
    });
  }, [dispatch, orgId]);

  useEffect(() => {
    dispatch(updateLoader());
    getAllClientShipmentIds({ orgId }, (clientShipmentIds: string[]) => {
      dispatch(updateAllClientShipmentIds(clientShipmentIds));
    });
  }, [dispatch, orgId]);

  useEffect(() => {
    dispatch(updateStatsLoading());
    const shipmentsStats = listenerForShipmentStats(
      {
        filter: [],
        page_size: 1, // TODO: not use
        orgId,
      },
      (payload: any) => {
        dispatch(updateShipmentStats(payload));
      }
    );
    return () => shipmentsStats();
  }, [dispatch, orgId]);
};

export default useShipments;
