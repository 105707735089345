import { EShipmentRouteHandleId } from './enums';

export const getCoordinatesGenerator = () => {
  let x = 0,
    y = 0;

  return (isSecondNode = false) => {
    if (isSecondNode) {
      y += 280;
    } else {
      y += 200;
    }
    return { x, y };
  };
};

export const getHandleStyles = (handleId: EShipmentRouteHandleId) => {
  const [, numerator, denominator] = handleId.split('.');

  switch (handleId) {
    case EShipmentRouteHandleId.top1_6:
    case EShipmentRouteHandleId.top2_6:
    case EShipmentRouteHandleId.top4_6:
    case EShipmentRouteHandleId.top5_6:
    case EShipmentRouteHandleId.bottom1_6:
    case EShipmentRouteHandleId.bottom2_6:
    case EShipmentRouteHandleId.bottom4_6:
    case EShipmentRouteHandleId.bottom5_6:
      return {
        left: `calc(100% * ${parseInt(numerator, 10)} / ${parseInt(denominator, 10)})`,
      };

    case EShipmentRouteHandleId.right1_6:
    case EShipmentRouteHandleId.right2_6:
    case EShipmentRouteHandleId.right4_6:
    case EShipmentRouteHandleId.right5_6:
    case EShipmentRouteHandleId.left1_6:
    case EShipmentRouteHandleId.left2_6:
    case EShipmentRouteHandleId.left4_6:
    case EShipmentRouteHandleId.left5_6:
      return {
        top: `calc(100% * ${parseInt(numerator, 10)} / ${parseInt(denominator, 10)})`,
      };
    default:
      return {};
  }
};
