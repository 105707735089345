import React from 'react';

const AlertTriangleIcon = () => {
  return (
    <div>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <g clipPath="url(#clip0_55186_3987)">
          <path
            d="M10.3331 7.50019V10.8335M10.3331 14.1669H10.3415M9.17923 3.24329L2.32516 15.0821C1.94499 15.7388 1.7549 16.0671 1.78299 16.3366C1.8075 16.5716 1.93064 16.7852 2.12177 16.9242C2.3409 17.0835 2.72029 17.0835 3.47906 17.0835H17.1872C17.946 17.0835 18.3254 17.0835 18.5445 16.9242C18.7356 16.7852 18.8588 16.5716 18.8833 16.3366C18.9114 16.0671 18.7213 15.7388 18.3411 15.0821L11.487 3.24329C11.1082 2.58899 10.9188 2.26184 10.6717 2.15196C10.4562 2.05612 10.2101 2.05612 9.99455 2.15196C9.74744 2.26184 9.55803 2.58899 9.17923 3.24329Z"
            stroke="#9CA3AF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_55186_3987">
            <rect width="20" height="20" fill="white" transform="translate(0.333008)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default AlertTriangleIcon;
