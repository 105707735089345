import React from 'react';

import ClockFastForwardIcon from 'shared/icons/ClockFastForwardIcon';
import PencilIcon from 'shared/icons/PencilIcon';
import ShipIcon from 'shared/icons/ShipIcon';
import TruckIcon from 'shared/icons/TruckIcon';
import { cn } from 'shared/utils/cn';
import { formatDate } from 'shared/utils/date';

const getTextContent = (isPickup: boolean, isShip: boolean, isCompleted = false) => {
  const preFix = isCompleted ? 'Actual' : 'Est';

  if (isShip) {
    return preFix + (isPickup ? ' Departure' : ' Arrival');
  }

  return preFix + (isPickup ? ' Pickup' : ' Delivery');
};

interface TimeBlockProps {
  vehicle: 'truck' | 'ship';
  variant: 'pickup' | 'delivery';
  estimatedTime?: number | string;
  actualTime?: number | string;
  isCompleted?: boolean;
  hideClockIcon?: boolean;
  editable?: boolean;
  onEdit?: () => void;
}

const TimeBlock = ({
  variant,
  vehicle,
  estimatedTime,
  actualTime,
  isCompleted = false,
  hideClockIcon = false,
  editable = false,
  onEdit = () => {},
}: TimeBlockProps) => {
  const isShip = vehicle === 'ship';
  const isPickup = variant === 'pickup';

  const startIcon = isShip ? <ShipIcon className="size-3" /> : <TruckIcon className="size-3" />;
  const timeToShow = isCompleted ? actualTime : estimatedTime;
  const formattedTimeToShow = timeToShow ? (typeof timeToShow === 'number' ? formatDate(timeToShow) : timeToShow) : '';
  const textContent = getTextContent(isPickup, isShip, isCompleted);

  return (
    <div className={cn('flex items-center gap-1 text-[10px]', isCompleted && 'text-gray-400')}>
      {startIcon}
      <div className="flex items-center gap-[2px] leading-[1]">
        <span className={cn('text-gray-600', isCompleted && 'text-gray-400')}>{textContent}</span>
        <span className={cn('font-bold text-gray-900', isCompleted && 'text-gray-400')}>{formattedTimeToShow}</span>
        {!hideClockIcon && (
          <ClockFastForwardIcon className={cn('size-3 stroke-gray-600', isCompleted && 'text-gray-400')} />
        )}
        {editable && (
          <PencilIcon className={cn('size-3 stroke-gray-600', isCompleted && 'text-gray-400')} onClick={onEdit} />
        )}
      </div>
    </div>
  );
};

export default TimeBlock;
