import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import React, { useState } from 'react';

import { DEFAULT_HANDLE_CLASSES } from 'models/ShipmentDetails/constants';
import { EShipmentRouteHandleId, EShipmentRouteVariant } from 'models/ShipmentDetails/enums';
import { TOceanContainer } from 'shared/constants/types';
import { cn } from 'shared/utils';
import ContainerBlock, { DEFAULT_CONTAINER_STATUS_LIST, TContainerStatusList } from './Blocks/ContainerBlock';
import HeadBlock from './Blocks/HeadBlock';

export type TPlaceOfReceiptNode = Node<{
  label: string;
  location?: string;
  containerStatusList?: TContainerStatusList;
  type: 'loading' | 'departure';
  variant: EShipmentRouteVariant;
  containers: TOceanContainer[];
  onOpenContainerList: () => void;
}>;

const PlaceOfReceiptNode = (props: NodeProps<TPlaceOfReceiptNode>) => {
  const {
    location,
    label,
    variant,
    containers,
    onOpenContainerList,
    containerStatusList = DEFAULT_CONTAINER_STATUS_LIST,
  } = props.data || {};
  const [isExpanded, setIsExpanded] = useState(true);

  const isInactive = variant === EShipmentRouteVariant.inactive;
  const isActive = variant === EShipmentRouteVariant.active;

  return (
    <>
      <div
        className={cn(
          'leading-1 relative flex !w-[230px] flex-col gap-2 rounded-md border border-gray-300 bg-white px-3 py-4 text-[10px]',
          isActive && 'border-indigo-300 bg-indigo-100',
          isInactive && 'border-gray-200'
        )}
      >
        <HeadBlock
          subLabel={location}
          label={label}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          variant={variant}
        />

        {isExpanded && (
          <ContainerBlock
            containers={containers}
            onOpenContainerList={onOpenContainerList}
            variant={variant}
            containerStatusList={containerStatusList}
          />
        )}
      </div>
      <Handle
        id={EShipmentRouteHandleId.bottom}
        type="source"
        position={Position.Bottom}
        className="size-0 min-w-[unset] border-0 bg-transparent"
      />
      <Handle
        id="left"
        type="source"
        position={Position.Left}
        className="size-0 min-w-[unset] border-0 bg-transparent"
      />
      <Handle
        id={EShipmentRouteHandleId.top}
        type="target"
        position={Position.Top}
        className={DEFAULT_HANDLE_CLASSES}
      />
      <Handle id="right" type="source" position={Position.Right} className={DEFAULT_HANDLE_CLASSES} />
    </>
  );
};

export default PlaceOfReceiptNode;
