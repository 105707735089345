import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentById } from '../firebase/query/documents';
import {
  fetchDocumentShipmentDetails,
  setDocumentDetails,
  updateGetDocumentLoader,
} from '../reduxStore/slices/documentDetails';
import { AppDispatch } from '../reduxStore/store';

const useDocumentDetails = (docId: string) => {
  const orgId = useSelector((state: any) => state.auth.orgId);
  const document = useSelector((state: any) => state.documentDetails.data);
  const dispatch = useDispatch<AppDispatch>();

  const docDetailsListener = useMemo(
    () =>
      fetchDocumentById(
        {
          docId,
          orgId,
        },
        (payload: any) => dispatch(setDocumentDetails(payload))
      ),
    [dispatch, orgId, docId]
  );

  useEffect(() => {
    dispatch(updateGetDocumentLoader());

    return () => docDetailsListener();
    //TODO: request was a dependency but caused an infinite loop/rerender so I removed it. Dig deeper what is happening and why
  }, [dispatch, orgId, docDetailsListener]);

  useEffect(() => {
    if (document) {
      const shipmentId = document.shipment_ids?.[0];
      // TODO: fetch shipment detail to get client shipment ID
      if (shipmentId) dispatch(fetchDocumentShipmentDetails(shipmentId));
    }
  }, [document, dispatch]);
};

export default useDocumentDetails;
