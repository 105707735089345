import { routesKeys } from 'containers/Shipments/Details/model';
import { Accordion } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { fetchDocumentById } from 'shared/firebase/query/documents';
import { cn } from 'shared/utils';
import CorrectValueModal from './CorrectValueModal';
import DiscrepancySection from './DiscrepancySection';
import IssueBadge from './IssueBadge';
import TaskDiscrepancyIcon from './TaskDiscrepancyIcon';

export default function TaskDiscrepancyPanel({
  task,
  shipment,
  column = 2,
  selectedValues,
  setSelectedValues,
}: {
  task: any;
  shipment: any;
  column?: 1 | 2;
  selectedValues: Record<string, string>;
  setSelectedValues(values: Record<string, string>): void;
}) {
  const [showModal, setShowModal] = useState(false);
  const [currentField, setCurrentField] = useState<string | null>(null); // Track the field name for modal
  const [customValue, setCustomValue] = useState<string>(''); // Store the custom input value
  const [documentDetails, setDocumentDetails] = useState<Record<string, any>>({});
  const [customValues, setCustomValues] = useState<Record<string, string>>({}); // Track custom values per field
  const [issueCount, setIssueCount] = useState(0);
  const [solvedCount, setSolvedCount] = useState(0);

  const discrepancyRecords = React.useMemo(() => {
    return (
      shipment?.validation_result?.records?.filter(
        (record: any) => record.status === 'discrepancy' || record.status === 'overridden'
      ) || []
    );
  }, [shipment?.validation_result?.records]);

  useEffect(() => {
    const discrepancyCount = discrepancyRecords.filter((record: any) => record.status === 'discrepancy').length;

    const overriddenCount = discrepancyRecords.filter((record: any) => record.status === 'overridden').length;

    setIssueCount(discrepancyCount);
    setSolvedCount(overriddenCount);
  }, [discrepancyRecords]);

  const fetchDocument = (docId: string, orgId: string) => {
    if (!documentDetails[docId]) {
      fetchDocumentById({ orgId, docId }, (document: any) =>
        setDocumentDetails((prev) => ({ ...prev, [docId]: document }))
      );
    }
  };

  const handleValueChange = (fieldName: string, value: string) => {
    setSelectedValues({
      ...selectedValues,
      [fieldName]: value,
    });
  };

  const handleModalOpen = (fieldName: string) => {
    setCurrentField(fieldName); // Set the active field for the modal
    setCustomValue(customValues[fieldName] || ''); // Pre-fill with existing custom value, if any
    setShowModal(true);
  };

  const handleModalSubmit = () => {
    if (currentField) {
      setCustomValues((prev) => ({ ...prev, [currentField]: customValue })); // Save the custom value
      handleValueChange(currentField, customValue); // Set the custom value as selected
      setShowModal(false); // Close the modal
    }
  };

  const getResolvedValue = (fieldName: string, data: any): any | undefined => {
    if (!data || !fieldName) return undefined;

    const routeKey = routesKeys.find((route) => route.key === fieldName);
    if (!routeKey) return undefined;

    let fieldValue = data[fieldName];

    // Handle fallBackValidationKey if primary field is undefined
    if (!fieldValue && routeKey.fallBackValidationKey) {
      fieldValue = data[routeKey.fallBackValidationKey];
    }

    // Handle accesorKey if fieldValue is an object
    if (typeof fieldValue === 'object' && fieldValue !== null && routeKey.accesorKey) {
      fieldValue = fieldValue[routeKey.accesorKey];
    }

    // Handle timestamps
    if (routeKey.isTimestamp && typeof fieldValue === 'number') {
      fieldValue = new Date(fieldValue * 1000).toISOString(); // Convert seconds to ISO string
    }

    // Ensure the value is a string
    return fieldValue ? fieldValue : undefined;
  };

  return (
    <>
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title className="relative bg-transparent px-4 py-3 text-sm dark:bg-transparent">
            <IssueBadge issueCount={issueCount} solvedCount={solvedCount} />
            <div className="flex items-center gap-2">
              {issueCount === 0 ? <TaskDiscrepancyIcon type="success" /> : <TaskDiscrepancyIcon type="exclamation" />}
              <span className="text-md font-bold text-black">Shipment Data</span>
            </div>
          </Accordion.Title>
          <Accordion.Content className="p-4 px-4 dark:bg-gray-800">
            <div className={cn('grid grid-cols-2 gap-4', column === 1 && 'grid-cols-1')}>
              {discrepancyRecords.map((record: any, index: number) => {
                const documentIds = record.field_values
                  .flatMap((fieldValue: any) => fieldValue.shipping_document_ids)
                  .filter(Boolean);

                documentIds.forEach((docId: any) => fetchDocument(docId, task.org_id));

                const documents = documentIds.map((docId: any) => documentDetails[docId]).filter(Boolean);

                // Resolve the current value dynamically based on shipment.data and routesKeys
                const resolvedValue = getResolvedValue(record.field_name, shipment?.data);
                const currentValue = selectedValues[record.field_name] || resolvedValue || '';

                return (
                  <DiscrepancySection
                    key={index}
                    label={record.field_name}
                    options={record.field_values.map((value: any) => ({
                      value: value.value,
                      label: `${value.value}`,
                      custom: false,
                    }))}
                    customValue={customValues[record.field_name] || null}
                    currentValue={currentValue}
                    onValueChange={(value: string) => handleValueChange(record.field_name, value)}
                    onModalOpen={() => handleModalOpen(record.field_name)}
                    documents={documents}
                    fieldValues={record.field_values}
                    record={record}
                    selectedValues={selectedValues}
                    task={task}
                  />
                );
              })}
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>

      <CorrectValueModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        fieldName={currentField}
        customValue={customValue}
        setCustomValue={setCustomValue}
        onSubmit={handleModalSubmit}
      />
    </>
  );
}
