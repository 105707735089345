export const countFilter = (filter: any) => {
  let count = 0;
  const excludedKeys = ['page_size', 'page', 'order_by', 'order_direction', 'orgId'];

  const filterKeys = Object.keys(filter);

  filterKeys.forEach((key) => {
    if (excludedKeys.includes(key)) {
      return;
    }

    const value = filter[key];

    if (!value) {
      return;
    }

    if (Array.isArray(value) && value.length > 0) {
      count++;
      return;
    }

    if (typeof value === 'string' && value.trim().length > 0) {
      count++;
      return;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      count++;
      return;
    }
  });

  return count;
};
