import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PaginatedQueryRequest } from '../firebase/model';
import { documentStats, fetchDocuments, getTotalDocumentCount } from '../firebase/query/documents';
import {
  updateAllDocumentsCount,
  updateDocsList,
  updateDocsListStats,
  updateDocsListStatsLoading,
  updateLoader,
} from '../reduxStore/slices/documents';
import { AppDispatch } from '../reduxStore/store';

const useDocuments = (request: PaginatedQueryRequest) => {
  const orgId = useSelector((state: any) => state.auth.orgId);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    dispatch(updateLoader());

    const initializeListener = async () => {
      try {
        unsubscribe = await fetchDocuments(
          {
            ...request,
            orgId,
          },
          (payload: any) => {
            dispatch(updateDocsList(payload));
          }
        );
      } catch (error) {
        console.error('Error initializing shipment listener:', error);
      }
    };
    initializeListener();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [request, orgId, dispatch]);

  useEffect(() => {
    dispatch(updateLoader());
    getTotalDocumentCount(orgId, (count: number) => {
      dispatch(updateAllDocumentsCount(count));
    });
  }, [dispatch, orgId]);

  useEffect(() => {
    dispatch(updateDocsListStatsLoading());
    const docStats = documentStats(
      {
        filter: [],
        page_size: 1,
        orgId,
      },
      (payload: any) => dispatch(updateDocsListStats(payload))
    );
    return () => docStats();
  }, [dispatch, request, orgId]);
};

export default useDocuments;
