import React from 'react';

type Props = {
  onClick?: () => void;
  className?: string;
};

const CloseIcon = (props: Props) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.0885 6.5001L11.1645 2.42408C11.2381 2.35304 11.2967 2.26807 11.3371 2.17412C11.3774 2.08017 11.3987 1.97912 11.3996 1.87687C11.4005 1.77462 11.381 1.67321 11.3423 1.57857C11.3035 1.48393 11.2464 1.39795 11.1741 1.32565C11.1018 1.25334 11.0158 1.19616 10.9211 1.15744C10.8265 1.11872 10.7251 1.09924 10.6228 1.10013C10.5206 1.10102 10.4195 1.12226 10.3256 1.16262C10.2316 1.20298 10.1467 1.26164 10.0756 1.33519L5.99961 5.41121L1.92359 1.33519C1.77835 1.19492 1.58383 1.1173 1.38192 1.11905C1.18001 1.12081 0.986864 1.2018 0.844085 1.34457C0.701307 1.48735 0.620319 1.6805 0.618564 1.88241C0.616809 2.08432 0.694429 2.27884 0.834705 2.42408L4.91072 6.5001L0.834705 10.5761C0.761155 10.6471 0.702488 10.7321 0.662129 10.8261C0.621771 10.92 0.600527 11.0211 0.599638 11.1233C0.59875 11.2256 0.618234 11.327 0.656954 11.4216C0.695674 11.5163 0.752855 11.6022 0.825159 11.6745C0.897464 11.7469 0.983445 11.804 1.07808 11.8428C1.17272 11.8815 1.27413 11.901 1.37638 11.9001C1.47863 11.8992 1.57968 11.8779 1.67363 11.8376C1.76758 11.7972 1.85256 11.7386 1.92359 11.665L5.99961 7.58898L10.0756 11.665C10.2209 11.8053 10.4154 11.8829 10.6173 11.8811C10.8192 11.8794 11.0124 11.7984 11.1551 11.6556C11.2979 11.5128 11.3789 11.3197 11.3807 11.1178C11.3824 10.9159 11.3048 10.7213 11.1645 10.5761L7.0885 6.5001Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default CloseIcon;
