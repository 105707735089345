import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { signInWithEmailAndPassword, signOut, User } from 'firebase/auth';
import { auth } from '../../firebase/config';

const initialState: any = {
  user: null,
  orgId: '',
  isLoading: false,
  error: null,
};

export const getOrgId = async (user: User) => {
  const idToken = await user.getIdTokenResult();
  return idToken.claims.org_id;
};

export const handleLogin = createAsyncThunk(
  'users/login',
  async ({ username, password }: { username: string; password: string }) => {
    const response = await signInWithEmailAndPassword(auth, username, password);
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `user=${JSON.stringify(response.user.providerData?.[0]) + ';' + expires + ';path=/'}`;
    return {
      user: response.user.providerData?.[0],
      orgId: await getOrgId(response.user),
    };
  }
);

export const handleLogout = createAsyncThunk('users/logout', async () => {
  try {
    const response = await signOut(auth);
    return response;
  } catch (e) {
    console.log('Error-->', e);
    return undefined;
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    persistLogin(state, action) {
      state.error = null;
      state.user = action.payload.user;
      state.orgId = action.payload.orgId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload.user;
        state.orgId = action.payload.orgId;
        state.error = null;
        state.isLoading = false;
      }
    });
    builder.addCase(handleLogin.rejected, (state, action) => {
      state.error = action.error;
    });
    builder.addCase(handleLogout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(handleLogout.fulfilled, (state) => {
      state.user = null;
      state.isLoading = false;
      state.orgId = '';
    });
  },
});

export const { persistLogin } = authSlice.actions;
export default authSlice.reducer;
