import { TaskType } from './enums';
import { TaskTypeLabel } from './labels';

export const TASK_URGENCY_FILTER_OPTIONS = [
  { label: 'Overdue', value: 'overdue' },
  { label: 'Due Soon', value: 'due_soon' },
  { label: 'Upcoming', value: 'upcoming' },
];

export const TASK_TYPE_FILTER_OPTIONS = Object.keys(TaskType).map((key) => ({
  value: key,
  label: TaskTypeLabel[key as TaskType],
}));
