import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import ProcessingStatus from 'containers/Document/components/ProcessingStatus';
import get from 'lodash/get';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import { EDocumentType } from 'shared/constants/enums';
import { TDocumentType } from 'shared/constants/types';
import CopyIcon from 'shared/icons/CopyIcon';
import TickIcon from 'shared/icons/TickIcon';
import { cn } from 'shared/utils';

interface Props {}

const MAX_FILE_NAME_LENGTH = 26;
const HIDE_COPIED_ALERT_TIME = 800;

const DocumentDetailsHeader: React.FC<Props> = () => {
  const {
    data: document,
    shipmentDetails,
    isFetchingShipmentDetails,
  } = useSelector((state: any) => state.documentDetails);
  const [isCopied, setIsCopied] = useState(false);

  const rawFileName = get(document, 'metadata.received_email_doc_filename');
  const documentType = get(document, 'type');
  const processingStatus = get(document, 'processing_result.status_code');
  const processingTimestamp = get(document, 'created_at');
  const clientShipmentId = get(shipmentDetails, 'client_shipment_id', '--');

  const isFileNameOverMax = rawFileName ? rawFileName.length > MAX_FILE_NAME_LENGTH : false;
  const positionClassName = `!left-[${(rawFileName?.length || 0) * 9}px]`;

  const formattedFileName = rawFileName
    ? isFileNameOverMax
      ? `${rawFileName.slice(0, MAX_FILE_NAME_LENGTH)}...`
      : rawFileName
    : '--';

  const handleCopyFileName = () => {
    if (isCopied || !rawFileName) return;

    navigator.clipboard.writeText(rawFileName);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, HIDE_COPIED_ALERT_TIME);
  };

  return (
    <div className="flex flex-wrap items-center justify-between border-b border-gray-200 bg-white px-6 py-4">
      <div className="flex w-full justify-between gap-10">
        <div className="flex items-center gap-6">
          <div className="flex flex-col gap-1">
            <div className="flex">
              {rawFileName && (
                <Popover as="div" className="relative">
                  <PopoverButton type="button" className="!px-0 !outline-none">
                    <h2 className="text-lg font-bold">{formattedFileName}</h2>
                  </PopoverButton>
                  <PopoverPanel
                    anchor="top end"
                    className={cn('!top-12', isFileNameOverMax ? '!left-[250px]' : positionClassName)}
                  >
                    <div className="flex flex-col">
                      <div className="ml-4 h-0 w-0 border-[4px] border-t-0 border-transparent border-b-gray-800" />
                      <div className="flex items-center gap-2 rounded bg-gray-800 px-3 py-1.5 text-[14px] font-medium leading-[1] text-white">
                        <div className="max-w-[650px] break-all text-[14px] leading-[1]">{rawFileName}</div>
                        <div
                          className={cn(
                            'flex items-center gap-[2px] text-[14px] leading-[1]',
                            isCopied ? 'cursor-default' : 'cursor-pointer'
                          )}
                          onClick={handleCopyFileName}
                        >
                          {isCopied ? (
                            <>
                              <TickIcon />
                              <span>Copied!</span>
                            </>
                          ) : (
                            <>
                              <CopyIcon />
                              <span>Copy</span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </PopoverPanel>
                </Popover>
              )}
            </div>
            <p className="text-sm text-gray-700">
              {isFetchingShipmentDetails ? <Skeleton width={100} height={18} /> : clientShipmentId}
            </p>
          </div>
        </div>
        <div className="flex grow flex-wrap justify-start gap-6 text-sm text-gray-600">
          <div className="flex flex-col gap-1">
            <span className="text-xs">Processing status</span>
            <ProcessingStatus variant="default" className="cursor-default" text={processingStatus || '--'} />
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-xs">Document type</span>
            {documentType && (
              <span className="font-semibold text-gray-900">
                {EDocumentType[documentType as TDocumentType] || documentType}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-xs">Processing timestamp</span>
            <span className="font-semibold text-gray-900">
              {processingTimestamp ? new Date(processingTimestamp).toLocaleString() : '--'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentDetailsHeader;
