import React from 'react'

export default function TaskDiscrepancyIcon({ type, green }: { type: string; green?: boolean }) {
  switch (type) {
    case 'exclamation':
      return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_156_34726)">
            <path
              d="M8 0.5C6.41775 0.5 4.87103 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346627 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C15.9977 6.37898 15.1541 4.3455 13.6543 2.84572C12.1545 1.34593 10.121 0.502329 8 0.5ZM8 12.5C7.84178 12.5 7.68711 12.4531 7.55555 12.3652C7.42399 12.2773 7.32145 12.1523 7.2609 12.0061C7.20035 11.86 7.18451 11.6991 7.21537 11.5439C7.24624 11.3887 7.32243 11.2462 7.43432 11.1343C7.5462 11.0224 7.68875 10.9462 7.84393 10.9154C7.99911 10.8845 8.15997 10.9003 8.30615 10.9609C8.45233 11.0214 8.57727 11.124 8.66518 11.2555C8.75308 11.3871 8.8 11.5418 8.8 11.7C8.8 11.9122 8.71572 12.1157 8.56569 12.2657C8.41566 12.4157 8.21218 12.5 8 12.5ZM8.8 9.3C8.8 9.51217 8.71572 9.71565 8.56569 9.86568C8.41566 10.0157 8.21218 10.1 8 10.1C7.78783 10.1 7.58435 10.0157 7.43432 9.86568C7.28429 9.71565 7.2 9.51217 7.2 9.3V5.3C7.2 5.08783 7.28429 4.88434 7.43432 4.73431C7.58435 4.58428 7.78783 4.5 8 4.5C8.21218 4.5 8.41566 4.58428 8.56569 4.73431C8.71572 4.88434 8.8 5.08783 8.8 5.3V9.3Z"
              fill="#E02424"
            />
          </g>
          <defs>
            <clipPath id="clip0_156_34726">
              <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
      )
    case 'success':
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87898 15.1541 3.8455 13.6543 2.34572C12.1545 0.845932 10.121 0.00232928 8 0ZM10.9656 6.9656L7.7656 10.1656C7.61558 10.3156 7.41213 10.3998 7.2 10.3998C6.98787 10.3998 6.78442 10.3156 6.6344 10.1656L5.0344 8.5656C4.88868 8.41472 4.80804 8.21263 4.80986 8.00288C4.81169 7.79312 4.89582 7.59247 5.04415 7.44414C5.19247 7.29582 5.39312 7.21168 5.60288 7.20986C5.81264 7.20804 6.01472 7.28867 6.1656 7.4344L7.2 8.4688L9.8344 5.8344C9.98528 5.68867 10.1874 5.60804 10.3971 5.60986C10.6069 5.61168 10.8075 5.69582 10.9559 5.84414C11.1042 5.99247 11.1883 6.19312 11.1901 6.40288C11.192 6.61263 11.1113 6.81472 10.9656 6.9656Z"
            fill="#0E9F6E"
          />
        </svg>
      )
    case 'warning':
      return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.3934 6.15855L9.4857 1.95855C9.43046 1.87871 9.35666 1.81347 9.27065 1.76843C9.18463 1.72338 9.08898 1.69989 8.99188 1.69995H1.09982C0.990351 1.69999 0.88298 1.72997 0.789331 1.78665C0.695682 1.84333 0.619324 1.92455 0.568523 2.02151C0.517723 2.11847 0.494417 2.22748 0.501129 2.33674C0.507841 2.446 0.544315 2.55134 0.606601 2.64135L3.27849 6.49995L0.606601 10.3586C0.544315 10.4486 0.507841 10.5539 0.501129 10.6632C0.494417 10.7724 0.517723 10.8814 0.568523 10.9784C0.619324 11.0754 0.695682 11.1566 0.789331 11.2133C0.88298 11.2699 0.990351 11.2999 1.09982 11.3H8.99188C9.08898 11.3 9.18463 11.2765 9.27065 11.2315C9.35666 11.1864 9.43046 11.1212 9.4857 11.0414L12.3934 6.84135C12.4628 6.74103 12.5 6.62194 12.5 6.49995C12.5 6.37796 12.4628 6.25887 12.3934 6.15855Z"
            fill={green ? '#057A55' : '#9B1C1C'}
          />
        </svg>
      )
    case 'document':
      return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.6663 7.29224V3.96724C11.6663 2.98714 11.6663 2.4971 11.4756 2.12275C11.3078 1.79347 11.0401 1.52575 10.7108 1.35797C10.3365 1.16724 9.84643 1.16724 8.86634 1.16724H5.13301C4.15292 1.16724 3.66287 1.16724 3.28852 1.35797C2.95924 1.52575 2.69152 1.79347 2.52375 2.12275C2.33301 2.4971 2.33301 2.98714 2.33301 3.96724V10.0339C2.33301 11.014 2.33301 11.504 2.52375 11.8784C2.69152 12.2077 2.95924 12.4754 3.28852 12.6432C3.66287 12.8339 4.15292 12.8339 5.13301 12.8339H6.99967M8.16634 6.41724H4.66634M5.83301 8.75057H4.66634M9.33301 4.0839H4.66634M8.45801 11.0839L9.62467 12.2506L12.2497 9.62557"
            stroke="#4B5563"
            strokeWidth="1.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    default:
      return null
  }
}
