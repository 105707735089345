import React from 'react';
import { Link } from 'react-router-dom';

import { EDocumentType } from 'shared/constants/enums';
import { TDocumentType } from 'shared/constants/types';
import FileCheck02 from 'shared/icons/FileCheck02';
import FileX02 from 'shared/icons/FileX02';
import { cn } from 'shared/utils';

const styles = {
  success: 'border-green-600 text-green-600',
  error: 'border-red-600 text-red-600',
  warning: 'border-yellow-500 text-yellow-500',
  missing: 'border-gray-300 text-gray-400 cursor-default',
};

type DocumentChipProps = {
  hasDocument: boolean;
  name: string;
  count?: number;
  className?: string;
  color?: keyof typeof styles;
  link?: string;
};

export default function DocumentChip({
  className,
  name,
  count,
  hasDocument,
  color = 'success',
  link,
}: DocumentChipProps) {
  const shortenedName = EDocumentType[name as TDocumentType] || name;

  const Item = (
    <>
      {hasDocument ? <FileCheck02 className="h-4 w-4" /> : <FileX02 className="h-4 w-4" />}
      <span className="ml-[5px] block text-sm uppercase">{shortenedName}</span>
      {count && (
        <span className={cn('ml-[5px] block rounded-full border px-2 text-xs font-medium', styles[color])}>
          {count}
        </span>
      )}
    </>
  );

  if (link) {
    return (
      <Link
        to={link}
        target="_blank"
        rel="noreferrer"
        className={cn(
          'flex flex-grow-0 items-center rounded-md border px-2 py-1 font-medium',
          styles[color],
          className
        )}
      >
        {Item}
      </Link>
    );
  }

  return (
    <div
      className={cn(
        'flex flex-grow-0 cursor-default items-center rounded-md border px-2 py-1 font-medium',
        styles[color],
        className
      )}
    >
      {Item}
    </div>
  );
}
