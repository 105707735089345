import { TAddress } from 'shared/constants/types';

export default function formatAddress(address: TAddress) {
  let str = '';
  const country = address.country || address.country_code;
  if (address.address_line_1) {
    str += address.address_line_1.trim();
  }

  if (address.address_line_2) {
    str += ` ${address.address_line_2.trim()}`;
  }

  if (address.city && str.length > 0) {
    str += `, ${address.city.trim()}`;
  }

  if (address.state && str.length > 0) {
    str += `, ${address.state.trim()}`;
  }

  if (country && str.length > 0) {
    str += `, ${country.trim()}`;
  }

  if (address.postal_code && str.length > 0) {
    str += `, ${address.postal_code.trim()}`;
  }

  return str;
}
