export const inputBoxOperations = [
  {
    value: '==',
    label: 'is',
  },
  {
    value: '!=',
    label: 'is not',
  },
];

export const dropdownOperations = [
  {
    value: 'in',
    label: 'in',
  },
  {
    value: 'not-in',
    label: 'not in',
  },
];

export const validationStatusOptions = [
  {
    label: 'All OK',
    value: 'all_ok',
  },
  {
    label: 'Partial OK',
    value: 'partial_ok',
  },
  {
    label: 'Discrepancy',
    value: 'discrepancy',
  },
];

export const docOptions = [
  {
    label: 'Success',
    value: 'success',
  },
  {
    label: 'Failure',
    value: 'failure',
  },
  {
    label: 'Unreceived',
    value: 'unreceived',
  },
];

export const modeOptions = [
  {
    label: 'FCL',
    value: 'fcl',
  },
  {
    label: 'LCL',
    value: 'lcl',
  },
  {
    label: 'AIR',
    value: 'air',
  },
  {
    label: 'FTL',
    value: 'ftl',
  },
  {
    label: 'LTL',
    value: 'ltl',
  },
];

export const PROCESSING_STATUS_OPTIONS = [
  {
    label: 'Unreceived',
    value: 'unreceived',
  },
  {
    label: 'Success',
    value: 'success',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
];
