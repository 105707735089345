// disable eslint the whole file
// eslint-disable

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

import { Label } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import FileSaver from 'file-saver';
import moment from 'moment';
import { Button } from 'shared/components/button';
import { Checkbox, CheckboxField, CheckboxGroup } from 'shared/components/checkbox';
import { Dialog, DialogActions, DialogBody, DialogTitle } from 'shared/components/dialog';
import { Radio, RadioField, RadioGroup } from 'shared/components/radio';
import { QueryKey } from 'shared/constants/queryKeys';
import { Filter } from 'shared/firebase/model';
import { getAllShipments } from 'shared/firebase/query/shipments';
import convertShipmentQueryRequestToFilter, { ShipmentQueryRequest } from '../query-request-to-filter';

interface ShipmentExportModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  columns?: any[];
  filterRequest: ShipmentQueryRequest;
  columnVisibility?: { [key: string]: boolean };
  onPrepareExport?: () => void;
  onExportFinished?: () => void;
}

type DownloadAsOption = 'CSV' | 'Excel';

const DOWNLOAD_OPTIONS: DownloadAsOption[] = ['CSV', 'Excel'];

export const ShipmentExportModal = ({
  isOpen,
  setIsOpen,
  columns,
  filterRequest,
  columnVisibility,
  onPrepareExport,
  onExportFinished,
}: ShipmentExportModalProps) => {
  const orgId = useSelector((state: any) => state.auth.orgId);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<DownloadAsOption>('CSV');

  const { mutate: getAllShipmentsMutation, isPending } = useMutation({
    mutationKey: [QueryKey.shipments, QueryKey.download],
    mutationFn: ({
      params,
    }: {
      format: DownloadAsOption;
      selectedColumns: string[];
      params: Parameters<typeof getAllShipments>;
    }) => getAllShipments(...params),
    onSuccess(shipments, variables) {
      if (!shipments) {
        return;
      }

      const rows = shipments.map((shipment) => {
        const item: Record<string, string> = {};

        variables.selectedColumns.forEach((selectedColumn) => {
          const column = columns?.find((column) => column.key === selectedColumn);

          if (!column) {
            return;
          }

          item[column.header] = column.textValue(shipment);
        });

        return item;
      });

      if (rows.length === 0) {
        alert('No data to export');
        return;
      }

      const fileName = `shipments_` + moment().format('YYYY_MM_DD_HH-mm-ss');

      if (variables.format === 'CSV') {
        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const fileBuffer = new Blob([excelBuffer], {
          type: 'application/csv;charset=UTF-8',
        });
        FileSaver.saveAs(fileBuffer, fileName + '.csv');
      }

      if (variables.format === 'Excel') {
        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const fileBuffer = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        FileSaver.saveAs(fileBuffer, fileName + '.xlsx');
      }

      // if (variables.format === 'PDF') {
      //   const doc = new jsPDF({
      //     orientation: 'landscape',
      //   })

      //   // const widths = variables.selectedColumns.map((col) => {
      //   //   const column = columns?.find((column) => column.key === col)
      //   //   return column?.size ?? 40
      //   // })

      //   const headers = rows[0] && Object.keys(rows[0])

      //   autoTable(doc, {
      //     head: [headers],
      //     body: rows.map((row) => headers.map((header) => row[header])),
      //     columnStyles: variables.selectedColumns.reduce<Record<number, Partial<Styles>>>((acc, col, index) => {
      //       const column = columns?.find((column) => column.key === col)
      //       acc[index] = { cellWidth: column?.pdfCellWidth ?? 40, fontSize: 8 }
      //       return acc
      //     }, {}),
      //   })
      //   FileSaver.saveAs(doc.output('blob'), fileName + '.pdf')
      // }

      onExportFinished?.();
    },
  });

  const onDownload = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      const filters = convertShipmentQueryRequestToFilter(filterRequest) as Filter[];

      getAllShipmentsMutation({
        format: selectedFormat,
        selectedColumns: selectedColumns,
        params: [
          {
            orgId,
            filters,
            sort: {
              key: filterRequest.order_by ?? 'created_at',
              value: (filterRequest.order_direction as 'asc' | 'desc') ?? 'desc',
            },
          },
        ],
      });

      setIsOpen(false);
      onPrepareExport?.();
    },
    [filterRequest, orgId, getAllShipmentsMutation, selectedColumns, selectedFormat, setIsOpen, onPrepareExport]
  );
  // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  // const fileExtension = '.xlsx'

  // const exportToCSV = (d: any, containerId?: string) => {
  //   const rows: any = []
  //   d?.forEach((_d: any) => {
  //     _d?.data?.ocean_containers?.map((container: any) => {
  //       rows.push({
  //         Vessel: _d?.data?.vessel_name || '',
  //         Voyage: _d?.data?.vessel_voyage_number || '',
  //         'Departure date': timestampToLocaleString(_d?.data?.estimated_time_of_departure?.seconds) || '',
  //         'HBL#': _d?.data?.house_bill_of_lading_number || '',
  //         'AMS HBL': _d?.data?.automated_manifest_system_number || '',
  //         'Inner Piece Count': _d?.data?.total_packages?.value || '',
  //         'Inner Piece UOM': _d?.data?.total_packages?.unit || '',
  //         'Outer Piece Count': _d?.data?.total_pallets?.value || '',
  //         Volume: _d?.data?.total_volume?.value || '',
  //         Weight: _d?.data?.total_weight?.value || '',
  //         Incoterm: _d?.data?.incoterm || '',
  //         'Cargo description': container.items[0]?.item_description || '',
  //         'DG (Y/N)': '',
  //         POL: _d?.data?.port_of_loading?.unloc || '',
  //         POD: _d?.data?.port_of_discharge?.unloc || '',
  //         CFS: _d?.data?.place_of_delivery || '',
  //         'Delivery Location': '',
  //         Shipper: _d?.data?.shipper?.name || '',
  //         Consignee: _d?.data?.consignee?.name || '',
  //         Notify: _d?.data?.notify_party?.name || '',
  //         'Notification emails': _d?.data?.notify_party?.emails?.[0] || '',
  //         ETA: timestampToLocaleString(_d?.data?.estimated_time_of_arrival?.seconds) || '',
  //         Carrier: _d?.data?.ocean_carrier || '',
  //         'Cntr#': container?.container_number || '',
  //         'Carrier BL#': _d?.data?.master_bill_of_lading_number || '',
  //         'Equip. Size': container?.container_type || '',
  //         'Release Type': _d?.data?.house_bill_of_lading_release_type || '',
  //         'Original BOL': _d?.data?.number_of_origin_bill_of_lading || '',
  //         'IT Number': _d?.data?.cbp_entry_number || '',
  //       })
  //     })
  //   })

  //   const ws = XLSX.utils.json_to_sheet(rows)
  //   const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  //   const data = new Blob([excelBuffer], { type: fileType })
  //   FileSaver.saveAs(data, containerId + fileExtension)
  // }

  // const handleExport = async (data: ShipmentsExport) => {
  //   // const d = await filterShipmentsBySearchKey({
  //   //   orgId,
  //   //   exportKeyType: data.searchKey,
  //   //   exportKeyValue: data.searchValue,
  //   // })
  //   // exportToCSV(d, data.searchValue)
  // }

  useEffect(() => {
    if (columns) {
      setSelectedColumns(
        columns.filter((column) => columnVisibility?.[column.id] !== false).map((column) => column.key)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(columns), JSON.stringify(columnVisibility)]);

  return (
    orgId && (
      <Dialog open={isOpen} onClose={setIsOpen} size="xl" className="z-50 !p-0">
        <form onSubmit={onDownload}>
          <DialogTitle className="border-b px-5 py-3">
            <span className="text-lg text-gray-900">Export</span>
            <p className="text-xs text-gray-600">
              All matching results in the table will be downloaded, not limited to the current page
            </p>
          </DialogTitle>
          <DialogBody className="!mt-0 border-b text-sm/6 text-zinc-900 dark:text-white">
            <div className="overflow-x-auto p-5">
              <div>
                <p className="font-bold">Columns</p>
                <CheckboxGroup className="mt-4 grid grid-cols-3 gap-x-4 gap-y-2">
                  {columns?.map((column, index) => (
                    <CheckboxField className="gap-2" key={index}>
                      <Checkbox
                        value={column.key}
                        checked={selectedColumns.includes(column.key)}
                        onChange={(value) => {
                          setSelectedColumns((prev) =>
                            value ? [...prev, column.key] : prev.filter((key) => key !== column.key)
                          );
                        }}
                      />
                      <Label className="text-sm font-medium text-gray-900">{column.header}</Label>
                    </CheckboxField>
                  ))}
                </CheckboxGroup>
              </div>

              <div className="mt-8">
                <p className="font-bold">Download As</p>
                <RadioGroup
                  className="mt-4 flex gap-x-4"
                  value={selectedFormat}
                  onChange={(value) => setSelectedFormat(value as DownloadAsOption)}
                >
                  {DOWNLOAD_OPTIONS?.map((option, index) => (
                    <RadioField className="!mt-0 gap-2" key={index}>
                      <Radio value={option} />
                      <Label className="text-sm font-medium text-gray-900">{option}</Label>
                    </RadioField>
                  ))}
                </RadioGroup>
              </div>
            </div>
          </DialogBody>
          <DialogActions className="!mt-0 px-5 py-5">
            <Button plain onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button disabled={isPending} type="submit">
              Download
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  );
};
