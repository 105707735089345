import { Checkbox, Label } from 'flowbite-react';
import _ from 'lodash';
import React from 'react';
import { IFilterItem } from '.';

export default function FilterItemCheckBox({ data }: { data: IFilterItem }) {
  const { options, key, value, disabled, onChange, columns = 1 } = data;

  return (
    <div className={`grid grid-cols-${columns} `}>
      {options &&
        options.map((option, i) => (
          <div key={i} className="flex items-center">
            <Checkbox
              id={`${key}-${option.value}`}
              checked={data?.value?.includes(option.value)}
              name={`${key}-checkbox-${option.value}`}
              disabled={disabled}
              onChange={(e) => {
                if (e.target.checked) {
                  const newValue = _.uniq([...(data?.value || []), option.value]);
                  onChange(newValue);
                } else {
                  onChange(value?.filter((v: string) => v !== option.value));
                }
              }}
            />
            <Label disabled={disabled} htmlFor={`${key}-${option.value}`} className="ml-2">
              {option.label}
            </Label>
          </div>
        ))}
    </div>
  );
}
