import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import {
  Cell,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  Header,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { Label, Pagination, TextInput, theme } from 'flowbite-react';
import _ from 'lodash';
import React, { CSSProperties, Fragment, useEffect, useMemo, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import { LuChevronDown, LuChevronsUpDown, LuChevronUp } from 'react-icons/lu';
import Skeleton from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';

import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { DotsHorizontal } from 'shared/icons/DotsHorizontal';
import { Filter } from 'shared/icons/Filter';
import { ITask } from 'shared/reduxStore/slices/tasks/types';
import { Button } from './button';
import { DataTableConfigure } from './data-table-configure';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from './dropdown';
import EmptyContent from './empty-content';
import FilterDrawerV2, { IFilterItem } from './filters-drawer';
import Toaster from './toaster';

const PER_PAGE_LIST = [10, 25, 50, 100];

export interface DataTableProps {
  tableId: string;
  title?: string;
  className?: string;
  columns: any;
  data: any;
  setColumns: any;
  loading: boolean;
  totalPages: number;
  currentPage: number;
  buttonGroup?: React.ReactElement;
  setFilter?: any;
  applyFilter?: any;
  countText?: string;
  totalCount?: number;
  handleApply: (data: any) => void;
  hasSearch?: boolean;
  actions?: { icon: React.ReactNode; label: string; onClick: (task: ITask) => void }[];
  filterItems: IFilterItem[];
  totalFilterResults?: number;
  filterCount?: number;
  filterAlertChecker?: (filters: any) => boolean;
  resetFilter: () => void;
  activeFilters?: React.ReactNode;
  emptyContent?: { title: string; description: string; action?: React.ReactNode };
  filterAlert?: { color: 'info' | 'warning' | 'success' | 'error'; message?: string };
  activeRows?: string[];
  onRowClick?: () => void;
  extraColumnConfigurationChildren?: Record<string, React.ReactNode>;
  extraState?: Record<string, any>;
  onChangeColumnsVisibility?: (visibility: any) => void;
}

const DraggableTableHeader = ({ header }: { header: Header<unknown, unknown> }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id,
  });
  const size = header.column.getSize();
  const isLastColumn = header.index === header.headerGroup.headers.length - 1;
  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    zIndex: isDragging ? 1 : 0,
    width: size === Number.MAX_SAFE_INTEGER ? 'auto' : `${size}px`,
  };

  return (
    <th
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={style}
      className={twMerge([
        'truncate py-4 pl-4 pr-2 text-left text-sm font-semibold text-gray-500',
        header.column.getIsResizing() ? 'isResizing' : '',
        header.column.getCanSort() ? 'cursor-pointer' : '',
      ])}
      onClick={header.column.getToggleSortingHandler()}
    >
      <div className="flex items-center justify-between">
        <div className={clsx(`flex items-center gap-2 text-xs uppercase`)}>
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          {header.column.columnDef.enableSorting !== false
            ? ({
                asc: <LuChevronUp height={15} width={15} />,
                desc: <LuChevronDown height={15} width={15} />,
              }[header.column.getIsSorted() as string] ?? <LuChevronsUpDown height={15} width={15} />)
            : null}
        </div>
        <button {...attributes} {...listeners}>
          <EllipsisVerticalIcon height={15} width={15} />
        </button>
        {header.column.getCanResize() && !isLastColumn && (
          <div
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
            className={`resizer ${header.column.getIsResizing() ? 'isResizing z-[10000] !w-full' : ''}`}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </div>
    </th>
  );
};

const CellSkelton = (
  <td className="truncate whitespace-nowrap p-2 text-sm text-gray-500">
    <Skeleton className="h-[25px]" direction="ltr" />
  </td>
);

const DragAlongCell = ({
  cell,
  extraState,
}: {
  cell: Cell<unknown, unknown>;
  extraState?: Record<string, any>;
  loading: boolean;
}) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    // width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    maxWidth: cell.column.getSize(),
    ...(cell.column.columnDef.meta as any)?.cellStyle,
  };
  return (
    <td
      style={style}
      ref={setNodeRef}
      className={twMerge([
        'truncate whitespace-nowrap p-3 text-sm text-gray-900',
        (cell.column.columnDef.meta as any)?.cellClassName || '',
      ])}
    >
      {flexRender(cell.column.columnDef.cell, {
        ...cell.getContext(),
        ...extraState,
      })}
    </td>
  );
};

const DataTableV2 = ({
  tableId,
  title,
  className,
  columns,
  data,
  setColumns,
  totalPages,
  loading,
  setFilter,
  buttonGroup,
  applyFilter,
  totalCount,
  handleApply,
  hasSearch,
  actions,
  filterItems,
  currentPage,
  totalFilterResults,
  filterCount,
  filterAlertChecker,
  resetFilter,
  activeFilters,
  emptyContent,
  filterAlert,
  activeRows,
  extraState,
  extraColumnConfigurationChildren,
  onChangeColumnsVisibility,
}: DataTableProps) => {
  const [showToasterSortDisabled, setShowToasterSortDisabled] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    { id: applyFilter.order_by || '', desc: applyFilter.order_direction === 'desc' },
  ]);
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [selectedFilters, setSelectedFilter] = useState<any>(applyFilter);
  const [search, setSearch] = useState('');

  const defaultSettings = {
    columnVisibility: columns.reduce((acc: any, column: any) => {
      acc[column.id] = column.initialVisibility;
      return acc;
    }, {} as any),
    columnOrder: [...columns].map((c: any) => c.id!),
    columnSizing: {},
  };
  const STORAGE_KEY = `table-${tableId}`;
  const { state, update } = useLocalStorage(STORAGE_KEY, defaultSettings);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      update(
        'columnOrder',
        arrayMove(
          state.columnOrder,
          state.columnOrder.indexOf(active.id as string),
          state.columnOrder.indexOf(over.id as string)
        )
      );
    }
  }

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const changeColumnVisibility = (visibility: any) => {
    onChangeColumnsVisibility && onChangeColumnsVisibility(visibility);
    update('columnVisibility', visibility);
  };

  const table = useReactTable({
    columns: [...columns],
    data,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    rowCount: data.length,
    enableSortingRemoval: false,
    manualSorting: true,
    state: {
      columnVisibility: state.columnVisibility,
      columnOrder: state.columnOrder,
      sorting,
      columnSizing: state.columnSizing,
    },
    pageCount: totalPages,
    defaultColumn: { minSize: 60 },
    enableSorting: true,
    onColumnOrderChange: (newOrder) => {
      update('columnOrder', newOrder);
    },
    onColumnSizingChange: (sizingUpdater) => {
      const newSizing = functionalUpdate(sizingUpdater, state.columnSizing);
      update('columnSizing', newSizing);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: (sortingUpdater) => {
      const newSortVal = functionalUpdate(sortingUpdater, sorting);
      setSorting(newSortVal);
      handleApply({
        ...applyFilter,
        page: 1,
        order_by: newSortVal[0].id,
        order_direction: newSortVal[0].desc ? 'desc' : 'asc',
      });
    },
    onColumnVisibilityChange: changeColumnVisibility,
  });

  const availableCols = table?.getAllLeafColumns()?.filter((c) => c.getIsVisible());

  const rows = table.getRowModel().rows;

  const normalizedFilterItems = useMemo(() => {
    return filterItems.map((item) => ({
      ...item,
      value: item.valueComposer ? item.valueComposer(selectedFilters) : selectedFilters[item.key],
      onChange: (value: any) => {
        if (item.onChangeComposer) {
          item.onChangeComposer(setSelectedFilter)(value);
        } else {
          setSelectedFilter((prev: any) => ({
            ...prev,
            [item.key]: value,
          }));
        }
      },
      disabled: item.disabledChecker ? item.disabledChecker(selectedFilters) : false,
    }));
  }, [filterItems, selectedFilters]);

  const getInitialState = () => {
    const filterState: any = { ...applyFilter };
    // eslint-disable-next-line array-callback-return
    columns?.map((col: any) => {
      if (col?.filter && !filterState?.[col.id]) {
        filterState[col.filter.accessor] = '';
      }
    });
    return filterState;
  };

  // debounce search
  const debouncedSearch = _.debounce((value) => {
    handleApply({
      ...applyFilter,
      search: value,
      page: 1,
    });
  }, 500);

  const startIndex = applyFilter.page_size * (currentPage - 1) + 1;

  useEffect(() => {
    setSelectedFilter(applyFilter);
  }, [applyFilter, isFilterDrawerOpen]);

  useEffect(() => {
    if (columns && applyFilter) {
      const filterState = getInitialState();
      setFilter((prev: any) => {
        return {
          ...prev,
          ...filterState,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    if (columns && state.columnOrder && state.columnVisibility) {
      const columnOrder = state.columnOrder || [];
      const columnVisibility = state.columnVisibility || {};
      let orderUpdated = false,
        visibilityUpdated = false;
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        if (column.id) {
          if (!columnOrder?.find((id: string) => id === column.id)) {
            columnOrder.push(column.id);
            orderUpdated = true;
          }
          if (!columnVisibility.hasOwnProperty(column.id)) {
            columnVisibility[column.id] = column.initialVisibility !== false;
            visibilityUpdated = true;
          }
        }
      }
      if (orderUpdated) {
        update('columnOrder', columnOrder);
      }
      if (visibilityUpdated) {
        changeColumnVisibility(columnVisibility);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, state.columnOrder, state.columnVisibility]);

  return (
    <div className={twMerge(['mx-auto', className])}>
      <div className="relative overflow-hidden border border-gray-200 bg-white sm:rounded-lg dark:bg-gray-800">
        <div className="mx-4 border-b dark:border-gray-700">
          <div className="flex items-center justify-between space-x-4 py-3">
            <div className="flex flex-1 items-center space-x-3">
              {title && <h5 className="text-base font-semibold text-gray-900 dark:text-white">{title}</h5>}
            </div>
            <div className="relative flex shrink-0 flex-row items-center justify-end space-x-3">
              {hasSearch && (
                <div className="w-full flex-1 md:max-w-sm">
                  <Label
                    htmlFor="default-search"
                    className="text-primary-900 sr-only text-sm font-medium dark:text-white"
                  >
                    Search
                  </Label>
                  <div className="relative">
                    <TextInput
                      icon={() => <HiSearch className="h-4 w-4 text-gray-500 dark:text-gray-400" />}
                      id="default-search"
                      name="default-search"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        debouncedSearch(e.target.value);
                      }}
                      type="search"
                      className="[&_input]:py-2"
                    />
                  </div>
                </div>
              )}
              <div className="flex items-center gap-3">
                {buttonGroup ? buttonGroup : null}
                {applyFilter ? (
                  <Button color="white" className="!px-4" onClick={() => setFilterDrawerOpen(true)}>
                    <Filter />
                    {!loading && filterCount ? (
                      <span className="absolute right-[2px] top-[2px] flex h-[16px] w-[16px] items-center justify-center rounded-[50%] bg-zinc-950 text-[12px] text-white">
                        {filterCount}
                      </span>
                    ) : null}
                    Filters
                  </Button>
                ) : null}
                <DataTableConfigure
                  table={table}
                  columnVisibility={state.columnVisibility}
                  setColumnVisibility={(visibility) => update('columnVisibility', visibility)}
                  columnOrder={state.columnOrder}
                  setColumnOrder={(order) => update('columnOrder', order)}
                  extraChildren={extraColumnConfigurationChildren}
                  onReset={() => {
                    update('columnVisibility', defaultSettings.columnVisibility);
                    update('columnOrder', defaultSettings.columnOrder);
                    update('columnSizing', defaultSettings.columnSizing);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {activeFilters}
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToHorizontalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <div className="overflow-hidden sm:-mx-6 lg:-mx-6">
            <div className="flow-root min-w-full pb-2 pt-3 align-middle sm:px-6 lg:px-6">
              <div className="!overflow-x-auto shadow ring-1 ring-black ring-opacity-5">
                <table className="w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={`hgroup_${headerGroup.id}`}>
                        <SortableContext items={state.columnOrder} strategy={horizontalListSortingStrategy}>
                          {headerGroup.headers.map((header, index) => (
                            <DraggableTableHeader
                              key={`header_${headerGroup.id}_${header.id}_${index}`}
                              header={header}
                            />
                          ))}
                          {actions && actions.length > 0 && <th />}
                        </SortableContext>
                      </tr>
                    ))}
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {!loading && rows?.length > 0 ? (
                      rows.map((row, index) => {
                        return (
                          <tr
                            key={`row_${row.id}_${index}`}
                            className={twMerge([
                              'cursor-pointer hover:bg-gray-100',
                              (row.original as any)?.is_active === false ? 'bg-indigo-100' : '',
                              activeRows?.includes((row.original as any).id) ? 'bg-indigo-100' : '',
                            ])}
                          >
                            {row.getVisibleCells().map((cell, index) => {
                              return (
                                <SortableContext
                                  key={`cell_${cell.id}_${index}`}
                                  items={state.columnOrder}
                                  strategy={horizontalListSortingStrategy}
                                >
                                  <DragAlongCell
                                    key={`cell_content_${cell.id}`}
                                    cell={cell}
                                    loading={loading}
                                    extraState={extraState}
                                  />
                                </SortableContext>
                              );
                            })}
                            {actions && actions.length > 0 && (
                              <td className="p-3 text-sm text-gray-500">
                                <div className="flex items-center justify-center space-x-2">
                                  <Dropdown>
                                    <DropdownButton plain className="h-6">
                                      <DotsHorizontal className="text-gray-500" />
                                    </DropdownButton>
                                    <DropdownMenu>
                                      {actions.map((action, index) => {
                                        return (
                                          <DropdownItem
                                            key={index}
                                            onClick={() => action.onClick(row.original as any)}
                                            className="cursor-pointer"
                                          >
                                            {action.icon}
                                            <span className="ml-2">{action.label}</span>
                                          </DropdownItem>
                                        );
                                      })}
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : !loading ? (
                      <tr>
                        <td
                          colSpan={table.getHeaderGroups()[0]?.headers?.length || 1}
                          className="p-3 text-sm text-gray-500"
                        >
                          <EmptyContent
                            title={emptyContent?.title || 'No Data Available'}
                            description={emptyContent?.description || ''}
                            action={emptyContent?.action}
                            className="py-8"
                          />
                        </td>
                      </tr>
                    ) : (
                      [...Array(applyFilter.page_size)].map((_, index) => {
                        return (
                          <tr key={`skelton_${index}`} className="cursor-pointer hover:bg-[#f1f5f9]">
                            {availableCols.map((i, si) => (
                              <Fragment key={`skelton_c_${si}`}>{CellSkelton}</Fragment>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-between space-y-3 p-3 md:flex-row md:items-center md:space-y-0">
            <div className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
              <span className="mr-2">Rows per page</span>
              <Dropdown>
                <DropdownButton
                  className="BORDER-2 cursor-pointer rounded-r-md border-[#e5e7eb] shadow-sm ring-0 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
                  outline
                >
                  <span className="font-normal text-gray-900">{applyFilter.page_size}</span>
                  <ChevronDownIcon />
                </DropdownButton>
                <DropdownMenu>
                  {PER_PAGE_LIST?.map((pc) => {
                    return (
                      <DropdownItem
                        className={`cursor-pointer ${
                          pc === applyFilter.page_size ? 'bg-zinc-950 !text-white' : ''
                        } hover:!bg-zinc-950 hover:!text-white`}
                        onClick={(e: any) => {
                          handleApply({
                            ...applyFilter,
                            page_size: pc,
                            page: 1,
                          });
                        }}
                        key={pc}
                      >
                        {pc}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <span className="ml-2 text-gray-900 dark:text-white">
                {startIndex} -{' '}
                {startIndex + applyFilter.page_size - 1 > (totalCount || 0)
                  ? totalCount
                  : startIndex + applyFilter.page_size - 1}
              </span>
              &nbsp;of&nbsp;
              <span className="text-gray-900 dark:text-white">{totalCount}</span>
            </div>
            <Pagination
              className="pagination"
              currentPage={currentPage}
              nextLabel=""
              onPageChange={(page) => {
                if (page !== currentPage) {
                  handleApply({
                    ...applyFilter,
                    page,
                  });
                }
              }}
              previousLabel=""
              showIcons
              totalPages={totalPages}
              layout="pagination"
              theme={{
                pages: {
                  base: twMerge(theme.pagination.pages.base, 'mt-0'),
                  next: {
                    base: twMerge(theme.pagination.pages.next.base, 'w-10 px-1.5 py-1.5'),
                    icon: 'h-6 w-6',
                  },
                  previous: {
                    base: twMerge(theme.pagination.pages.previous.base, 'w-10 px-1.5 py-1.5'),
                    icon: 'h-6 w-6',
                  },
                  selector: {
                    base: twMerge(theme.pagination.pages.selector.base, 'w-9 py-2 text-sm focus:border-gray-300'),
                  },
                },
              }}
            />
          </div>
        </DndContext>
        {applyFilter ? (
          <FilterDrawerV2
            open={isFilterDrawerOpen}
            filters={selectedFilters}
            setOpen={setFilterDrawerOpen}
            handleApply={handleApply}
            resetHandler={resetFilter}
            items={normalizedFilterItems}
            totalResults={totalFilterResults || 0}
            filterCount={filterCount || 0}
            alert={!filterAlertChecker || filterAlertChecker(selectedFilters) ? filterAlert : undefined}
          />
        ) : null}
        <Toaster
          show={showToasterSortDisabled}
          setShow={setShowToasterSortDisabled}
          variant={'error'}
          title={'Sorting is disabled on active filters!'}
        />
      </div>
    </div>
  );
};

export default DataTableV2;
