import { Modal } from 'flowbite-react'
import React from 'react'

function formatString(value: string) {
  return value
    .split('_') // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' ') // Join the words with a space
}

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button
    onClick={onClick}
    className="absolute right-5 top-[18px] ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
  >
    <svg aria-hidden fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="h-5 w-5">
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
    <span className="sr-only">Close modal</span>
  </button>
)

export default function CorrectValueModal({
  showModal,
  onClose,
  fieldName,
  customValue,
  setCustomValue,
  onSubmit,
}: {
  showModal: boolean
  onClose: () => void
  fieldName: string | null
  customValue: string
  setCustomValue: (value: string) => void
  onSubmit: () => void
}) {
  return (
    <Modal onClose={onClose} show={showModal} size="xs" position="center" className="custom-modal">
      <Modal.Body className="relative rounded-lg bg-white p-4 shadow sm:p-5 dark:bg-gray-800">
        <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 sm:mb-5 dark:border-gray-600">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Enter Correct Value</h3>
          <CloseButton onClick={onClose} />
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-2 text-gray-800">{fieldName ? formatString(fieldName) : 'Field'}</p>
          <input
            type="text"
            value={customValue}
            onChange={(e) => setCustomValue(e.target.value)}
            placeholder="Enter value"
            className="w-full rounded-lg border border-gray-300 p-2 text-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-end">
        <button
          onClick={onSubmit}
          disabled={customValue.trim() === ''} // Disable button if input is empty
          className={`rounded-lg border border-gray-200 px-4 py-2 text-white ${
            customValue.trim() === '' ? 'cursor-not-allowed bg-gray-300' : 'bg-black hover:bg-gray-700'
          }`}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  )
}
