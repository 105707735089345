import { Accordion } from 'flowbite-react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { IFilterItem } from '.';
import { Badge } from '../badge';
import FilterItemCheckBox from './item-checkbox';
import FilterItemDateDropDown from './item-date-dropdown';
import FilterItemDateRange from './item-date-range';
import FilterItemDropdown from './item-dropdown';
import FilterItemRadioCheckbox from './item-radio-checkbox';
import FilterItemSearchInput from './item-search-input';

export default function FiltersDrawerItem({ data }: { data: IFilterItem }) {
  return (
    <Accordion flush>
      <Accordion.Panel>
        <Accordion.Title className={twMerge(['!bg-transparent px-4 py-3 text-sm', data.disabled ? 'opacity-50' : ''])}>
          <div className="flex w-full items-center gap-2 text-gray-900">
            {data.title}
            {data.isActive ? (
              <Badge color="zinc-950" className="mr-5">
                Active
              </Badge>
            ) : null}
          </div>
        </Accordion.Title>
        <Accordion.Content className="space-y-2 border-none px-4 pt-0">
          {data.type === 'checkbox' && <FilterItemCheckBox data={data} />}
          {data.type === 'inputSearch' && <FilterItemSearchInput data={data} />}
          {data.type === 'dateRange' && <FilterItemDateRange data={data} />}
          {data.type === 'radio' && <FilterItemRadioCheckbox data={data} />}
          {data.type === 'custom' && data.component && data.component}
          {data.type === 'dateDropdown' && <FilterItemDateDropDown data={data} />}
          {data.type === 'dropdown' && (
            <div className="flex w-full items-center gap-2">
              <FilterItemDropdown data={data} />
            </div>
          )}
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
}
