import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import moment from 'moment';
import React from 'react';

import { InfoIcon } from 'shared/icons/Info';

type TPODCell = {
  pod?: string;
  eta?: string;
  ata?: string;
};

export default function PODCell({ data }: { data: TPODCell }) {
  let pod = data?.pod ?? '--';

  if (!data) {
    return <></>;
  }

  return (
    <div className="flex">
      <span className="truncate">{pod}</span>
      <Popover as="div" className="relative flex">
        <PopoverButton type="button" className="!px-1 !outline-none">
          <InfoIcon className="text-[#9CA3AF]" />
        </PopoverButton>
        <PopoverPanel
          anchor={{ gap: 4, to: 'right start', offset: -18 }}
          className="!max-w-64 data-[closed]:-translate-x-1 data-[closed]:opacity-0"
        >
          <div className="flex">
            <div className="mt-4 h-0 w-0 border-[10px] border-l-0 border-transparent border-r-[#21283C]"></div>
            <div className="rounded bg-[#21283C] px-3 py-2 text-white">
              <p className="text-xs">
                <span className="font-bold">ETA: </span>
                <span className="text-gray-300">
                  {data.eta ? moment(data.eta).format('MM/DD/YYYY HH:mm:ss') : '--'}
                </span>
              </p>
              <p className="text-xs">
                <span className="font-bold">ATA: </span>
                <span className="text-gray-300">
                  {data.ata ? moment(data.ata).format('MM/DD/YYYY HH:mm:ss') : '--'}
                </span>
              </p>
            </div>
          </div>
        </PopoverPanel>
      </Popover>
    </div>
  );
}
