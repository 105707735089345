export const shipmentSortingColIdMap = {
  shipmentId: 'client_shipment_id',
  shipping_mode: 'data.load_type',
  shipper: 'data.shipper.name',
  consignee: 'data.consignee.name',
  notify_party: 'data.notify_party.name',
  origin_agent: 'data.origin_agent.name',
  destination_agent: 'data.destination_agent.name',
  pol: 'data.port_of_loading.unloc',
  etd: 'data.estimated_time_of_departure',
  atd: 'data.actual_time_of_departure',
  pod: 'data.port_of_discharge.unloc',
  eta: 'data.estimated_time_of_arrival',
  ata: 'data.actual_time_of_arrival',
  place_of_receipt: 'data.place_of_receipt',
  place_of_delivery: 'data.place_of_delivery',
  vessel_name: 'data.vessel_name',
  voyage: 'data.vessel_voyage_number',
  total_weight: 'data.total_weight',
  total_volume: 'data.total_volume',
  total_packages: 'data.total_packages',
  validationStatus: 'validation_result.status_code',
};

export const columnIdNameMapper = {
  shipmentId: 'Client shipment #',
  shipping_mode: 'Mode',
  pol: 'POL',
  etd: 'ETD',
  pod: 'POD',
  eta: 'ETA',
  validationStatus: 'Validation status',
  mbl: 'MBL',
  carrier: 'Carrier AN',
  ci: 'CI',
  pl: 'PL',
  hbl: 'HBL',
  isf: 'ISF',
  prealert: 'Pre alert',
  ams: 'AMS',
};

export const documentSortingColIdMap = {
  name: 'metadata.received_email_doc_filename',
  documents: 'type',
  processing_status: 'processing_result.status_code',
  timestamp: 'created_at',
  // client_shipment_id: 'client_shipment_id',
};
