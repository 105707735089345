import { EShipmentRouteVariant } from 'models/ShipmentDetails/enums';
import React from 'react';

import CustomsIcon from 'shared/icons/CustomsIcon';
import ExclamationOutLineIcon from 'shared/icons/ExclamationOutLineIcon';
import { cn } from 'shared/utils';

type CustomsBlockProps = {
  variant: EShipmentRouteVariant;
};

const CustomsBlock = ({ variant }: CustomsBlockProps) => {
  const isActive = variant === EShipmentRouteVariant.active;
  const isInActive = variant === EShipmentRouteVariant.inactive;

  return (
    <div className="flex items-center gap-1">
      <CustomsIcon
        className={cn('[&_*]:fill-current', {
          'text-teal-600 [&_*]:fill-current': isActive,
          'text-gray-400': isInActive,
        })}
      />
      <div className="flex items-center gap-[2px]">
        <span
          className={cn('text-[10px] text-black', {
            'font-medium': isActive,
            'text-gray-400': isInActive,
          })}
        >
          Customs
        </span>
        <ExclamationOutLineIcon />
      </div>
    </div>
  );
};

export default CustomsBlock;
