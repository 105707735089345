import React from 'react';
import { cn } from 'shared/utils';

type Props = {
  className?: string;
  variant?: 'default' | 'active';
};

const TruckIcon = ({ variant = 'default', className, ...restProps }: Props) => {
  const classes = cn(variant === 'active' && 'fill-indio-600', className);

  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      {...restProps}
    >
      <path
        d="M4.16667 13.3333C3.47222 13.3333 2.88194 13.0903 2.39583 12.6042C1.90972 12.1181 1.66667 11.5278 1.66667 10.8333H0V1.66667C0 1.20833 0.163194 0.815972 0.489583 0.489583C0.815972 0.163194 1.20833 0 1.66667 0H13.3333V3.33333H15.8333L18.3333 6.66667V10.8333H16.6667C16.6667 11.5278 16.4236 12.1181 15.9375 12.6042C15.4514 13.0903 14.8611 13.3333 14.1667 13.3333C13.4722 13.3333 12.8819 13.0903 12.3958 12.6042C11.9097 12.1181 11.6667 11.5278 11.6667 10.8333H6.66667C6.66667 11.5278 6.42361 12.1181 5.9375 12.6042C5.45139 13.0903 4.86111 13.3333 4.16667 13.3333ZM4.16667 11.6667C4.40278 11.6667 4.6007 11.5868 4.76042 11.4271C4.92014 11.2674 5 11.0694 5 10.8333C5 10.5972 4.92014 10.3993 4.76042 10.2396C4.6007 10.0799 4.40278 10 4.16667 10C3.93056 10 3.73264 10.0799 3.57292 10.2396C3.41319 10.3993 3.33333 10.5972 3.33333 10.8333C3.33333 11.0694 3.41319 11.2674 3.57292 11.4271C3.73264 11.5868 3.93056 11.6667 4.16667 11.6667ZM1.66667 9.16667H2.33333C2.56944 8.91667 2.84028 8.71528 3.14583 8.5625C3.45139 8.40972 3.79167 8.33333 4.16667 8.33333C4.54167 8.33333 4.88195 8.40972 5.1875 8.5625C5.49306 8.71528 5.76389 8.91667 6 9.16667H11.6667V1.66667H1.66667V9.16667ZM14.1667 11.6667C14.4028 11.6667 14.6007 11.5868 14.7604 11.4271C14.9201 11.2674 15 11.0694 15 10.8333C15 10.5972 14.9201 10.3993 14.7604 10.2396C14.6007 10.0799 14.4028 10 14.1667 10C13.9306 10 13.7326 10.0799 13.5729 10.2396C13.4132 10.3993 13.3333 10.5972 13.3333 10.8333C13.3333 11.0694 13.4132 11.2674 13.5729 11.4271C13.7326 11.5868 13.9306 11.6667 14.1667 11.6667ZM13.3333 7.5H16.875L15 5H13.3333V7.5Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default TruckIcon;
