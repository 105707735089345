export enum NodeKey {
  shipper = 'shipper',
  placeOfReceipt = 'place_of_receipt',
  portOfLoading = 'port_of_loading',
  portOfDischarge = 'port_of_discharge',
  placeOfDelivery = 'place_of_delivery',
  consignee = 'consignee',
}

export enum EShipmentRouteHandleId {
  left = 'left',
  right = 'right',
  bottom = 'bottom',
  top = 'top',

  top1_6 = 'top.1.6',
  top2_6 = 'top.2.6',
  top4_6 = 'top.4.6',
  top5_6 = 'top.5.6',

  bottom1_6 = 'bottom.1.6',
  bottom2_6 = 'bottom.2.6',
  bottom4_6 = 'bottom.4.6',
  bottom5_6 = 'bottom.5.6',

  left1_6 = 'left.1.6',
  left2_6 = 'left.2.6',
  left4_6 = 'left.4.6',
  left5_6 = 'left.5.6',

  right1_6 = 'right.1.6',
  right2_6 = 'right.2.6',
  right4_6 = 'right.4.6',
  right5_6 = 'right.5.6',
}

export enum EShipmentRouteNode {
  placeOfReceipt = 'placeOfReceipt',
  portOfLoading = 'portOfLoading',
  portOfDischarge = 'portOfDischarge',
  placeOfDelivery = 'placeOfDelivery',
  shipper = 'shipper',
  consignee = 'consignee',
}

export enum EShipmentRouteVariant {
  active = 'active',
  inactive = 'inactive',
  default = 'default',
}
