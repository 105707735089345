import React from 'react';

type Props = {
  className?: string;
  variant?: 'default' | 'active' | 'inactive';
};

const ContainerIcon = ({ variant = 'default', ...restProps }: Props) => {
  if (variant === 'active') {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
        <mask
          id="mask0_55484_7985"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_55484_7985)"></g>
        <g clipPath="url(#clip0_55484_7985)">
          <path d="M9.10147 12.6459L3.29401 9.29032L3.28223 5.42773L9.08969 8.78328L9.10147 12.6459Z" fill="#5850EC" />
          <path
            d="M9.10122 12.6457L3.29454 9.29005L3.28223 5.42773L4.1533 5.93091C3.92057 6.36044 3.79185 6.83556 3.79185 7.33522C3.79185 9.27077 5.72274 10.8399 8.10324 10.8399C8.44523 10.8399 8.7768 10.8083 9.09597 10.7452L9.10122 12.6457Z"
            fill="#5850EC"
          />
          <path
            d="M9.09987 12.7049C9.08967 12.7049 9.07947 12.7024 9.07043 12.697L3.2629 9.34147C3.2448 9.33099 3.2336 9.31167 3.23346 9.2907L3.22168 5.42818C3.22168 5.40707 3.23288 5.38761 3.25112 5.37706C3.26922 5.36657 3.29191 5.36643 3.31 5.37706L9.11753 8.73263C9.13563 8.74311 9.14683 8.76243 9.14697 8.78339L9.15875 12.6459C9.15875 12.667 9.14755 12.6865 9.12931 12.697C9.12026 12.7023 9.11006 12.7049 9.09987 12.7049ZM3.35107 9.25652L9.0407 12.544L9.02935 8.81757L3.33973 5.53008L3.35107 9.25652Z"
            fill="black"
          />
          <path
            d="M4.14804 8.76054C4.11558 8.76054 4.0893 8.73433 4.08915 8.70195L4.08398 7.0102C4.08398 6.97767 4.11027 6.95124 4.14272 6.95117C4.17518 6.95117 4.2016 6.97738 4.20175 7.00977L4.20692 8.70152C4.20692 8.73404 4.18049 8.76047 4.14804 8.76054Z"
            fill="black"
          />
          <path
            d="M4.96737 9.23508C4.93491 9.23508 4.90863 9.20887 4.90849 9.17649L4.90332 7.48481C4.90332 7.45228 4.9296 7.42585 4.96206 7.42578C4.99451 7.42578 5.02094 7.45199 5.02108 7.48438L5.02625 9.17605C5.02625 9.20858 4.99983 9.23501 4.96737 9.23508Z"
            fill="black"
          />
          <path
            d="M5.78573 9.70585C5.75327 9.70585 5.72699 9.67964 5.72685 9.64726L5.72168 7.95551C5.72168 7.92298 5.74796 7.89656 5.78042 7.89648C5.81287 7.89648 5.8393 7.92269 5.83944 7.95508L5.84461 9.64683C5.84461 9.67936 5.81819 9.70578 5.78573 9.70585Z"
            fill="black"
          />
          <path
            d="M6.60311 10.1746C6.57066 10.1746 6.54438 10.1484 6.54423 10.116L6.53906 8.42426C6.53906 8.39173 6.56534 8.36531 6.5978 8.36523C6.63026 8.36523 6.65668 8.39144 6.65682 8.42383L6.66199 10.1156C6.66199 10.1481 6.63557 10.1745 6.60311 10.1746Z"
            fill="black"
          />
          <path
            d="M7.42147 10.6491C7.38902 10.6491 7.36274 10.6229 7.36259 10.5905L7.35742 8.89887C7.35742 8.86634 7.3837 8.83992 7.41616 8.83984C7.44862 8.83984 7.47504 8.86605 7.47518 8.89844L7.48035 10.5901C7.48035 10.6226 7.45393 10.6491 7.42147 10.6491Z"
            fill="black"
          />
          <path
            d="M8.23886 11.1238C8.2064 11.1238 8.18012 11.0976 8.17997 11.0652L8.1748 9.37348C8.1748 9.34095 8.20109 9.31452 8.23354 9.31445C8.266 9.31445 8.29242 9.34066 8.29257 9.37305L8.29774 11.0648C8.29774 11.0973 8.27131 11.1238 8.23886 11.1238Z"
            fill="black"
          />
          <path d="M9.08773 8.78307L3.28027 5.42752L6.89671 3.35156L12.7042 6.70711L9.08773 8.78307Z" fill="white" />
          <path
            d="M12.7055 6.70821L9.08891 8.784L3.28223 5.42836L5.06149 4.40625C5.02438 4.5465 5.00501 4.69027 5.00501 4.83579C5.00501 6.2962 6.90059 7.47961 9.237 7.47961C10.5208 7.47961 11.6704 7.12196 12.4463 6.55743L12.7055 6.70821Z"
            fill="#E9EDF4"
          />
          <path d="M12.7043 6.70508L12.7161 10.5677L9.09967 12.6436L9.08789 8.78104L12.7043 6.70508Z" fill="white" />
          <path
            d="M12.7176 10.5674L9.10096 12.6432L9.0957 11.1126C9.34427 11.1968 9.6123 11.2424 9.89092 11.2424C11.2593 11.2424 12.3666 10.1396 12.3666 8.78087C12.3666 8.18828 12.1568 7.64479 11.806 7.22052L12.7052 6.70508L12.7176 10.5674Z"
            fill="#E9EDF4"
          />
          <path
            d="M9.08809 8.84218C9.0779 8.84218 9.0677 8.83959 9.05865 8.83428L3.25112 5.47871C3.23288 5.46823 3.22168 5.44877 3.22168 5.42773C3.22182 5.40669 3.23303 5.38723 3.25126 5.37682L6.8677 3.3009C6.88594 3.29028 6.90849 3.29035 6.92644 3.30098L12.734 6.65647C12.7522 6.66696 12.7634 6.68642 12.7634 6.70745C12.7633 6.72849 12.7521 6.74795 12.7338 6.75837L9.11739 8.83435C9.10834 8.83959 9.09814 8.84218 9.08809 8.84218ZM3.39847 5.42794L9.08824 8.71551L12.5866 6.70724L6.89686 3.41974L3.39847 5.42794Z"
            fill="black"
          />
          <path
            d="M9.10191 12.7028C9.09171 12.7028 9.08166 12.7002 9.07247 12.6949C9.05437 12.6845 9.04317 12.6651 9.04303 12.6442L9.03125 8.78151C9.03125 8.7604 9.04245 8.74087 9.06083 8.73039L12.6773 6.6544C12.6954 6.64377 12.7176 6.64392 12.736 6.6544C12.7541 6.66488 12.7653 6.68427 12.7655 6.70517L12.7772 10.5678C12.7772 10.5889 12.766 10.6085 12.7476 10.619L9.1312 12.6949C9.12216 12.7002 9.11196 12.7028 9.10191 12.7028ZM9.14901 8.81533L9.16036 12.5426L12.6595 10.534L12.6481 6.80677L9.14901 8.81533Z"
            fill="black"
          />
          <path
            d="M11.5834 11.2821C11.5509 11.2821 11.5246 11.2559 11.5245 11.2234L11.5127 7.36176C11.5127 7.32923 11.539 7.30281 11.5714 7.30273C11.6039 7.30273 11.6303 7.32894 11.6305 7.3614L11.6422 11.2231C11.6422 11.2556 11.6158 11.282 11.5834 11.2821Z"
            fill="black"
          />
          <path
            d="M10.2357 12.0517C10.2032 12.0517 10.177 12.0255 10.1768 11.993L10.165 8.13129C10.165 8.09876 10.1913 8.07234 10.2238 8.07227C10.2562 8.07227 10.2827 8.09847 10.2828 8.13093L10.2946 11.9927C10.2946 12.0252 10.2682 12.0516 10.2357 12.0517Z"
            fill="black"
          />
          <path
            d="M11.3567 8.16624C11.3363 8.16624 11.3165 8.15569 11.3057 8.13673C11.2895 8.10851 11.2992 8.07261 11.3274 8.05645L11.7644 7.80563C11.7924 7.78962 11.8286 7.79903 11.8447 7.82732C11.8609 7.85554 11.8511 7.89144 11.823 7.9076L11.386 8.15842C11.3768 8.16373 11.3667 8.16624 11.3567 8.16624Z"
            fill="black"
          />
          <path
            d="M11.3567 8.82252C11.3363 8.82252 11.3166 8.81196 11.3057 8.79301C11.2895 8.76486 11.2992 8.72888 11.3274 8.71273L11.7644 8.46184C11.7924 8.4459 11.8284 8.4553 11.8447 8.48352C11.8609 8.51167 11.8511 8.54765 11.823 8.5638L11.386 8.81469C11.3768 8.82001 11.3667 8.82252 11.3567 8.82252Z"
            fill="black"
          />
          <path
            d="M11.3567 9.47482C11.3363 9.47482 11.3165 9.46427 11.3057 9.44531C11.2895 9.41709 11.2992 9.38119 11.3274 9.36503L11.7644 9.11421C11.7924 9.0982 11.8286 9.10768 11.8447 9.1359C11.8609 9.16412 11.8511 9.20002 11.823 9.21618L11.386 9.467C11.3768 9.47231 11.3667 9.47482 11.3567 9.47482Z"
            fill="black"
          />
          <path
            d="M11.3567 10.1252C11.3363 10.1252 11.3165 10.1147 11.3057 10.0957C11.2895 10.0675 11.2992 10.0316 11.3274 10.0154L11.7644 9.7646C11.7924 9.74859 11.8286 9.75807 11.8447 9.78629C11.8609 9.81451 11.8511 9.85041 11.823 9.86657L11.386 10.1174C11.3768 10.1227 11.3667 10.1252 11.3567 10.1252Z"
            fill="black"
          />
          <path
            d="M11.3567 10.7776C11.3363 10.7776 11.3165 10.767 11.3057 10.748C11.2895 10.7198 11.2992 10.6839 11.3274 10.6678L11.7644 10.4169C11.7924 10.4009 11.8286 10.4104 11.8447 10.4386C11.8609 10.4669 11.8511 10.5028 11.823 10.5189L11.386 10.7697C11.3768 10.775 11.3667 10.7776 11.3567 10.7776Z"
            fill="black"
          />
          <path
            d="M10.009 8.94162C9.98863 8.94162 9.96881 8.93106 9.95804 8.91211C9.94181 8.88389 9.95158 8.84798 9.97973 8.83183L10.4167 8.58101C10.4446 8.565 10.4809 8.57448 10.497 8.6027C10.5132 8.63091 10.5035 8.66682 10.4753 8.68297L10.0383 8.93379C10.0291 8.93911 10.0191 8.94162 10.009 8.94162Z"
            fill="black"
          />
          <path
            d="M10.009 9.59396C9.98863 9.59396 9.96881 9.58341 9.95804 9.56445C9.94181 9.53623 9.95158 9.50033 9.97973 9.48417L10.4167 9.23335C10.4446 9.21734 10.4809 9.22682 10.497 9.25504C10.5132 9.28326 10.5035 9.31916 10.4753 9.33532L10.0383 9.58614C10.0291 9.59145 10.0191 9.59396 10.009 9.59396Z"
            fill="black"
          />
          <path
            d="M10.009 10.2444C9.98863 10.2444 9.96881 10.2338 9.95804 10.2148C9.94181 10.1866 9.95158 10.1507 9.97973 10.1346L10.4167 9.88374C10.4446 9.86773 10.4809 9.87721 10.497 9.90543C10.5132 9.93365 10.5035 9.96955 10.4753 9.98571L10.0383 10.2365C10.0291 10.2418 10.0191 10.2444 10.009 10.2444Z"
            fill="black"
          />
          <path
            d="M10.009 10.8967C9.98863 10.8967 9.96881 10.8861 9.95804 10.8672C9.94181 10.839 9.95158 10.8031 9.97973 10.7869L10.4167 10.5361C10.4446 10.5201 10.4809 10.5296 10.497 10.5578C10.5132 10.586 10.5035 10.6219 10.4753 10.6381L10.0383 10.8889C10.0291 10.8942 10.0191 10.8967 10.009 10.8967Z"
            fill="black"
          />
          <path
            d="M10.009 11.553C9.98863 11.553 9.96896 11.5424 9.95804 11.5235C9.94181 11.4953 9.95158 11.4594 9.97973 11.4432L10.4167 11.1923C10.4446 11.1764 10.4808 11.1858 10.497 11.214C10.5132 11.2421 10.5035 11.2781 10.4753 11.2943L10.0383 11.5452C10.0291 11.5505 10.0191 11.553 10.009 11.553Z"
            fill="black"
          />
          <path d="M4.75488 5.43273L6.89686 4.20312L11.2321 6.70815L9.0901 7.93776L4.75488 5.43273Z" fill="white" />
          <path
            d="M9.09039 7.99689C9.08019 7.99689 9.07 7.99431 9.06095 7.98899L4.72573 5.48397C4.70749 5.47348 4.69629 5.45402 4.69629 5.43298C4.69643 5.41194 4.70763 5.39249 4.72587 5.38207L6.86785 4.15247C6.88609 4.14184 6.90863 4.14191 6.92659 4.15254L11.2618 6.65756C11.28 6.66805 11.2912 6.68751 11.2912 6.70855C11.2911 6.72959 11.2799 6.74905 11.2617 6.75946L9.11969 7.98906C9.11064 7.99431 9.10044 7.99689 9.09039 7.99689ZM4.87308 5.4332L9.09053 7.87023L11.1145 6.70833L6.897 4.2713L4.87308 5.4332Z"
            fill="black"
          />
          <path d="M5.87305 5.46312L6.9554 4.8418L5.87305 5.46312Z" fill="white" />
          <path
            d="M5.87328 5.522C5.85289 5.522 5.83307 5.51144 5.8223 5.49248C5.80607 5.46426 5.81584 5.42836 5.84399 5.4122L6.92625 4.79094C6.95425 4.775 6.99044 4.7844 7.00653 4.81262C7.02275 4.84084 7.01299 4.87675 6.98484 4.8929L5.90258 5.51417C5.89339 5.51948 5.88334 5.522 5.87328 5.522Z"
            fill="black"
          />
          <path d="M6.59082 5.87914L7.67317 5.25781L6.59082 5.87914Z" fill="white" />
          <path
            d="M6.59106 5.93808C6.57066 5.93808 6.55085 5.92752 6.54007 5.90856C6.52385 5.88034 6.53361 5.84444 6.56176 5.82828L7.64402 5.20702C7.67202 5.19093 7.70836 5.20048 7.7243 5.2287C7.74053 5.25692 7.73076 5.29283 7.70261 5.30898L6.62035 5.93025C6.61116 5.93556 6.60111 5.93808 6.59106 5.93808Z"
            fill="black"
          />
          <path d="M7.30859 6.29515L8.39095 5.67383L7.30859 6.29515Z" fill="white" />
          <path
            d="M7.30883 6.35418C7.28844 6.35418 7.26876 6.34362 7.25785 6.32467C7.24162 6.29652 7.25139 6.26054 7.27953 6.24439L8.36179 5.62305C8.38965 5.60696 8.42585 5.61644 8.44207 5.64473C8.4583 5.67295 8.44854 5.70886 8.42039 5.72501L7.33813 6.34635C7.32894 6.35167 7.31888 6.35418 7.30883 6.35418Z"
            fill="black"
          />
          <path d="M8.02637 6.71117L9.10872 6.08984L8.02637 6.71117Z" fill="white" />
          <path
            d="M8.0266 6.77007C8.00621 6.77007 7.98639 6.75952 7.97562 6.74056C7.95939 6.71234 7.96916 6.67644 7.99731 6.66028L9.07957 6.03902C9.10743 6.023 9.14376 6.03248 9.15984 6.0607C9.17607 6.08892 9.16631 6.12483 9.13816 6.14098L8.0559 6.76225C8.04671 6.76756 8.03666 6.77007 8.0266 6.77007Z"
            fill="black"
          />
          <path d="M8.02637 6.71117L9.10872 6.08984L8.02637 6.71117Z" fill="white" />
          <path
            d="M8.0266 6.77007C8.00621 6.77007 7.98639 6.75952 7.97562 6.74056C7.95939 6.71234 7.96916 6.67644 7.99731 6.66028L9.07957 6.03902C9.10743 6.023 9.14376 6.03248 9.15984 6.0607C9.17607 6.08892 9.16631 6.12483 9.13816 6.14098L8.0559 6.76225C8.04671 6.76756 8.03666 6.77007 8.0266 6.77007Z"
            fill="black"
          />
          <path d="M8.74414 7.12704L9.82649 6.50586L8.74414 7.12704Z" fill="white" />
          <path
            d="M8.74438 7.18604C8.72398 7.18604 8.70417 7.17549 8.69339 7.15653C8.67717 7.12831 8.68693 7.09241 8.71508 7.07625L9.79734 6.45498C9.82535 6.43912 9.86168 6.44838 9.87762 6.47667C9.89385 6.50489 9.88408 6.54079 9.85593 6.55695L8.77367 7.17822C8.76448 7.18353 8.75443 7.18604 8.74438 7.18604Z"
            fill="black"
          />
          <path d="M7.30859 6.29515L8.39095 5.67383L7.30859 6.29515Z" fill="white" />
          <path
            d="M7.30883 6.35418C7.28844 6.35418 7.26876 6.34362 7.25785 6.32467C7.24162 6.29652 7.25139 6.26054 7.27953 6.24439L8.36179 5.62305C8.38965 5.60696 8.42585 5.61644 8.44207 5.64473C8.4583 5.67295 8.44854 5.70886 8.42039 5.72501L7.33813 6.34635C7.32894 6.35167 7.31888 6.35418 7.30883 6.35418Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_55484_7985">
            <rect width="10" height="10" fill="white" transform="translate(3 3)" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (variant === 'inactive') {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
        <mask
          id="mask0_55484_8003"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_55484_8003)"></g>
        <g clipPath="url(#clip0_55484_8003)">
          <path d="M9.10147 12.6459L3.29401 9.29032L3.28223 5.42773L9.08969 8.78328L9.10147 12.6459Z" fill="#9CA3AF" />
          <path
            d="M9.10122 12.6457L3.29454 9.29005L3.28223 5.42773L4.1533 5.93091C3.92057 6.36044 3.79185 6.83556 3.79185 7.33522C3.79185 9.27077 5.72274 10.8399 8.10324 10.8399C8.44523 10.8399 8.7768 10.8083 9.09597 10.7452L9.10122 12.6457Z"
            fill="#9CA3AF"
          />
          <path
            d="M9.09987 12.7049C9.08967 12.7049 9.07947 12.7024 9.07043 12.697L3.2629 9.34147C3.2448 9.33099 3.2336 9.31167 3.23346 9.2907L3.22168 5.42818C3.22168 5.40707 3.23288 5.38761 3.25112 5.37706C3.26922 5.36657 3.29191 5.36643 3.31 5.37706L9.11753 8.73263C9.13563 8.74311 9.14683 8.76243 9.14697 8.78339L9.15875 12.6459C9.15875 12.667 9.14755 12.6865 9.12931 12.697C9.12026 12.7023 9.11006 12.7049 9.09987 12.7049ZM3.35107 9.25652L9.0407 12.544L9.02935 8.81757L3.33973 5.53008L3.35107 9.25652Z"
            fill="#9CA3AF"
          />
          <path
            d="M4.14804 8.76054C4.11558 8.76054 4.0893 8.73433 4.08915 8.70195L4.08398 7.0102C4.08398 6.97767 4.11027 6.95124 4.14272 6.95117C4.17518 6.95117 4.2016 6.97738 4.20175 7.00977L4.20692 8.70152C4.20692 8.73404 4.18049 8.76047 4.14804 8.76054Z"
            fill="#9CA3AF"
          />
          <path
            d="M4.96737 9.23508C4.93491 9.23508 4.90863 9.20887 4.90849 9.17649L4.90332 7.48481C4.90332 7.45228 4.9296 7.42585 4.96206 7.42578C4.99451 7.42578 5.02094 7.45199 5.02108 7.48438L5.02625 9.17605C5.02625 9.20858 4.99983 9.23501 4.96737 9.23508Z"
            fill="#9CA3AF"
          />
          <path
            d="M5.78573 9.70585C5.75327 9.70585 5.72699 9.67964 5.72685 9.64726L5.72168 7.95551C5.72168 7.92298 5.74796 7.89656 5.78042 7.89648C5.81287 7.89648 5.8393 7.92269 5.83944 7.95508L5.84461 9.64683C5.84461 9.67936 5.81819 9.70578 5.78573 9.70585Z"
            fill="#9CA3AF"
          />
          <path
            d="M6.60311 10.1746C6.57066 10.1746 6.54438 10.1484 6.54423 10.116L6.53906 8.42426C6.53906 8.39173 6.56534 8.36531 6.5978 8.36523C6.63026 8.36523 6.65668 8.39144 6.65682 8.42383L6.66199 10.1156C6.66199 10.1481 6.63557 10.1745 6.60311 10.1746Z"
            fill="#9CA3AF"
          />
          <path
            d="M7.42147 10.6491C7.38902 10.6491 7.36274 10.6229 7.36259 10.5905L7.35742 8.89887C7.35742 8.86634 7.3837 8.83992 7.41616 8.83984C7.44862 8.83984 7.47504 8.86605 7.47518 8.89844L7.48035 10.5901C7.48035 10.6226 7.45393 10.6491 7.42147 10.6491Z"
            fill="#9CA3AF"
          />
          <path
            d="M8.23886 11.1238C8.2064 11.1238 8.18012 11.0976 8.17997 11.0652L8.1748 9.37348C8.1748 9.34095 8.20109 9.31452 8.23354 9.31445C8.266 9.31445 8.29242 9.34066 8.29257 9.37305L8.29774 11.0648C8.29774 11.0973 8.27131 11.1238 8.23886 11.1238Z"
            fill="#9CA3AF"
          />
          <path d="M9.08773 8.78307L3.28027 5.42752L6.89671 3.35156L12.7042 6.70711L9.08773 8.78307Z" fill="white" />
          <path
            d="M12.7055 6.70821L9.08891 8.784L3.28223 5.42836L5.06149 4.40625C5.02438 4.5465 5.00501 4.69027 5.00501 4.83579C5.00501 6.2962 6.90059 7.47961 9.237 7.47961C10.5208 7.47961 11.6704 7.12196 12.4463 6.55743L12.7055 6.70821Z"
            fill="#E9EDF4"
          />
          <path d="M12.7043 6.70508L12.7161 10.5677L9.09967 12.6436L9.08789 8.78104L12.7043 6.70508Z" fill="white" />
          <path
            d="M12.7176 10.5674L9.10096 12.6432L9.0957 11.1126C9.34427 11.1968 9.6123 11.2424 9.89092 11.2424C11.2593 11.2424 12.3666 10.1396 12.3666 8.78087C12.3666 8.18828 12.1568 7.64479 11.806 7.22052L12.7052 6.70508L12.7176 10.5674Z"
            fill="#E9EDF4"
          />
          <path
            d="M9.08809 8.84218C9.0779 8.84218 9.0677 8.83959 9.05865 8.83428L3.25112 5.47871C3.23288 5.46823 3.22168 5.44877 3.22168 5.42773C3.22182 5.40669 3.23303 5.38723 3.25126 5.37682L6.8677 3.3009C6.88594 3.29028 6.90849 3.29035 6.92644 3.30098L12.734 6.65647C12.7522 6.66696 12.7634 6.68642 12.7634 6.70745C12.7633 6.72849 12.7521 6.74795 12.7338 6.75837L9.11739 8.83435C9.10834 8.83959 9.09814 8.84218 9.08809 8.84218ZM3.39847 5.42794L9.08824 8.71551L12.5866 6.70724L6.89686 3.41974L3.39847 5.42794Z"
            fill="#9CA3AF"
          />
          <path
            d="M9.10191 12.7028C9.09171 12.7028 9.08166 12.7002 9.07247 12.6949C9.05437 12.6845 9.04317 12.6651 9.04303 12.6442L9.03125 8.78151C9.03125 8.7604 9.04245 8.74087 9.06083 8.73039L12.6773 6.6544C12.6954 6.64377 12.7176 6.64392 12.736 6.6544C12.7541 6.66488 12.7653 6.68427 12.7655 6.70517L12.7772 10.5678C12.7772 10.5889 12.766 10.6085 12.7476 10.619L9.1312 12.6949C9.12216 12.7002 9.11196 12.7028 9.10191 12.7028ZM9.14901 8.81533L9.16036 12.5426L12.6595 10.534L12.6481 6.80677L9.14901 8.81533Z"
            fill="#9CA3AF"
          />
          <path
            d="M11.5834 11.2821C11.5509 11.2821 11.5246 11.2559 11.5245 11.2234L11.5127 7.36176C11.5127 7.32923 11.539 7.30281 11.5714 7.30273C11.6039 7.30273 11.6303 7.32894 11.6305 7.3614L11.6422 11.2231C11.6422 11.2556 11.6158 11.282 11.5834 11.2821Z"
            fill="#9CA3AF"
          />
          <path
            d="M10.2357 12.0517C10.2032 12.0517 10.177 12.0255 10.1768 11.993L10.165 8.13129C10.165 8.09876 10.1913 8.07234 10.2238 8.07227C10.2562 8.07227 10.2827 8.09847 10.2828 8.13093L10.2946 11.9927C10.2946 12.0252 10.2682 12.0516 10.2357 12.0517Z"
            fill="#9CA3AF"
          />
          <path
            d="M11.3567 8.16624C11.3363 8.16624 11.3165 8.15569 11.3057 8.13673C11.2895 8.10851 11.2992 8.07261 11.3274 8.05645L11.7644 7.80563C11.7924 7.78962 11.8286 7.79903 11.8447 7.82732C11.8609 7.85554 11.8511 7.89144 11.823 7.9076L11.386 8.15842C11.3768 8.16373 11.3667 8.16624 11.3567 8.16624Z"
            fill="#9CA3AF"
          />
          <path
            d="M11.3567 8.82252C11.3363 8.82252 11.3166 8.81196 11.3057 8.79301C11.2895 8.76486 11.2992 8.72888 11.3274 8.71273L11.7644 8.46184C11.7924 8.4459 11.8284 8.4553 11.8447 8.48352C11.8609 8.51167 11.8511 8.54765 11.823 8.5638L11.386 8.81469C11.3768 8.82001 11.3667 8.82252 11.3567 8.82252Z"
            fill="#9CA3AF"
          />
          <path
            d="M11.3567 9.47482C11.3363 9.47482 11.3165 9.46427 11.3057 9.44531C11.2895 9.41709 11.2992 9.38119 11.3274 9.36503L11.7644 9.11421C11.7924 9.0982 11.8286 9.10768 11.8447 9.1359C11.8609 9.16412 11.8511 9.20002 11.823 9.21618L11.386 9.467C11.3768 9.47231 11.3667 9.47482 11.3567 9.47482Z"
            fill="#9CA3AF"
          />
          <path
            d="M11.3567 10.1252C11.3363 10.1252 11.3165 10.1147 11.3057 10.0957C11.2895 10.0675 11.2992 10.0316 11.3274 10.0154L11.7644 9.7646C11.7924 9.74859 11.8286 9.75807 11.8447 9.78629C11.8609 9.81451 11.8511 9.85041 11.823 9.86657L11.386 10.1174C11.3768 10.1227 11.3667 10.1252 11.3567 10.1252Z"
            fill="#9CA3AF"
          />
          <path
            d="M11.3567 10.7776C11.3363 10.7776 11.3165 10.767 11.3057 10.748C11.2895 10.7198 11.2992 10.6839 11.3274 10.6678L11.7644 10.4169C11.7924 10.4009 11.8286 10.4104 11.8447 10.4386C11.8609 10.4669 11.8511 10.5028 11.823 10.5189L11.386 10.7697C11.3768 10.775 11.3667 10.7776 11.3567 10.7776Z"
            fill="#9CA3AF"
          />
          <path
            d="M10.009 8.94162C9.98863 8.94162 9.96881 8.93106 9.95804 8.91211C9.94181 8.88389 9.95158 8.84798 9.97973 8.83183L10.4167 8.58101C10.4446 8.565 10.4809 8.57448 10.497 8.6027C10.5132 8.63091 10.5035 8.66682 10.4753 8.68297L10.0383 8.93379C10.0291 8.93911 10.0191 8.94162 10.009 8.94162Z"
            fill="#9CA3AF"
          />
          <path
            d="M10.009 9.59396C9.98863 9.59396 9.96881 9.58341 9.95804 9.56445C9.94181 9.53623 9.95158 9.50033 9.97973 9.48417L10.4167 9.23335C10.4446 9.21734 10.4809 9.22682 10.497 9.25504C10.5132 9.28326 10.5035 9.31916 10.4753 9.33532L10.0383 9.58614C10.0291 9.59145 10.0191 9.59396 10.009 9.59396Z"
            fill="#9CA3AF"
          />
          <path
            d="M10.009 10.2444C9.98863 10.2444 9.96881 10.2338 9.95804 10.2148C9.94181 10.1866 9.95158 10.1507 9.97973 10.1346L10.4167 9.88374C10.4446 9.86773 10.4809 9.87721 10.497 9.90543C10.5132 9.93365 10.5035 9.96955 10.4753 9.98571L10.0383 10.2365C10.0291 10.2418 10.0191 10.2444 10.009 10.2444Z"
            fill="#9CA3AF"
          />
          <path
            d="M10.009 10.8967C9.98863 10.8967 9.96881 10.8861 9.95804 10.8672C9.94181 10.839 9.95158 10.8031 9.97973 10.7869L10.4167 10.5361C10.4446 10.5201 10.4809 10.5296 10.497 10.5578C10.5132 10.586 10.5035 10.6219 10.4753 10.6381L10.0383 10.8889C10.0291 10.8942 10.0191 10.8967 10.009 10.8967Z"
            fill="#9CA3AF"
          />
          <path
            d="M10.009 11.553C9.98863 11.553 9.96896 11.5424 9.95804 11.5235C9.94181 11.4953 9.95158 11.4594 9.97973 11.4432L10.4167 11.1923C10.4446 11.1764 10.4808 11.1858 10.497 11.214C10.5132 11.2421 10.5035 11.2781 10.4753 11.2943L10.0383 11.5452C10.0291 11.5505 10.0191 11.553 10.009 11.553Z"
            fill="#9CA3AF"
          />
          <path d="M4.75488 5.43273L6.89686 4.20312L11.2321 6.70815L9.0901 7.93776L4.75488 5.43273Z" fill="white" />
          <path
            d="M9.09039 7.99689C9.08019 7.99689 9.07 7.99431 9.06095 7.98899L4.72573 5.48397C4.70749 5.47348 4.69629 5.45402 4.69629 5.43298C4.69643 5.41194 4.70763 5.39249 4.72587 5.38207L6.86785 4.15247C6.88609 4.14184 6.90863 4.14191 6.92659 4.15254L11.2618 6.65756C11.28 6.66805 11.2912 6.68751 11.2912 6.70855C11.2911 6.72959 11.2799 6.74905 11.2617 6.75946L9.11969 7.98906C9.11064 7.99431 9.10044 7.99689 9.09039 7.99689ZM4.87308 5.4332L9.09053 7.87023L11.1145 6.70833L6.897 4.2713L4.87308 5.4332Z"
            fill="#9CA3AF"
          />
          <path d="M5.87305 5.46312L6.9554 4.8418L5.87305 5.46312Z" fill="white" />
          <path
            d="M5.87328 5.522C5.85289 5.522 5.83307 5.51144 5.8223 5.49248C5.80607 5.46426 5.81584 5.42836 5.84399 5.4122L6.92625 4.79094C6.95425 4.775 6.99044 4.7844 7.00653 4.81262C7.02275 4.84084 7.01299 4.87675 6.98484 4.8929L5.90258 5.51417C5.89339 5.51948 5.88334 5.522 5.87328 5.522Z"
            fill="#9CA3AF"
          />
          <path d="M6.59082 5.87914L7.67317 5.25781L6.59082 5.87914Z" fill="white" />
          <path
            d="M6.59106 5.93808C6.57066 5.93808 6.55085 5.92752 6.54007 5.90856C6.52385 5.88034 6.53361 5.84444 6.56176 5.82828L7.64402 5.20702C7.67202 5.19093 7.70836 5.20048 7.7243 5.2287C7.74053 5.25692 7.73076 5.29283 7.70261 5.30898L6.62035 5.93025C6.61116 5.93556 6.60111 5.93808 6.59106 5.93808Z"
            fill="#9CA3AF"
          />
          <path d="M7.30859 6.29515L8.39095 5.67383L7.30859 6.29515Z" fill="white" />
          <path
            d="M7.30883 6.35418C7.28844 6.35418 7.26876 6.34362 7.25785 6.32467C7.24162 6.29652 7.25139 6.26054 7.27953 6.24439L8.36179 5.62305C8.38965 5.60696 8.42585 5.61644 8.44207 5.64473C8.4583 5.67295 8.44854 5.70886 8.42039 5.72501L7.33813 6.34635C7.32894 6.35167 7.31888 6.35418 7.30883 6.35418Z"
            fill="#9CA3AF"
          />
          <path d="M8.02637 6.71117L9.10872 6.08984L8.02637 6.71117Z" fill="white" />
          <path
            d="M8.0266 6.77007C8.00621 6.77007 7.98639 6.75952 7.97562 6.74056C7.95939 6.71234 7.96916 6.67644 7.99731 6.66028L9.07957 6.03902C9.10743 6.023 9.14376 6.03248 9.15984 6.0607C9.17607 6.08892 9.16631 6.12483 9.13816 6.14098L8.0559 6.76225C8.04671 6.76756 8.03666 6.77007 8.0266 6.77007Z"
            fill="#9CA3AF"
          />
          <path d="M8.02637 6.71117L9.10872 6.08984L8.02637 6.71117Z" fill="white" />
          <path
            d="M8.0266 6.77007C8.00621 6.77007 7.98639 6.75952 7.97562 6.74056C7.95939 6.71234 7.96916 6.67644 7.99731 6.66028L9.07957 6.03902C9.10743 6.023 9.14376 6.03248 9.15984 6.0607C9.17607 6.08892 9.16631 6.12483 9.13816 6.14098L8.0559 6.76225C8.04671 6.76756 8.03666 6.77007 8.0266 6.77007Z"
            fill="#9CA3AF"
          />
          <path d="M8.74414 7.12704L9.82649 6.50586L8.74414 7.12704Z" fill="white" />
          <path
            d="M8.74438 7.18604C8.72398 7.18604 8.70417 7.17549 8.69339 7.15653C8.67717 7.12831 8.68693 7.09241 8.71508 7.07625L9.79734 6.45498C9.82535 6.43912 9.86168 6.44838 9.87762 6.47667C9.89385 6.50489 9.88408 6.54079 9.85593 6.55695L8.77367 7.17822C8.76448 7.18353 8.75443 7.18604 8.74438 7.18604Z"
            fill="#9CA3AF"
          />
          <path d="M7.30859 6.29515L8.39095 5.67383L7.30859 6.29515Z" fill="white" />
          <path
            d="M7.30883 6.35418C7.28844 6.35418 7.26876 6.34362 7.25785 6.32467C7.24162 6.29652 7.25139 6.26054 7.27953 6.24439L8.36179 5.62305C8.38965 5.60696 8.42585 5.61644 8.44207 5.64473C8.4583 5.67295 8.44854 5.70886 8.42039 5.72501L7.33813 6.34635C7.32894 6.35167 7.31888 6.35418 7.30883 6.35418Z"
            fill="#9CA3AF"
          />
        </g>
        <defs>
          <clipPath id="clip0_55484_8003">
            <rect width="10" height="10" fill="white" transform="translate(3 3)" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <mask
        id="mask0_55487_809"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_55487_809)"></g>
      <g clipPath="url(#clip0_55487_809)">
        <path d="M9.10049 12.6459L3.29303 9.29032L3.28125 5.42773L9.08871 8.78328L9.10049 12.6459Z" fill="#4B5563" />
        <path
          d="M9.10025 12.6457L3.29356 9.29005L3.28125 5.42773L4.15233 5.93091C3.9196 6.36044 3.79088 6.83556 3.79088 7.33522C3.79088 9.27077 5.72176 10.8399 8.10226 10.8399C8.44425 10.8399 8.77582 10.8083 9.09499 10.7452L9.10025 12.6457Z"
          fill="#4B5563"
        />
        <path
          d="M9.09889 12.7049C9.08869 12.7049 9.0785 12.7024 9.06945 12.697L3.26192 9.34147C3.24382 9.33099 3.23262 9.31167 3.23248 9.2907L3.2207 5.42818C3.2207 5.40707 3.2319 5.38761 3.25014 5.37706C3.26824 5.36657 3.29093 5.36643 3.30902 5.37706L9.11656 8.73263C9.13465 8.74311 9.14585 8.76243 9.146 8.78339L9.15777 12.6459C9.15777 12.667 9.14657 12.6865 9.12833 12.697C9.11928 12.7023 9.10909 12.7049 9.09889 12.7049ZM3.3501 9.25652L9.03972 12.544L9.02838 8.81757L3.33875 5.53008L3.3501 9.25652Z"
          fill="black"
        />
        <path
          d="M4.14804 8.76054C4.11558 8.76054 4.0893 8.73433 4.08915 8.70195L4.08398 7.0102C4.08398 6.97767 4.11027 6.95124 4.14272 6.95117C4.17518 6.95117 4.2016 6.97738 4.20175 7.00977L4.20692 8.70152C4.20692 8.73404 4.18049 8.76047 4.14804 8.76054Z"
          fill="black"
        />
        <path
          d="M4.96639 9.23508C4.93394 9.23508 4.90766 9.20887 4.90751 9.17649L4.90234 7.48481C4.90234 7.45228 4.92862 7.42585 4.96108 7.42578C4.99354 7.42578 5.01996 7.45199 5.02011 7.48438L5.02528 9.17605C5.02528 9.20858 4.99885 9.23501 4.96639 9.23508Z"
          fill="black"
        />
        <path
          d="M5.78475 9.70585C5.7523 9.70585 5.72602 9.67964 5.72587 9.64726L5.7207 7.95551C5.7207 7.92298 5.74698 7.89656 5.77944 7.89648C5.8119 7.89648 5.83832 7.92269 5.83847 7.95508L5.84364 9.64683C5.84364 9.67936 5.81721 9.70578 5.78475 9.70585Z"
          fill="black"
        />
        <path
          d="M6.60116 10.1746C6.5687 10.1746 6.54242 10.1484 6.54228 10.116L6.53711 8.42426C6.53711 8.39173 6.56339 8.36531 6.59585 8.36523C6.6283 8.36523 6.65473 8.39144 6.65487 8.42383L6.66004 10.1156C6.66004 10.1481 6.63362 10.1745 6.60116 10.1746Z"
          fill="black"
        />
        <path
          d="M7.42147 10.6491C7.38902 10.6491 7.36274 10.6229 7.36259 10.5905L7.35742 8.89887C7.35742 8.86634 7.3837 8.83992 7.41616 8.83984C7.44862 8.83984 7.47504 8.86605 7.47518 8.89844L7.48035 10.5901C7.48035 10.6226 7.45393 10.6491 7.42147 10.6491Z"
          fill="black"
        />
        <path
          d="M8.23788 11.1238C8.20542 11.1238 8.17914 11.0976 8.179 11.0652L8.17383 9.37348C8.17383 9.34095 8.20011 9.31452 8.23257 9.31445C8.26502 9.31445 8.29145 9.34066 8.29159 9.37305L8.29676 11.0648C8.29676 11.0973 8.27034 11.1238 8.23788 11.1238Z"
          fill="black"
        />
        <path d="M9.08676 8.78307L3.2793 5.42752L6.89574 3.35156L12.7032 6.70711L9.08676 8.78307Z" fill="white" />
        <path
          d="M12.7045 6.70821L9.08793 8.784L3.28125 5.42836L5.06051 4.40625C5.0234 4.5465 5.00403 4.69027 5.00403 4.83579C5.00403 6.2962 6.89961 7.47961 9.23603 7.47961C10.5198 7.47961 11.6694 7.12196 12.4453 6.55743L12.7045 6.70821Z"
          fill="#E9EDF4"
        />
        <path d="M12.7024 6.70508L12.7142 10.5677L9.09772 12.6436L9.08594 8.78104L12.7024 6.70508Z" fill="white" />
        <path
          d="M12.7176 10.5674L9.10096 12.6432L9.0957 11.1126C9.34427 11.1968 9.6123 11.2424 9.89092 11.2424C11.2593 11.2424 12.3666 10.1396 12.3666 8.78087C12.3666 8.18828 12.1568 7.64479 11.806 7.22052L12.7052 6.70508L12.7176 10.5674Z"
          fill="#E9EDF4"
        />
        <path
          d="M9.08712 8.84218C9.07692 8.84218 9.06672 8.83959 9.05767 8.83428L3.25014 5.47871C3.2319 5.46823 3.2207 5.44877 3.2207 5.42773C3.22085 5.40669 3.23205 5.38723 3.25029 5.37682L6.86673 3.3009C6.88497 3.29028 6.90751 3.29035 6.92547 3.30098L12.733 6.65647C12.7512 6.66696 12.7624 6.68642 12.7624 6.70745C12.7623 6.72849 12.7511 6.74795 12.7329 6.75837L9.11641 8.83435C9.10736 8.83959 9.09717 8.84218 9.08712 8.84218ZM3.39749 5.42794L9.08726 8.71551L12.5857 6.70724L6.89588 3.41974L3.39749 5.42794Z"
          fill="black"
        />
        <path
          d="M9.09995 12.7028C9.08976 12.7028 9.0797 12.7002 9.07051 12.6949C9.05242 12.6845 9.04122 12.6651 9.04107 12.6442L9.0293 8.78151C9.0293 8.7604 9.0405 8.74087 9.05888 8.73039L12.6753 6.6544C12.6934 6.64377 12.7157 6.64392 12.7341 6.6544C12.7522 6.66488 12.7634 6.68427 12.7635 6.70517L12.7753 10.5678C12.7753 10.5889 12.7641 10.6085 12.7457 10.619L9.12925 12.6949C9.1202 12.7002 9.11001 12.7028 9.09995 12.7028ZM9.14706 8.81533L9.1584 12.5426L12.6575 10.534L12.6462 6.80677L9.14706 8.81533Z"
          fill="black"
        />
        <path
          d="M11.5824 11.2821C11.5499 11.2821 11.5236 11.2559 11.5235 11.2234L11.5117 7.36176C11.5117 7.32923 11.538 7.30281 11.5705 7.30273C11.6029 7.30273 11.6293 7.32894 11.6295 7.3614L11.6413 11.2231C11.6413 11.2556 11.6148 11.282 11.5824 11.2821Z"
          fill="black"
        />
        <path
          d="M10.2347 12.0517C10.2023 12.0517 10.176 12.0255 10.1758 11.993L10.1641 8.13129C10.1641 8.09876 10.1903 8.07234 10.2228 8.07227C10.2553 8.07227 10.2817 8.09847 10.2818 8.13093L10.2936 11.9927C10.2936 12.0252 10.2672 12.0516 10.2347 12.0517Z"
          fill="black"
        />
        <path
          d="M11.3557 8.16624C11.3353 8.16624 11.3155 8.15569 11.3047 8.13673C11.2885 8.10851 11.2983 8.07261 11.3264 8.05645L11.7634 7.80563C11.7914 7.78962 11.8276 7.79903 11.8437 7.82732C11.8599 7.85554 11.8502 7.89144 11.822 7.9076L11.385 8.15842C11.3758 8.16373 11.3658 8.16624 11.3557 8.16624Z"
          fill="black"
        />
        <path
          d="M11.3557 8.82252C11.3353 8.82252 11.3156 8.81196 11.3047 8.79301C11.2885 8.76486 11.2983 8.72888 11.3264 8.71273L11.7634 8.46184C11.7914 8.4459 11.8275 8.4553 11.8437 8.48352C11.8599 8.51167 11.8502 8.54765 11.822 8.5638L11.385 8.81469C11.3758 8.82001 11.3658 8.82252 11.3557 8.82252Z"
          fill="black"
        />
        <path
          d="M11.3557 9.47482C11.3353 9.47482 11.3155 9.46427 11.3047 9.44531C11.2885 9.41709 11.2983 9.38119 11.3264 9.36503L11.7634 9.11421C11.7914 9.0982 11.8276 9.10768 11.8437 9.1359C11.8599 9.16412 11.8502 9.20002 11.822 9.21618L11.385 9.467C11.3758 9.47231 11.3658 9.47482 11.3557 9.47482Z"
          fill="black"
        />
        <path
          d="M11.3557 10.1252C11.3353 10.1252 11.3155 10.1147 11.3047 10.0957C11.2885 10.0675 11.2983 10.0316 11.3264 10.0154L11.7634 9.7646C11.7914 9.74859 11.8276 9.75807 11.8437 9.78629C11.8599 9.81451 11.8502 9.85041 11.822 9.86657L11.385 10.1174C11.3758 10.1227 11.3658 10.1252 11.3557 10.1252Z"
          fill="black"
        />
        <path
          d="M11.3557 10.7776C11.3353 10.7776 11.3155 10.767 11.3047 10.748C11.2885 10.7198 11.2983 10.6839 11.3264 10.6678L11.7634 10.4169C11.7914 10.4009 11.8276 10.4104 11.8437 10.4386C11.8599 10.4669 11.8502 10.5028 11.822 10.5189L11.385 10.7697C11.3758 10.775 11.3658 10.7776 11.3557 10.7776Z"
          fill="black"
        />
        <path
          d="M10.008 8.94162C9.98766 8.94162 9.96784 8.93106 9.95707 8.91211C9.94084 8.88389 9.9506 8.84798 9.97875 8.83183L10.4158 8.58101C10.4436 8.565 10.48 8.57448 10.496 8.6027C10.5123 8.63091 10.5025 8.66682 10.4744 8.68297L10.0373 8.93379C10.0282 8.93911 10.0181 8.94162 10.008 8.94162Z"
          fill="black"
        />
        <path
          d="M10.008 9.59396C9.98766 9.59396 9.96784 9.58341 9.95707 9.56445C9.94084 9.53623 9.9506 9.50033 9.97875 9.48417L10.4158 9.23335C10.4436 9.21734 10.48 9.22682 10.496 9.25504C10.5123 9.28326 10.5025 9.31916 10.4744 9.33532L10.0373 9.58614C10.0282 9.59145 10.0181 9.59396 10.008 9.59396Z"
          fill="black"
        />
        <path
          d="M10.008 10.2444C9.98766 10.2444 9.96784 10.2338 9.95707 10.2148C9.94084 10.1866 9.9506 10.1507 9.97875 10.1346L10.4158 9.88374C10.4436 9.86773 10.48 9.87721 10.496 9.90543C10.5123 9.93365 10.5025 9.96955 10.4744 9.98571L10.0373 10.2365C10.0282 10.2418 10.0181 10.2444 10.008 10.2444Z"
          fill="black"
        />
        <path
          d="M10.008 10.8967C9.98766 10.8967 9.96784 10.8861 9.95707 10.8672C9.94084 10.839 9.9506 10.8031 9.97875 10.7869L10.4158 10.5361C10.4436 10.5201 10.48 10.5296 10.496 10.5578C10.5123 10.586 10.5025 10.6219 10.4744 10.6381L10.0373 10.8889C10.0282 10.8942 10.0181 10.8967 10.008 10.8967Z"
          fill="black"
        />
        <path
          d="M10.008 11.553C9.98766 11.553 9.96798 11.5424 9.95707 11.5235C9.94084 11.4953 9.9506 11.4594 9.97875 11.4432L10.4158 11.1923C10.4436 11.1764 10.4798 11.1858 10.496 11.214C10.5123 11.2421 10.5025 11.2781 10.4744 11.2943L10.0373 11.5452C10.0282 11.5505 10.0181 11.553 10.008 11.553Z"
          fill="black"
        />
        <path d="M4.75391 5.43273L6.89588 4.20312L11.2311 6.70815L9.08913 7.93776L4.75391 5.43273Z" fill="white" />
        <path
          d="M9.08941 7.99689C9.07922 7.99689 9.06902 7.99431 9.05997 7.98899L4.72475 5.48397C4.70651 5.47348 4.69531 5.45402 4.69531 5.43298C4.69546 5.41194 4.70666 5.39249 4.7249 5.38207L6.86687 4.15247C6.88511 4.14184 6.90766 4.14191 6.92561 4.15254L11.2608 6.65756C11.2791 6.66805 11.2903 6.68751 11.2903 6.70855C11.2901 6.72959 11.2789 6.74905 11.2607 6.75946L9.11871 7.98906C9.10966 7.99431 9.09947 7.99689 9.08941 7.99689ZM4.8721 5.4332L9.08956 7.87023L11.1135 6.70833L6.89602 4.2713L4.8721 5.4332Z"
          fill="black"
        />
        <path d="M5.87305 5.46312L6.9554 4.8418L5.87305 5.46312Z" fill="white" />
        <path
          d="M5.87328 5.522C5.85289 5.522 5.83307 5.51144 5.8223 5.49248C5.80607 5.46426 5.81584 5.42836 5.84399 5.4122L6.92625 4.79094C6.95425 4.775 6.99044 4.7844 7.00653 4.81262C7.02275 4.84084 7.01299 4.87675 6.98484 4.8929L5.90258 5.51417C5.89339 5.51948 5.88334 5.522 5.87328 5.522Z"
          fill="black"
        />
        <path d="M6.58984 5.87914L7.6722 5.25781L6.58984 5.87914Z" fill="white" />
        <path
          d="M6.59008 5.93808C6.56969 5.93808 6.54987 5.92752 6.5391 5.90856C6.52287 5.88034 6.53264 5.84444 6.56078 5.82828L7.64304 5.20702C7.67105 5.19093 7.70738 5.20048 7.72332 5.2287C7.73955 5.25692 7.72979 5.29283 7.70164 5.30898L6.61938 5.93025C6.61019 5.93556 6.60013 5.93808 6.59008 5.93808Z"
          fill="black"
        />
        <path d="M7.30859 6.29515L8.39095 5.67383L7.30859 6.29515Z" fill="white" />
        <path
          d="M7.30883 6.35418C7.28844 6.35418 7.26876 6.34362 7.25785 6.32467C7.24162 6.29652 7.25139 6.26054 7.27953 6.24439L8.36179 5.62305C8.38965 5.60696 8.42585 5.61644 8.44207 5.64473C8.4583 5.67295 8.44854 5.70886 8.42039 5.72501L7.33813 6.34635C7.32894 6.35167 7.31888 6.35418 7.30883 6.35418Z"
          fill="black"
        />
        <path d="M8.02734 6.71117L9.1097 6.08984L8.02734 6.71117Z" fill="white" />
        <path
          d="M8.02758 6.77007C8.00719 6.77007 7.98737 6.75952 7.9766 6.74056C7.96037 6.71234 7.97014 6.67644 7.99828 6.66028L9.08054 6.03902C9.1084 6.023 9.14474 6.03248 9.16082 6.0607C9.17705 6.08892 9.16729 6.12483 9.13914 6.14098L8.05688 6.76225C8.04769 6.76756 8.03763 6.77007 8.02758 6.77007Z"
          fill="black"
        />
        <path d="M8.02734 6.71117L9.1097 6.08984L8.02734 6.71117Z" fill="white" />
        <path
          d="M8.02758 6.77007C8.00719 6.77007 7.98737 6.75952 7.9766 6.74056C7.96037 6.71234 7.97014 6.67644 7.99828 6.66028L9.08054 6.03902C9.1084 6.023 9.14474 6.03248 9.16082 6.0607C9.17705 6.08892 9.16729 6.12483 9.13914 6.14098L8.05688 6.76225C8.04769 6.76756 8.03763 6.77007 8.02758 6.77007Z"
          fill="black"
        />
        <path d="M8.74414 7.12704L9.82649 6.50586L8.74414 7.12704Z" fill="white" />
        <path
          d="M8.74438 7.18604C8.72398 7.18604 8.70417 7.17549 8.69339 7.15653C8.67717 7.12831 8.68693 7.09241 8.71508 7.07625L9.79734 6.45498C9.82535 6.43912 9.86168 6.44838 9.87762 6.47667C9.89385 6.50489 9.88408 6.54079 9.85593 6.55695L8.77367 7.17822C8.76448 7.18353 8.75443 7.18604 8.74438 7.18604Z"
          fill="black"
        />
        <path d="M7.30859 6.29515L8.39095 5.67383L7.30859 6.29515Z" fill="white" />
        <path
          d="M7.30883 6.35418C7.28844 6.35418 7.26876 6.34362 7.25785 6.32467C7.24162 6.29652 7.25139 6.26054 7.27953 6.24439L8.36179 5.62305C8.38965 5.60696 8.42585 5.61644 8.44207 5.64473C8.4583 5.67295 8.44854 5.70886 8.42039 5.72501L7.33813 6.34635C7.32894 6.35167 7.31888 6.35418 7.30883 6.35418Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_55487_809">
          <rect width="10" height="10" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContainerIcon;
