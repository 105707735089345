import React from 'react'
import { cn } from 'shared/utils'
import { BadgeButton, Colors } from './badge'

const statusColors = {
  unreceived: 'zinc',
  'partial ok': 'green',
  success: 'green',
  failure: 'red',
  discrepancy: 'red',
  failed: 'red',
  un_received: 'zinc',
  partial_ok: 'indigo',
  all_ok: 'green',
  error: 'red',
  mixed: 'amber',
  unvalidated: 'red',
}

const statusText = {
  unreceived: 'Unreceived',
  'partial ok': 'Partial OK',
  success: 'Success',
  failure: 'Failure',
  discrepancy: 'Discrepancy',
  failed: 'Failed',
  un_received: 'Unreceived',
  partial_ok: 'Partial OK',
  all_ok: 'All OK',
  in_progress: 'In progress',
  error: 'Discrepancy',
  mixed: 'Mixed',
  unvalidated: 'Unvalidated',
}

const countBadgeBorderColor = {
  zinc: 'border-zinc-700',
  green: 'border-green-600',
  red: 'border-red-600',
  amber: 'border-amber-700',
}

const Status = ({
  type,
  text,
  disabled,
  data = {},
  href,
  target,
  count,
  handleClick,
  textClassName = ''
}: {
  type: 'Button' | 'Link'
  text: string
  disabled: boolean
  target: string
  data?: unknown
  href?: any
  count?: number
  handleClick?: (v: unknown) => any
  textClassName?: string
}) => {
  const color = statusColors?.[text?.toLowerCase() as keyof typeof statusColors]
  const CountBadge = (
    <span
      className={cn(
        'h-4 w-5 rounded-full border text-[10px] leading-[14px]',
        countBadgeBorderColor[color as keyof typeof countBadgeBorderColor]
      )}
    >
      {count}
    </span>
  )
  return (
    <div className="">
      {type === 'Button' ? (
        <BadgeButton
          color={color as Colors}
          className="gap-1"
          textClassName={textClassName}
          disabled={disabled}
          onClick={() => {
            handleClick?.(data as unknown)
          }}
        >
          {statusText[text?.toLowerCase() as keyof typeof statusColors]}

          {count && CountBadge}
        </BadgeButton>
      ) : (
        !!href && (
          <BadgeButton className="gap-1"  textClassName={textClassName} color={color as Colors} disabled={disabled} to={href} target={target}>
            {statusText[text?.toLowerCase() as keyof typeof statusColors]}
            {count && CountBadge}
          </BadgeButton>
        )
      )}
    </div>
  )
}

export default Status
