import { EDocumentType } from 'shared/constants/enums';
import { TDocumentType } from 'shared/constants/types';

export const getDocumentTypeOptions = (): {
  value: string;
  label: string;
  index: number;
}[] => {
  return Object.keys(EDocumentType).map((key, index) => ({
    value: key,
    label: EDocumentType[key as TDocumentType],
    index,
  }));
};

export const DOCUMENT_TYPES_OPTIONS = getDocumentTypeOptions();

export const getDocumentTypeDisplayName = (type: string): string => {
  return EDocumentType[type as TDocumentType] || type;
};
