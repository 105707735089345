import { Accordion } from '@szhsin/react-accordion';
import get from 'lodash/get';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import ShipmentDetailsHeader from 'containers/Shipments/Details/ShipmentDetailsHeader';
import ShipmentValidationDetailsDrawer from 'containers/Shipments/Details/ShipmentValidationDetailsDrawer';
import ValidationStatusIcon from 'containers/Shipments/Details/ValidationStatusIcon';
import ShipmentRoute from 'models/ShipmentDetails/components/ShipmentRoute';
import { AccordionItem } from 'shared/components/accordion-items';
import { BadgeButton } from 'shared/components/badge';
import { Button } from 'shared/components/button';
import { Divider } from 'shared/components/divider';
import { Input } from 'shared/components/input';
import { Loader } from 'shared/components/loader';
import { Textarea } from 'shared/components/textarea';
import { auth } from 'shared/firebase/config';
import { FetchShipmentsByIdRequest } from 'shared/firebase/model';
import useShipmentDetails from 'shared/hooks/useShipmentDetails';
import CheckCircleIcon from 'shared/icons/CheckCircleIcon';
import ExclamationIcon from 'shared/icons/ExclamationIcon';
import { overrideShipmentValidation, resetToaster } from 'shared/reduxStore/slices/shipmentDetails';
import { AppDispatch } from 'shared/reduxStore/store';
import { convertMillisecondToIOSString } from 'shared/utils/date';
import formatAddress from 'shared/utils/formatAddress';
import {
  EValidationStatus,
  routesKeys,
  ShipmentDetailFieldLabel,
  ShipmentDetailsState,
  Validation,
} from '../containers/Shipments/Details/model';

const enum ShipmentTab {
  OVERVIEW = 'Overview',
  ROUTE = 'Route',
}

const SUPPORTED_TABS = [ShipmentTab.OVERVIEW, ShipmentTab.ROUTE];

const ShipmentView = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tabListRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [maxTabPanelHeight, setMaxTabPanelHeight] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [shipmentDetails, setShipmentDetails] = useState<ShipmentDetailsState>({
    involvedParties: [
      {
        partyName: 'Shipper',
        name: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        nameKey: 'shipper',
        emailKey: 'shipper',
        addressLine1Key: 'shipper',
        addressLine2Key: 'shipper',
        cityKey: 'shipper',
        stateKey: 'shipper',
        countryKey: 'shipper',
        postalCodeKey: 'shipper',
      },
      {
        partyName: 'Consignee',
        name: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        nameKey: 'consignee',
        emailKey: 'consignee',
        addressLine1Key: 'consignee',
        addressLine2Key: 'consignee',
        cityKey: 'consignee',
        stateKey: 'consignee',
        countryKey: 'consignee',
        postalCodeKey: 'consignee',
      },
      {
        partyName: 'Notify party',
        name: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        nameKey: 'notify_party',
        emailKey: 'notify_party',
        addressLine1Key: 'notify_party',
        addressLine2Key: 'notify_party',
        cityKey: 'notify_party',
        stateKey: 'notify_party',
        countryKey: 'notify_party',
        postalCodeKey: 'notify_party',
      },
      {
        partyName: 'Origin Agent',
        name: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        nameKey: 'origin_agent',
        emailKey: 'origin_agent',
        addressLine1Key: 'origin_agent',
        addressLine2Key: 'origin_agent',
        cityKey: 'origin_agent',
        stateKey: 'origin_agent',
        countryKey: 'origin_agent',
        postalCodeKey: 'origin_agent',
      },
      {
        partyName: 'Destination Agent',
        name: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        nameKey: 'destination_agent',
        emailKey: 'destination_agent',
        addressLine1Key: 'destination_agent',
        addressLine2Key: 'destination_agent',
        cityKey: 'destination_agent',
        stateKey: 'destination_agent',
        countryKey: 'destination_agent',
        postalCodeKey: 'destination_agent',
      },
    ],
    routes: [],
    containers: [],
  });
  const [pendingValidations, setPendingValidations] = useState<number>(0);
  const [resolvedValidations, setResolvedValidations] = useState<number>(0);
  const [selectedFieldValidation, setSelectedFieldValidation] = useState<Validation | null>(null);
  const [correctInputData, setCorrectInputData] = useState({
    value: '',
    disabled: true,
  });
  const { shipmentId } = useParams();
  const [shipmentDetailsRequest] = useState<FetchShipmentsByIdRequest>({
    orgId: '',
    shipmentId: shipmentId as string,
    refetch: 0,
  });
  useShipmentDetails(shipmentDetailsRequest);
  const { shipment, isLoading } = useSelector((state: any) => state.shipmentDetails);

  const shouldShowCheckMark = pendingValidations === 0 && resolvedValidations >= 0;

  const handleUpdateShipmentData = async () => {
    if (!shipmentId) {
      console.error('Tried to update shipment with empty id');
      return;
    }
    try {
      const token = await auth?.currentUser?.getIdToken();
      const field = shipment.data[selectedFieldValidation?.field_name as keyof typeof shipment];
      const data = {
        [selectedFieldValidation?.field_name as string]:
          typeof field === 'object'
            ? {
                ...field,
                value: correctInputData.value,
              }
            : correctInputData.value,
      };

      dispatch(
        overrideShipmentValidation({
          id: shipmentId!,
          data,
          version: shipment?.version,
          token: token || '',
        })
      );
    } catch (err) {
      console.error(`Error when submitting vote: ${err}`);
    }
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedFieldValidation(null);
    }
    dispatch(resetToaster());
  }, [isDrawerOpen, dispatch]);

  useEffect(() => {
    if (shipment) {
      const shipmentData = { ...shipmentDetails };
      shipmentData.involvedParties?.forEach((data) => {
        data.name = shipment?.data?.[data?.nameKey]?.name;
        data.email = shipment?.data?.[data?.emailKey]?.emails?.[0];
        data.addressLine1 = shipment?.data?.[data?.addressLine1Key]?.address_line_1;
        data.addressLine2 = shipment?.data?.[data?.addressLine2Key]?.address_line_2;
        data.city = shipment?.data?.[data?.cityKey]?.city;
        data.state = shipment?.data?.[data?.stateKey]?.state;
        data.country = shipment?.data?.[data?.countryKey]?.country;
        data.postalCode = shipment?.data?.[data?.postalCodeKey]?.postal_code;
      });

      let pendingValidationFromShipment = 0;
      let resolvedValidationFromShipment = 0;
      const routesList = routesKeys?.map(({ key, accesorKey, isTimestamp, appendKey, fallBackValidationKey }: any) => {
        const validation =
          shipment?.validation_result?.records?.find(
            (record: any) => record.field_name === key || record.field_name === fallBackValidationKey
          ) || null;

        const rawValue = get(shipment, 'data.' + key);
        const nestedObj = !!appendKey ? `${rawValue?.[accesorKey]} ${rawValue?.[appendKey]}` : rawValue?.[accesorKey];

        const isConvertedNumber = typeof rawValue === 'number';
        const objData = isTimestamp
          ? isConvertedNumber
            ? convertMillisecondToIOSString(rawValue)
            : rawValue?.toDate()?.toISOString()
          : nestedObj;

        let value = typeof rawValue == 'object' || (isConvertedNumber && isTimestamp) ? objData : rawValue;

        if (validation && validation?.status) {
          switch (validation.status) {
            case EValidationStatus.OVERRIDDEN:
              resolvedValidationFromShipment++;
              break;
            case EValidationStatus.DISCREPANCY:
              pendingValidationFromShipment++;
              break;
            default:
              break;
          }
        }

        let valueToCompareWIthValidation = rawValue?.[accesorKey] || rawValue;

        if (key === 'total_containers' && !value) {
          value = shipment?.data?.ocean_containers?.length;
          valueToCompareWIthValidation = value;
        }

        return {
          fieldName: ShipmentDetailFieldLabel[key as keyof typeof ShipmentDetailFieldLabel],
          value,
          valueToCompareWIthValidation,
          validation,
        };
      });
      const containers = shipment?.data?.ocean_containers?.map((container: any) => ({
        containerNumber: container.container_number,
        type: container.container_type,
        cargoDescription: container.items?.[0]?.item_description,
        sealNumber: container.seal_number,
        packageCount: container.package_count,
        packageUOM: container.package_uom,
        weight: container.weight,
        volume: container.volume,
        palletCount: container.pallet_count,
      }));

      setPendingValidations(pendingValidationFromShipment);
      setResolvedValidations(resolvedValidationFromShipment);
      shipmentData.routes = routesList;
      shipmentData.containers = containers;
      setShipmentDetails(shipmentData);
    }
  }, [shipment]);

  useEffect(() => {
    if (containerRef.current && tabListRef.current) {
      setMaxTabPanelHeight(containerRef.current.offsetHeight - tabListRef.current.offsetHeight - 25 - 40);
    }
  }, [containerRef, tabListRef]);

  return (
    <div className="flex h-screen flex-col overflow-hidden">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ShipmentDetailsHeader shipment={shipment} />
          <div
            className="container m-6 mx-auto mb-0 flex h-[calc(100vh-85px)] flex-col items-center justify-center overflow-y-auto"
            ref={containerRef}
          >
            <div className="h-full w-full">
              <TabGroup>
                <TabList className="flex gap-4 pb-6" ref={tabListRef}>
                  {SUPPORTED_TABS.map((tab) => (
                    <Tab as={Fragment} key={tab}>
                      {({ hover, selected }) => (
                        <Button color={selected || hover ? 'dark/zinc' : 'white'}>{tab}</Button>
                      )}
                    </Tab>
                  ))}
                </TabList>
                <TabPanels>
                  <TabPanel className="pb-12">
                    <Accordion transition transitionTimeout={200} allowMultiple className="flex flex-col gap-6">
                      <AccordionItem
                        header={<span className="text-base font-semibold text-gray-900">Involved Parties</span>}
                        className="rounded-lg border border-gray-300"
                        buttonClassName="hover:bg-white p-4 pt-5"
                        enterButtonClassName="bg-white"
                        panelClassName="p-4 pt-0"
                        initialEntered
                      >
                        <div className="h-[1px] w-full bg-gray-200" />
                        <div className="grid grid-cols-5 gap-6 pt-4">
                          {shipmentDetails.involvedParties?.map(
                            (
                              { partyName, name, email, addressLine1, addressLine2, city, state, country, postalCode },
                              index
                            ) => {
                              return (
                                <div className="flex flex-col gap-4" key={index}>
                                  <p className="text-base font-bold first-letter:uppercase">
                                    {partyName.toLocaleLowerCase()}
                                  </p>
                                  <div className="flex flex-col gap-x-6 gap-y-2">
                                    <div>
                                      <label className="text-sm">Name</label>
                                      <Input type="text" value={name} disabled className="my-2" />
                                    </div>
                                    <div>
                                      <label className="text-sm">Email </label>
                                      <Input type="text" value={email} disabled className="my-2" />
                                    </div>
                                    <div>
                                      <label className="text-sm">Address</label>
                                      <Textarea
                                        value={formatAddress({
                                          postal_code: postalCode,
                                          address_line_1: addressLine1,
                                          address_line_2: addressLine2,
                                          city,
                                          state,
                                          country,
                                        })}
                                        disabled
                                        rows={3}
                                        className="my-2"
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </AccordionItem>
                      <AccordionItem
                        header={
                          <div className="text-base font-semibold text-gray-900">
                            {pendingValidations > 0 ? (
                              <div className="flex flex-row justify-between pr-2">
                                <div className="flex flex-row items-center gap-1">
                                  <ExclamationIcon /> Shipment data
                                </div>

                                <BadgeButton className="gap-1" color="red" disabled>
                                  {`${pendingValidations} issues`}
                                </BadgeButton>
                              </div>
                            ) : shouldShowCheckMark ? (
                              <div className="flex flex-row justify-between pr-2">
                                <div className="flex flex-row items-center gap-1">
                                  <CheckCircleIcon /> Shipment data
                                </div>
                                {resolvedValidations > 0 && (
                                  <BadgeButton color="green" disabled>
                                    {`${resolvedValidations} ${resolvedValidations > 1 ? 'issues' : 'issue'} fixed`}
                                  </BadgeButton>
                                )}
                              </div>
                            ) : (
                              'Shipment data'
                            )}
                          </div>
                        }
                        className="rounded-lg border border-gray-300"
                        buttonClassName="hover:bg-white p-4 pt-5"
                        enterButtonClassName="bg-white"
                        panelClassName="p-4 pt-0"
                        initialEntered
                      >
                        <div className="h-[1px] w-full bg-gray-200" />
                        <div className="grid grid-cols-5 gap-x-6 gap-y-2 py-4">
                          {shipmentDetails.routes?.map((route) => {
                            return (
                              <div key={route.fieldName}>
                                <div className="relative flex w-full items-center">
                                  <label className={`text-sm`}>{route.fieldName}</label>
                                  {route.validation ? (
                                    <span className="ml-2">
                                      <ValidationStatusIcon
                                        validation={route.validation}
                                        value={route.value}
                                        documents={shipment?.shipping_documents}
                                        valueToCompareWIthValidation={route.valueToCompareWIthValidation}
                                      />
                                    </span>
                                  ) : null}
                                </div>
                                <Input type="text" value={route.value} disabled className="my-2" />
                              </div>
                            );
                          })}
                        </div>
                      </AccordionItem>
                      <AccordionItem
                        header={<span className="text-base font-semibold text-gray-900">Containers</span>}
                        className="rounded-lg border border-gray-300"
                        buttonClassName="hover:bg-white p-4 pt-5"
                        enterButtonClassName="bg-white"
                        panelClassName="p-4 pt-0"
                        initialEntered
                      >
                        <div className="h-[1px] w-full bg-gray-200" />
                        <div className="flex flex-col gap-6 py-2">
                          {shipmentDetails?.containers?.map((container: any, index) => (
                            <React.Fragment key={index}>
                              {index > 0 ? <Divider /> : null}
                              <div className="flex flex-col gap-4">
                                <p className="text-base font-bold">Container #{index + 1}</p>
                                <div className="grid grid-cols-5 gap-x-6 gap-y-2">
                                  <div>
                                    <div className="flex items-center justify-between">
                                      <label className="text-sm">Container number</label>
                                    </div>
                                    <Input type="text" value={container.containerNumber} disabled className="my-2" />
                                  </div>
                                  <div>
                                    <div className="flex items-center justify-between">
                                      <label className="text-sm">Seal number</label>
                                    </div>
                                    <Input type="text" value={container.sealNumber} disabled className="my-2" />
                                  </div>
                                  <div>
                                    <div className="flex items-center justify-between">
                                      <label className="text-sm">Container type</label>
                                    </div>
                                    <Input type="text" value={container.type} disabled className="my-2" />
                                  </div>
                                  <div>
                                    <div className="flex items-center justify-between">
                                      <label className="text-sm">Packages</label>
                                    </div>
                                    <Input type="text" value={container.packageCount} disabled className="my-2" />
                                  </div>
                                  <div>
                                    <div className="flex items-center justify-between">
                                      <label className="text-sm">Package UOM</label>
                                    </div>
                                    <Input type="text" value={container.packageUOM} disabled className="my-2" />
                                  </div>
                                  <div>
                                    <div className="flex items-center justify-between">
                                      <label className="text-sm">Pallets</label>
                                    </div>
                                    <Input type="text" value={container.palletCount} disabled className="my-2" />
                                  </div>
                                  <div>
                                    <div className="flex items-center justify-between">
                                      <label className="text-sm">Weight</label>
                                    </div>
                                    <Input type="text" value={container.weight} disabled className="my-2" />
                                  </div>
                                  <div>
                                    <div className="flex items-center justify-between">
                                      <label className="text-sm">Volume</label>
                                    </div>
                                    <Input type="text" value={container.volume} disabled className="my-2" />
                                  </div>

                                  <div>
                                    <div className="flex items-center justify-between">
                                      <label className="text-sm">Item description</label>
                                    </div>
                                    <Textarea rows={2} value={container.cargoDescription} disabled className="my-2" />
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </AccordionItem>
                    </Accordion>
                    <ShipmentValidationDetailsDrawer
                      isOpen={isDrawerOpen}
                      setOpen={setIsDrawerOpen}
                      fieldValidation={selectedFieldValidation}
                      setInputData={setCorrectInputData}
                      inputData={correctInputData}
                      onConfirm={handleUpdateShipmentData}
                      onCancel={() => setIsDrawerOpen(false)}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ShipmentRoute shipment={shipment} maxHeight={maxTabPanelHeight} />
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShipmentView;
