import React from 'react';

import { cn } from 'shared/utils';
import { EShipmentRouteVariant } from '../enums';

export const enum EContainerStatus {
  discharged = 'discharged',
  readyForPickup = 'readyForPickup',
  pickedUp = 'pickedUp',
  notArrived = 'notArrived',
  arrived = 'arrived',
  departed = 'departed',
}

const CONTAINER_STATUS_LABELS = {
  [EContainerStatus.discharged]: 'Discharged',
  [EContainerStatus.readyForPickup]: 'Ready for Pickup',
  [EContainerStatus.pickedUp]: 'Picked Up',
  [EContainerStatus.notArrived]: 'Not Arrived',
  [EContainerStatus.arrived]: 'Arrived',
  [EContainerStatus.departed]: 'Departed',
};

type ContainerStatusBadgeProps = {
  count: number;
  status: EContainerStatus;
  variant?: EShipmentRouteVariant;
};

const ContainerStatusBadge = ({ count, status, variant = EShipmentRouteVariant.active }: ContainerStatusBadgeProps) => {
  const isInActive = variant === EShipmentRouteVariant.inactive;
  const isActive = variant === EShipmentRouteVariant.active;
  const isDefault = variant === EShipmentRouteVariant.default;

  const statusText = CONTAINER_STATUS_LABELS[status];

  const isNotArrivedOrDischarged = status === EContainerStatus.notArrived || status === EContainerStatus.discharged;
  const isReadyForPickupOrArrived = status === EContainerStatus.readyForPickup || status === EContainerStatus.arrived;
  const isDepartedOrPickedUp = status === EContainerStatus.pickedUp || status === EContainerStatus.departed;

  const classes = cn(
    'flex flex-col justify-center h-[14px] py-[1px] px-1 rounded-[100px] text-[8px] leading-[12px]',
    isActive && {
      'bg-teal-100 text-green-800': isNotArrivedOrDischarged,
      'bg-[#DEF7EC] text-green-800': isReadyForPickupOrArrived,
      'bg-gray-100 text-gray-800': isDepartedOrPickedUp,
    },
    isDefault && 'bg-gray-100 text-gray-800',
    isInActive && 'bg-gray-100 text-gray-400'
  );

  return (
    <div className={classes}>
      <div className="h-fit">
        <span className="font-bold">{count}</span> <span className="font-medium">{statusText}</span>
      </div>
    </div>
  );
};

export default ContainerStatusBadge;
