import React, { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DashboardPage from 'pages/DashboardPage';
import DocumentDetailsPage from 'pages/DocumentDetailsPage';
import DocumentsPage from 'pages/DocumentsPage';
import ShipmentsPage from 'pages/ShipmentsPage';
import TasksPage from 'pages/TasksPage';
import { DefaultDashboardNavbar } from 'shared/components/Navigation';
import Spinner from 'shared/components/Spinner/Spinner';
import { useAuthenticate } from 'shared/hooks/useAuthenticate';
import TaskDetails from './containers/Taskdetails/TaskDetails';
import TaskDiscrepancy from './containers/Taskdetails/TaskDiscrepancy';
import TaskDetailsDuplicate from './containers/Taskdetails/TaskDuplicate';
import TaskMissingOrgData from './containers/Taskdetails/TaskMissingOrgData';
import TaskDetailsUnassigned from './containers/Taskdetails/TaskUnassigned';
import ShipmentDetailsPage from './pages/ShipmentDetailsPage';
import ShipmentDetailsPageWithNewRouteTab from './pages/ShipmentDetailsPageWithNewRouteTab';
import Login2 from './shared/containers/Login/login-2';

import '@xyflow/react/dist/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './App.css';

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen">
      <DefaultDashboardNavbar prefix="" />
      <div className="content container mx-auto py-6">{children}</div>
    </div>
  );
};

const AppLayout: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Routes>
        {/* Routes that should show the navbar */}
        <Route
          path="/shipments"
          element={
            <MainLayout>
              <ShipmentsPage />
            </MainLayout>
          }
        />
        <Route
          path="/"
          element={
            <MainLayout>
              <DashboardPage />
            </MainLayout>
          }
        />
        <Route
          path="/documents"
          element={
            <MainLayout>
              <DocumentsPage />
            </MainLayout>
          }
        />
        <Route
          path="/tasks"
          element={
            <MainLayout>
              <TasksPage />
            </MainLayout>
          }
        />

        {/* Route that should not show the navbar */}
        <Route path="/shipments/:shipmentId" element={<ShipmentDetailsPage />} />
        <Route path="/v2/shipments/:shipmentId" element={<ShipmentDetailsPageWithNewRouteTab />} />
        <Route path="/document/:docId" element={<DocumentDetailsPage />} />
        <Route path="/task/corrupt-document/:taskId" element={<TaskDetails />} />
        <Route path="/task/unassigned/:taskId" element={<TaskDetailsUnassigned />} />
        <Route path="/task/duplicate/:taskId" element={<TaskDetailsDuplicate />} />
        <Route path="/task/missing-org-data/:taskId" element={<TaskMissingOrgData />} />
        <Route path="/task/discrepancy/:taskId" element={<TaskDiscrepancy />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

function App() {
  const isAuthenticated = useAuthenticate('/tasks');
  if (!isAuthenticated) return <Spinner />;

  return (
    <Routes>
      {/* Login route */}
      <Route path="/login" element={<Login2 />} />
      <Route path="/*" element={<AppLayout />} />
    </Routes>
  );
}

export default App;
