import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import { AccordionItem as Item, AccordionItemProps } from '@szhsin/react-accordion';
import React from 'react';

import { cn } from 'shared/utils';

interface Props extends AccordionItemProps {
  buttonClassName?: string;
  enterButtonClassName?: string;
  className?: string;
  panelClassName?: string;
}

export const AccordionItem = ({
  header,
  className,
  buttonClassName,
  panelClassName,
  enterButtonClassName,
  ...rest
}: Props) => (
  <Item
    {...rest}
    className={className}
    header={({ state: { isEnter } }) => (
      <div className="grid w-full grid-cols-[1fr_18px] items-center justify-between">
        {header as React.ReactNode}
        <div className={`ml-auto transition-transform duration-200 ${isEnter && 'rotate-180'}`}>
          <ChevronDownIcon height={18} width={18} className="ease-out" />
        </div>
      </div>
    )}
    buttonProps={{
      className: ({ isEnter }) =>
        cn(
          `rounded-lg flex w-full p-3 text-left hover:bg-zinc-950/5 ${isEnter && 'bg-zinc-950/5'} text-base`,
          buttonClassName,
          isEnter && enterButtonClassName
        ),
    }}
    contentProps={{
      className: cn('transition-height duration-200 ease-out'),
    }}
    panelProps={{ className: cn('p-3', panelClassName) }}
  />
);
