import React from 'react';

type Props = {
  className?: string;
};

const ShipIcon = (props: Props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.5 16.1205C2.47087 16.1924 3.28775 15.375 4 15.375C4.71225 15.375 5.86855 16.129 6.5 16.1205C7.25774 16.1269 8.1453 15.375 9 15.375C9.8547 15.375 10.7423 16.1269 11.5 16.1205C12.4709 16.1924 13.2878 15.375 14 15.375C14.7123 15.375 15.8686 16.129 16.5 16.1205"
        stroke="#9CA3AF"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 15.3762C3.4366 14.0514 2.68715 12.3558 2.368 11.4573C2.2668 11.1724 2.2162 11.03 2.27496 10.8938C2.33373 10.7576 2.47782 10.6938 2.76602 10.5661L8.38292 8.07815C8.68683 7.94353 8.83878 7.87622 9 7.87622C9.16122 7.87622 9.31317 7.94353 9.61708 8.07815L15.234 10.5661C15.5222 10.6938 15.6663 10.7576 15.725 10.8938C15.7838 11.03 15.7332 11.1724 15.632 11.4573C15.3129 12.3558 14.5634 14.0514 13.5 15.3762"
        stroke="#9CA3AF"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 9.75232L4.66193 7.64723C4.76301 6.33318 4.81355 5.67615 5.24487 5.27674C5.67619 4.87732 6.33516 4.87732 7.65309 4.87732H10.3469C11.6648 4.87732 12.3238 4.87732 12.7551 5.27674C13.1864 5.67615 13.237 6.33318 13.3381 7.64723L13.5 9.75232"
        stroke="#9CA3AF"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 4.875L6.50363 3.84592C6.62094 2.90746 6.6796 2.43822 6.9986 2.15661C7.31761 1.875 7.79049 1.875 8.73626 1.875H9.26374C10.2095 1.875 10.6824 1.875 11.0014 2.15661C11.3204 2.43822 11.3791 2.90746 11.4964 3.84592L11.625 4.875"
        stroke="#9CA3AF"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShipIcon;
