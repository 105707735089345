import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getShipmentById } from 'shared/firebase/query/shipments';

const initialState: any = {
  isLoading: false,
  data: null,
  json_data: {
    data: null,
    loader: true,
    error: null,
  },
  docSrc: '',
  isFetchingShipmentDetails: false,
  shipmentDetails: null,
};

export const getDocumentData = createAsyncThunk('get/documentDetailsJson', async (url: string) => {
  const response = await axios.get(url);
  return response.data;
});

export const fetchDocumentShipmentDetails = createAsyncThunk('get/documentShipmentDetails', (shipmentId: string) => {
  return getShipmentById(shipmentId);
});

const documentDetails = createSlice({
  name: 'shipmentsDetails',
  initialState,
  reducers: {
    updateGetDocumentLoader(state) {
      state.isLoading = !state.isLoading;
    },
    setDocumentDetails(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    updateDocSrc(state, action) {
      state.docSrc = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocumentData.pending, (state) => {
      state.json_data.loader = true;
    });
    builder.addCase(getDocumentData.fulfilled, (state, action) => {
      state.json_data.loader = false;
      state.json_data.data = action.payload;
      state.json_data.error = null;
    });
    builder.addCase(getDocumentData.rejected, (state, action) => {
      state.json_data.loader = false;
      state.json_data.error = action.payload;
      state.json_data.data = null;
    });
    builder.addCase(fetchDocumentShipmentDetails.pending, (state) => {
      state.isFetchingShipmentDetails = true;
    });
    builder.addCase(fetchDocumentShipmentDetails.fulfilled, (state, action) => {
      state.isFetchingShipmentDetails = false;
      state.shipmentDetails = action.payload;
    });
    builder.addCase(fetchDocumentShipmentDetails.rejected, (state) => {
      state.isFetchingShipmentDetails = false;
      state.shipmentDetails = null;
    });
  },
});

export const { updateGetDocumentLoader, setDocumentDetails, updateDocSrc } = documentDetails.actions;
export default documentDetails.reducer;
