import React, { PropsWithChildren } from 'react'

type DocumentDrawBodyItemProps = PropsWithChildren<{
  title: string
  subTitle: string
}>

export default function DocumentDrawBodyItem({ title, subTitle, children }: DocumentDrawBodyItemProps) {
  return (
    <div className="mx-4 border-b border-gray-200 pb-4 pt-4">
      <div className="pb-0.5">
        <p className="font-semibold">{title}</p>
        <p className="text-sm text-gray-900">{subTitle}</p>
        {children}
      </div>
    </div>
  )
}
