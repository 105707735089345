import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { Alert } from 'flowbite-react';
import React from 'react';

export default function ExportPreparedAlert({ onDismiss }: { onDismiss: () => void }) {
  return (
    <Alert
      theme={{
        closeButton: {
          color: {
            success: '!bg-green-50 !text-green-800',
          },
        },
      }}
      color="success"
      className="fixed bottom-8 left-0 right-0 z-20 mx-auto max-w-96 bg-green-50 font-semibold text-green-800"
      onDismiss={onDismiss}
    >
      <div className="flex items-center gap-x-2">
        <CheckCircleIcon className="size-4" />
        <span>Your export is being prepared!</span>
      </div>
    </Alert>
  );
}
