import {
  CollectionReference,
  endBefore,
  getCountFromServer,
  getDocs,
  query,
  Query,
  QueryDocumentSnapshot,
} from '@firebase/firestore';
import { orderBy, where } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import get from 'lodash/get';
import set from 'lodash/set';

import { PaginatedQueryRequest } from './model';

export async function getTotalCount(query: Query) {
  try {
    const countSnapshot = await getCountFromServer(query);
    return countSnapshot.data().count;
  } catch (e) {
    return 0;
  }
}

export async function getCountUntilCurrentPage(
  tasksCollection: CollectionReference,
  request: PaginatedQueryRequest,
  firstToken: QueryDocumentSnapshot
) {
  let q = query(
    tasksCollection,
    where('org_id', '==', request.orgId),
    orderBy('created_at', 'desc'),
    endBefore(firstToken)
  );

  const countSnapshot = await getDocs(q);
  return countSnapshot.size;
}

export async function getGSReference(gsUrl: string, cb: any) {
  const storage = getStorage();
  getDownloadURL(ref(storage, gsUrl))
    .then((url) => {
      cb(url);
    })
    .catch((error) => {
      // Handle any errors
    });
}
export const normalizeShipmentDocumentData = (data: any) => {
  try {
    const etd = get(data, 'data.estimated_time_of_departure');
    const eta = get(data, 'data.estimated_time_of_arrival');
    const ata = get(data, 'data.actual_time_of_arrival');
    const atd = get(data, 'data.actual_time_of_departure');

    const normalizedDoc = {
      ...data,
      created_at: data?.created_at?.toMillis(),
      updated_at: data?.updated_at?.toMillis(),
    };

    if (etd) {
      set(normalizedDoc, 'data.estimated_time_of_departure', etd.toMillis());
    }
    if (eta) {
      set(normalizedDoc, 'data.estimated_time_of_arrival', eta.toMillis());
    }
    if (ata) {
      set(normalizedDoc, 'data.actual_time_of_arrival', ata.toMillis());
    }
    if (atd) {
      set(normalizedDoc, 'data.actual_time_of_departure', atd.toMillis());
    }

    return normalizedDoc;
  } catch (error) {
    console.error('Error in normalizeShipmentDocumentData: ', error);
    return data;
  }
};
